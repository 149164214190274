<template>
  <div class="decoration-statistics">
    <!--项目选择模块-->
    <div class="module-small" @click="showProject">
      <span class="project-name">{{
        departmentName != undefined && departmentId != ''
          ? departmentName
          : '部门检索'
      }}</span>
      <img class="drop-down-image" src="../../assets/images/drop-down.png" />
    </div>
    <!--拉下菜单-->
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <div class="choose-company-text">选择公司</div>
      <van-row justify="center">
        <div v-for="(item, index) in companyList" :key="index">
          <van-col span="8" offset="4" class="company-col-style"
            ><div
              :class="
                checkedIndex == index
                  ? 'company-col-checked-style'
                  : 'company-text-style'
              "
              @click="selectCompany(item, index)"
            >
              {{ item.departmentName ? item.departmentName : '部门检索' }}
            </div></van-col
          >
        </div>
      </van-row>
      <van-tree-select
        style="margin-top: 20px"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        :items="items"
        @click-nav="selectArea"
        @click-item="selectDepartment"
      />
      <div class="search">
        <van-button
          style="width: 2rem; color: orangered;border-color: orangered"
          plain
          type="primary"
          @click="resetDepartmentInfo"
          >清空</van-button
        >
        <van-button
          @click="searchDepartmentInfo"
          style="width: 2.8rem;margin-left: 4rem"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="normal"
        >
          筛选
        </van-button>
      </div>
    </van-popup>

    <!--当前用户总量-->
    <div class="module-literal">
      <!--特征内容-->
      <div class="statistics-content-figure">
        <div class="description-content">
          <div class="money-big-font">{{ totalCustomerNum }}</div>
          <div class="description-text">当前总用户量</div>
        </div>
      </div>
    </div>
    <!--投诉情况-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          投诉情况
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(1)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(1)">{{
            complaintInfoMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(2)">{{
            complaintInfoMaxDate
          }}</span>
          <img
            @click="leftArrow(2)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-figure">
        <div class="description-content">
          <div class="left-content">
            <div class="money-small-font">{{ complaintInfo.complaints }}</div>
            <div class="description-text">投诉量/次</div>
          </div>
          <!--中间分割线-->
          <div class="middle-line"></div>
          <div class="right-content">
            <div class="money-small-font">
              {{ complaintInfo.complaintRate }}
            </div>
            <div class="description-text">投诉率</div>
          </div>
        </div>
      </div>
    </div>
    <!--各类投诉占比-->
    <div class="module-charts">
      <div>
        <!--统计标题-->
        <span class="left-title">
          各类投诉占比
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(3)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(3)">{{
            everyComplaintMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(4)">{{
            everyComplaintMaxDate
          }}</span>
          <img
            @click="leftArrow(4)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-chart">
        <!-- <ve-ring class="chart-style" width="345px" height="185px" :data="chartEveryComplaintData" :extend="everyComplaintSetend" ></ve-ring> -->
        <ve-ring
          class="chart-style"
          width="345px"
          height="185px"
          id="complaintTypeRatio"
        ></ve-ring>
      </div>
    </div>
    <!--投诉处理情况-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          投诉处理情况
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(5)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(5)">{{
            finalAccountHouseMaxDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="min-date" @click="chooseTime(6)">{{
            finalAccountHouseMaDate
          }}</span>
          <img
            @click="leftArrow(6)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-figure">
        <div class="description-content-other">
          <div class="first-content">
            <div class="money-small-font">
              {{ disposeComplaintInfo.avgResponseTimeLong }}
            </div>
            <div class="description-text">平均响应时长/时</div>
          </div>
          <div class="middle-content">
            <div class="money-small-font">
              {{ disposeComplaintInfo.avgAcceptNum }}
            </div>
            <div class="description-text">平均受理次数/次</div>
          </div>
          <div class="third-content">
            <div class="money-small-font">
              {{ disposeComplaintInfo.complaintSolveAging }}
            </div>
            <div class="description-text">投诉解决时效</div>
          </div>
        </div>
      </div>
    </div>

    <!--时间选择-->
    <van-popup v-model="isDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        item-height="35px"
        @confirm="dateConfirm"
        @cancel="isDateShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { responseUtil, getStaffId } from '@/libs/rongxunUtil'
import util from '../contract/util2'
var echarts = require('echarts')
import {
  DatetimePicker,
  Popup,
  Picker,
  TreeSelect,
  Col,
  Row,
  Button,
} from 'vant'
import {
  globaluserNum, //用户总量
  complaintRatio, //总投诉量，投诉率
  complaintTypeRatio, //投诉类型占比
  complaintHandle, //投诉处理情况
  queryAuthLevelDepartment,
} from '../../getData/getData'

export default {
  name: 'customerService',
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
  },
  data() {
    return {
      checkedIndex: 0,
      departmentName: '',
      departmentId: '', //部门id
      functionId: '410',
      companyList: [],
      activeId: -1,
      activeIndex: 0,
      //新的列表接口所需数据
      departmentAuthData: {},
      items: [
        {
          text: '浙江',
          children: [
            { text: '杭州', id: 1 },
            { text: '温州', id: 2 },
          ],
        },
        {
          text: '辽宁',
          children: [
            { text: '沈阳', id: 3 },
            { text: '大连', id: 4 },
          ],
        },
        {
          text: '江苏',
          children: [
            { text: '南京', id: 5 },
            { text: '无锡', id: 6 },
          ],
        },
      ],
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: '集寓项目名称',
      projectDataInfo: [],

      totalCustomerNum: {}, //当前总用户数

      //投诉情况
      complaintInfo: {
        complaintRate: {}, //投诉率
        complaints: {}, //投诉量
      },

      //投诉处理情况
      disposeComplaintInfo: {
        avgResponseTimeLong: '13.3',
        avgAcceptNum: '32',
        complaintSolveAging: '23',
      },
      chooseType: '', //选择类型 时长&总额 最小时间 or 验收情况  最小时间  等等
      currentDate: new Date(), //时间选择器绑定的时间
      isDateShow: false, //时间选择显示与否

      complaintInfoMinDate: this.formatDate(new Date()), //投诉情况 最小时间  1
      complaintInfoMaxDate: this.formatDate(new Date()), //投诉情况 最大时间  2
      everyComplaintMinDate: this.formatDate(new Date()), //各类投诉占比  最大时间  3
      everyComplaintMaxDate: this.formatDate(new Date()), //各类投诉占比  最大时间  4
      finalAccountHouseMaxDate: this.formatDate(new Date()), //投诉处理情况 最大时间  6
      finalAccountHouseMaDate: this.formatDate(new Date()), //投诉处理情况 最大时间  6

      /********************************各类投诉占比样式 start **************************/
      // everyComplaintSetend: {

      // 	legend: {
      // 		orient: 'vertical',
      // 		left: 180,
      // 		top: 30,
      // 		// data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
      // 		textStyle: {
      // 			color: '#d8d8d8'
      // 		},
      // 		icon: 'circle'
      // 	},
      // 	title: {
      // 		left: 180,
      // 		top: 10,
      // 		text: '各类投诉占比',
      // 		textStyle: {
      // 			fontSize: 13
      // 		}
      // 	},
      // 	graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
      // 		width: '30',
      // 		type: 'group',
      // 		left: 57,
      // 		top: 83,
      // 		children: [
      // 			{
      // 				type: 'text',
      // 				left: 52,
      // 				top: 83,
      // 				style: {
      // 					width:'30',
      // 					text: '累计投诉/次',
      // 					textAlign: 'center',
      // 					fill: '#999999',
      // 					font: 'bolder 12px "Microsoft YaHei", sans-serif'
      // 				}
      // 			},
      // 			{
      // 				type: 'text',
      // 				left: 68,
      // 				top: 100,
      // 				style: {
      // 					width:'30',
      // 					text: '3432',
      // 					textAlign: 'center',
      // 					fill: 'black',
      // 					font: 'bolder 12px "Microsoft YaHei", sans-serif'
      // 				}
      // 			},
      // 		]
      // 	},
      // 	series: {
      // 		// 扇叶样式
      // 		label: {
      // 			normal: {
      // 				// 是否显示扇叶
      // 				show: false,
      // 				position: 'insideLeft',
      // 				// 文字大小
      // 				fontSize: 30
      // 			}
      // 		},
      // 		 type: 'line',
      // 		// 位置
      // 		center: ['25%', '50%'],
      // 		// 大小
      // 		radius : ['48%', '60%'],
      // 		// data: [
      // 		// 	{value: 335, name: '直接访问'},
      // 		// 	{value: 310, name: '邮件营销'},
      // 		// 	{value: 234, name: '联盟广告'},
      // 		// 	{value: 135, name: '视频广告'},
      // 		// 	{value: 1548, name: '搜索引擎'}
      // 		// ]
      // 	}

      // },

      // chartEveryComplaintData: {
      //     columns: ['价格区间', '人数'],
      //     rows: [
      //         { '价格区间': '500-1000', '人数': 1393 },
      //         { '价格区间': '1000-1500', '人数': 3530 },
      //         { '价格区间': '1500-2000', '人数': 2923 },
      //         { '价格区间': '2000-2500', '人数': 1723 },
      //         { '价格区间': '2500-3000', '人数': 3792 },
      //         { '价格区间': '3000-3500', '人数': 4593 }
      //     ]
      // },

      /********************************各类投诉占比样式 end   **************************/
    }
  },
  mounted() {
    this.getGlobaluserNum() //用户总量
    this.getComplaintTypeRatio() //投诉类型占比

    this.everyComplaintSetend()
    this.getComplaintHandle() //投诉处理情况
    //公司初始化
    this.queryAuthLevelDepartment()
  },
  methods: {
    queryAuthLevelDepartment() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId() //员工id
      initData.function_id = that.functionId //方法模块id
      queryAuthLevelDepartment(initData).then(res =>
        responseUtil.dealResponse(that, res, () => {
          that.departmentAuthData = res.data.data
          let allList = that.departmentAuthData.list
          if (allList != null) {
            that.companyList = allList.filter(item => item.level == 1)
          }
          let departmentId = that.departmentAuthData.roleDepartmentId
          that.departmentId = departmentId
          let item = that.departmentAuthData.list.find(
            item => item.id == departmentId
          )
          that.departmentName = item ? item.departmentName || '' : ''
          that.initOtherDepartment()
          that.searchDepartmentInfo()
        })
      )
    },
    initOtherDepartment() {
      let that = this
      let allList = that.departmentAuthData.list
      let departmentId = that.departmentId
      let threeLevelDepartments = allList.filter(item => item.level == 3)
      let fiveLevelDepartments = allList.filter(item => item.level == 5)
      let levelDepartments = threeLevelDepartments
        .filter(item => {
          let levelTop = item.levelTop || ''
          return levelTop.indexOf(departmentId) != -1
        })
        .map(item => {
          let result = {}
          result.children = fiveLevelDepartments
            .filter(child => {
              let levelTop = child.levelTop || ''
              return levelTop.indexOf(',' + item.id) != -1
            })
            .map(child => {
              let childResult = {}
              childResult.text = child.departmentName
              childResult.id = child.id
              childResult.level = child.level
              return childResult
            })
          result.text = item.departmentName
          result.value = item.id
          result.level = item.level
          return result
        })
      that.items = levelDepartments // 公司列表
    },
    resetDepartmentInfo() {
      let departmentId = this.departmentAuthData.roleDepartmentId
      let item = this.departmentAuthData.list.find(
        item => item.id == departmentId
      )
      this.departmentName = item ? item.departmentName || '' : ''
      this.departmentId = departmentId
      this.isShowProjectMenu = false
      this.searchDepartmentInfo()
    },

    //选择公司，联动下方区、部
    selectCompany(item, index) {
      let that = this
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(that, '权限不足')
        return
      }
      this.checkedIndex = index
      this.departmentId = item.id
      this.activeId = this.departmentId
      this.departmentName = item.departmentName
      this.initOtherDepartment()
    },
    selectArea(value) {
      let item = this.items[value]
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = item.value
      this.departmentName = item.text
      this.activeId = this.departmentId
    },
    selectDepartment(data) {
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = data.id
      this.departmentName = data.text
    },
    searchDepartmentInfo() {
      this.getComplaintRatio() //总投诉量，投诉率

      this.isShowProjectMenu = false
    },
    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true
    },
    //取消选择
    cancelChangeProject() {
      this.projectName = '集寓项目名称'
      this.projectId = ''
      this.isShowProjectMenu = false
    },

    //用户总量
    getGlobaluserNum() {
      var that = this
      let data = {}
      globaluserNum(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.totalCustomerNum = response.data.data.list[0].value
        })
      })
    },
    //总投诉量，投诉率
    getComplaintRatio() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.complaintInfoMinDate)
      data.endTime = util.getCurrentMonthLast(that.complaintInfoMaxDate)

      complaintRatio(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.complaintInfo.complaints = response.data.data.allComplaintCount
          that.complaintInfo.complaintRate =
            response.data.data.allComplaintRatio * 100 + '%'
        })
      })
    },

    //投诉处理情况
    getComplaintHandle() {
      var that = this
      let data = {}
      data.beginTime = util.dateFormat(that.finalAccountHouseMaxDate)
      data.endTime = util.getCurrentMonthLast(that.finalAccountHouseMaDate)
      complaintHandle(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.disposeComplaintInfo.avgResponseTimeLong = response.data.data.complaintAvg.pingjun.toFixed(
            2
          ) //平均响应时长/时
          that.disposeComplaintInfo.avgAcceptNum = response.data.data.complaintCount.times.toFixed(
            2
          ) //平均受理次数/次
          that.disposeComplaintInfo.complaintSolveAging = response.data.data.complaintTime.times.toFixed(
            2
          ) //投诉解决时效
        })
      })
    },

    everyComplaintSetend(data) {
      let myChart = echarts.init(document.getElementById('complaintTypeRatio'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 165,
          top: 20,
          text: '投诉类型占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 165,
          top: 50,
          //data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },

        series: [
          {
            name: '投诉类型占比',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['25%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },

            data: data,
          },
        ],
      })
    },

    //投诉类型占比
    getComplaintTypeRatio() {
      var that = this
      let data = {}
      //匹配后端接口 (只取年月)
      data.typeFinishBeginTime = that.everyComplaintMinDate
      data.typeFinishEndTime = that.everyComplaintMaxDate

      complaintTypeRatio(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.ratioData = [
            {
              value: response.data.data.typeTitleCount[1],
              name: response.data.data.typeTitle[1],
            },
            {
              value: response.data.data.typeTitleCount[2],
              name: response.data.data.typeTitle[2],
            },
            {
              value: response.data.data.typeTitleCount[3],
              name: response.data.data.typeTitle[3],
            },
            {
              value: response.data.data.typeTitleCount[4],
              name: response.data.data.typeTitle[4],
            },
            {
              value: response.data.data.typeTitleCount[5],
              name: response.data.data.typeTitle[5],
            },
            {
              value: response.data.data.typeTitleCount[6],
              name: response.data.data.typeTitle[6],
            },
            {
              value: response.data.data.typeTitleCount[7],
              name: response.data.data.typeTitle[7],
            },
            {
              value: response.data.data.typeTitleCount[8],
              name: response.data.data.typeTitle[8],
            },
            {
              value: response.data.data.typeTitleCount[9],
              name: response.data.data.typeTitle[9],
            },
          ]
          that.everyComplaintSetend(that.ratioData)
        })
      })
    },

    //选择时间
    chooseTime(value) {
      this.chooseType = value //时长&总额 最小时间  1
      this.isDateShow = true
    },

    /*时间格式转化方法*/
    formatDate(dateTime) {
      let year = `${dateTime.getFullYear()}`
      let month = `${dateTime.getMonth() + 1}`
      if (month < 10) {
        month = `0${dateTime.getMonth() + 1}`
      }
      return year + '-' + month
    },

    //确认时间
    dateConfirm(date) {
      this.currentDate = this.formatDate(date)
      if (this.chooseType == 1) {
        this.complaintInfoMinDate = this.currentDate //时长&总额 最小时间  1
        this.getComplaintRatio() //投诉量 投诉率
      } else if (this.chooseType == 2) {
        this.complaintInfoMaxDate = this.currentDate //时长&总额 最大时间  2
        this.getComplaintRatio() //投诉量 投诉率
      } else if (this.chooseType == 3) {
        this.everyComplaintMinDate = this.currentDate //各类投诉占比  最小时间  3
        this.getComplaintTypeRatio() //投诉类型占比
      } else if (this.chooseType == 4) {
        this.everyComplaintMaxDate = this.currentDate //各类投诉占比  最大时间  4
        this.getComplaintTypeRatio() //投诉类型占比
      } else if (this.chooseType == 5) {
        this.finalAccountHouseMaxDate = this.currentDate //投诉处理情况 最大时间  5
        this.getComplaintHandle() //投诉处理情况
      } else if (this.chooseType == 6) {
        this.finalAccountHouseMaDate = this.currentDate //投诉处理情况 最大时间  6
        this.getComplaintHandle() //投诉处理情况
      }
      this.isDateShow = false

      // this.getComplaintRatio()
      // this.getComplaintTypeRatio()//投诉类型占比
      // this.getComplaintHandle()//投诉处理情况
    },

    /*点击箭头，增加或减少一个月*/
    leftArrow(paramType) {
      if (paramType == 1) {
        //时长&总额 最小时间  1
        let date = new Date(this.complaintInfoMinDate)
        this.complaintInfoMinDate = this.reduceDate(date)
        this.getComplaintRatio() //投诉量 投诉率
      } else if (paramType == 2) {
        //时长&总额 最大时间  2
        let date = new Date(this.complaintInfoMaxDate)
        this.complaintInfoMaxDate = this.addDate(date)
        this.getComplaintRatio() //投诉量 投诉率
      } else if (paramType == 3) {
        //各类投诉占比  最小时间  3
        let date = new Date(this.everyComplaintMinDate)
        this.everyComplaintMinDate = this.reduceDate(date)
        this.getComplaintTypeRatio() //投诉类型占比
      } else if (paramType == 4) {
        //各类投诉占比  最大时间  4
        let date = new Date(this.everyComplaintMaxDate)
        this.everyComplaintMaxDate = this.addDate(date)
        this.getComplaintTypeRatio() //投诉类型占比
      } else if (paramType == 5) {
        //投诉处理情况 最大时间  5
        let date = new Date(this.finalAccountHouseMaxDate)
        this.finalAccountHouseMaxDate = this.reduceDate(date)
        this.getComplaintHandle() //投诉处理情况
      } else if (paramType == 6) {
        //投诉处理情况 最大时间  5
        let date = new Date(this.finalAccountHouseMaDate)
        this.finalAccountHouseMaDate = this.addDate(date)
        this.getComplaintHandle() //投诉处理情况
      }

      // this.getComplaintRatio()
      // this.getComplaintTypeRatio()//投诉类型占比
      // this.getComplaintHandle()//投诉处理情况
    },

    //使年月格式的时间减少一个月
    reduceDate(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month
      }
    },
    //使年月格式的时间增加一个月
    addDate(dateTime) {
      if (dateTime.getMonth() + 1 > 11) {
        let year = `${dateTime.getFullYear() + 1}`
        let month = `01`
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 + 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 + 1}`
        }
        return year + '-' + month
      }
    },
  },
}
</script>

<style scoped>
/*项目选择模块*/
.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  margin: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  background-color: #f7f8fa;
  border-radius: 0.1rem;
  font-size: 17px;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}

/*背景颜色*/
.decoration-statistics {
  width: 10rem;
  background-color: #fafafa;
}

/*模块样式*/
.module-literal {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/*左上角标题*/
.left-title {
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  color: #ff5d3b;
  font-weight: bolder;
  margin: 10px 0px 5px 0px;
}

/*右上角选择时间*/
.min-date,
.max-date,
.middle-character {
  font-size: 14px;
  font-weight: 600;
  padding: 0 4px;
}

/*模块主题内容*/
.statistics-content-figure {
  width: 100%;
  height: 109px;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*含有图表的模块*/
.module-charts {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/*右上角统一div样式*/
.right-time {
  float: right;
  margin-right: 8px;
}

/*所有数字下方描述性质字体*/
.description-text {
  font-size: 12px;
  color: #999999;
  font-family: PingFangSC-Regular;
  margin-top: 4px;
  font-weight: bolder;
}

/*数字样式-小*/
.money-small-font {
  font-family: Oswald-Regular;
  font-size: 20px;
  color: #222222;
  font-weight: bolder;
  padding-top: 30px;
}

/*数字样式-大*/
.money-big-font {
  font-family: Oswald-Regular;
  font-size: 40px;
  color: #222222;
  font-weight: bolder;
  padding-top: 20px;
}

/*中间分割线*/
.middle-line {
  float: left;
  width: 1px;
  height: 43px;
  left: 50%;
  margin-top: 33px;
  border-right: #ff5d3b 1px dashed;
}

/*图表样式*/
.chart-style {
  height: 115px;
  width: 115px;
}

.description-content {
  width: 100%;
  margin: 0 auto;
  height: 100px;
}

.description-content-other {
  width: 8.2rem;
  margin: 0 auto;
}

.left-content {
  float: left;
  width: 49.5%;
}

.right-content {
  /*margin-left: 20px;*/
  float: right;
  width: 49.5%;
}

.middle-content,
.first-content,
.third-content {
  /*margin-left: 20px;*/
  float: left;
  width: 33.33%;
}

.statistics-content-chart {
  width: 100%;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*右上角选择时间左右箭头*/
.left-arrow,
.right-arrow {
  padding-bottom: 2px;
}
</style>

<template>
    <div class="user-portrait">
        <!--用户特征占比模块-->
        <div class="feature-module">
            <!--特征标题-->
            <div class="feature-title">
                <p>用户特征占比</p>
            </div>
            <!--特征内容-->
            <div class="feature-content">
                <!--用户性别占比模块-->
                <div class="submodule">
                    <ve-ring class="chart-style" width="345px" height="178px" :data="chartSexData" :extend="sexSetend"></ve-ring>
                </div>
                <!--用户年龄占比模块-->
                <div class="submodule">
                    <ve-ring class="chart-style" width="345px" height="178px" :data="chartAgeData" :extend="ageSetend" ></ve-ring>
                </div>
                <!--租房价格区间占比模块-->
                <div class="submodule">
                    <ve-ring class="chart-style" width="345px" height="178px" :data="chartPriceSectionData" :extend="priceSectionSetend"  ></ve-ring>
                </div>
                <!--各类付款方式占比模块-->
                <div class="submodule-other">
                    <ve-ring class="chart-style" width="345px" height="178px" :data="chartPayTypeData" :extend="payTypeSetend" ></ve-ring>
                </div>
            </div>
        </div>
        <!--违约用户特征占比模块-->
        <div class="feature-module">
            <!--特征标题-->
            <div class="feature-title">
                <p>违约用户特征</p>
            </div>
            <!--特征内容-->
            <div class="feature-content">
                <!--违约用户数量-->
                <div class="default-quantity">
                    <div class="default-title">
                        违约用户/总用户
                    </div>
                    <div class="default-num">
                       <span class="user-num">{{defaultsNum}}</span>
                        <span class="total-user-num">/{{totalUserNum}}</span>
                    </div>
                </div>
                <!--违约用户年龄模块-->
                <div class="submodule">
                    <ve-ring class="chart-style" width="345px" height="178px" :data="breachGlobalUserAge" :extend="ageSetend" ></ve-ring>
                </div>
                <!--违约用户性别占比模块-->
                <div class="submodule-other">
                    <ve-ring class="chart-style" width="345px" height="178px" :data="breachGlobalUserSex" :extend="sexSetend"></ve-ring>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {globaluserId, responseUtil} from "../../libs/rongxunUtil";
    import {
        breachGlobaluserAge,
        breachGlobaluserNum, breachGlobaluserSex,
        globaluserAge,
        globaluserNum,
        globaluserSex, rentPriceRange,
        staffLeavingData, userPaymantType,businessUserPaymantType
    } from "../../getData/getData";

    export default {
        name: "userPortrait",

        data () {

            return {
                defaultsNum:'332',  //违约用户人数
                totalUserNum: '323123',   //总用户数量

                /********************************性别占比样式 start **************************/
                sexSetend: {
                    color: ["#19d4ae", "#5ab1ef", "#fa6e86", "#ffb980", "#0067a6", "#c4b4e4", "#d87a80", "#9cbbff", "#d9d0c7", "#87a997", "#d49ea2", "#5b4947", "#7ba3a8"],
                    // 右侧数量显示样式
                    legend: {
                        orient: 'vertical',
                        left: 180,
                        top: 70,
                        // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
                        textStyle: {
                            color: '#d8d8d8'
                        },
                        icon: 'circle'
                    },
                    //右侧文字显示样式
                    title: {
                        left: 180,
                        top: 40,
                        text: '用户性别占比',
                        textStyle: {
                            fontSize: 13
                        }
                    },
                    //左侧中心文字显示样式
                    // graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
                    //     type: 'group',
                    //     left: 72,
                    //     top: 86,
                    //     children: [
                    //         {
                    //             type: 'text',
                    //             style: {
                    //                 text: '性别',
                    //                 textAlign: 'center',
                    //                 fill: 'black',
                    //                 font: 'bolder 0.4rem "Microsoft YaHei", sans-serif'
                    //             }
                    //         },
                    //     ]
                    // },
                    series: {
                        // 线的样式
                        // labelLine: {
                        //     length: 50,
                        //     // 第二条线
                        //     length2: 5,
                        //     lineStyle: {
                        //         // 线的大小
                        //         width: 10
                        //     }
                        // },

                        // 扇叶样式
                        label: {
                            normal: {
                                // 是否显示扇叶
                                show: false,
                                position: 'insideLeft',
                                // 文字大小
                                fontSize: 30
                            }
                        },
                        // 位置
                        center: ['25%', '50%'],
                        // 大小
                        radius : ['48%', '60%'],
                    }
            },
                /********************************性别占比样式 end   **************************/

                /********************************年龄占比样式 start **************************/
                ageSetend: {
                    legend: {
                        orient: 'vertical',
                        left: 165,
                        top: 45,
                        // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
                        textStyle: {
                            color: '#d8d8d8'
                        },
                        icon: 'circle'
                    },
                    title: {
                        left: 165,
                        top: 15,
                        text: '用户年龄占比',
                        textStyle: {
                            fontSize: 13
                        }
                    },
                    // graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
                    //     type: 'group',
                    //     left: 72,
                    //     top: 86,
                    //     children: [
                    //         {
                    //             type: 'text',
                    //             style: {
                    //                 text: '年龄',
                    //                 textAlign: 'center',
                    //                 fill: 'black',
                    //                 font: 'bolder 0.4rem "Microsoft YaHei", sans-serif'
                    //             }
                    //         },
                    //     ]
                    // },
                    series: {
                        // 线的样式
                        // labelLine: {
                        //     length: 50,
                        //     // 第二条线
                        //     length2: 5,
                        //     lineStyle: {
                        //         // 线的大小
                        //         width: 10
                        //     }
                        // },

                        // 扇叶样式
                        label: {
                            normal: {
                                // 是否显示扇叶
                                show: false,
                                position: 'insideLeft',
                                // 文字大小
                                fontSize: 30
                            }
                        },
                        // 位置
                        center: ['25%', '50%'],
                        // 大小
                        radius : ['48%', '60%'],
                    }
                },
                /********************************年龄占比样式 end   **************************/

                /********************************价格区间占比样式 start **************************/
                priceSectionSetend: {
                    legend: {
                        orient: 'vertical',
                        left: 180,
                        top: 40,
                        // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
                        textStyle: {
                            color: '#d8d8d8'
                        },
                        icon: 'circle'
                    },
                    title: {
                        left: 180,
                        top: 10,
                        text: '租房价格区间占比',
                        textStyle: {
                            fontSize: 13
                        }
                    },
                    // graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
                    //     type: 'group',
                    //     left: 57,
                    //     top: 83,
                    //     children: [
                    //         {
                    //             type: 'text',
                    //             style: {
                    //                 text: '价格区间',
                    //                 textAlign: 'center',
                    //                 fill: 'black',
                    //                 font: 'bolder 0.4rem "Microsoft YaHei", sans-serif'
                    //             }
                    //         },
                    //     ]
                    // },
                    series: {
                        // 线的样式
                        // labelLine: {
                        //     length: 50,
                        //     // 第二条线
                        //     length2: 5,
                        //     lineStyle: {
                        //         // 线的大小
                        //         width: 10
                        //     }
                        // },

                        // 扇叶样式
                        label: {
                            normal: {
                                // 是否显示扇叶
                                show: false,
                                position: 'insideLeft',
                                // 文字大小
                                fontSize: 30
                            }
                        },
                        // 位置
                        center: ['25%', '50%'],
                        // 大小
                        radius : ['48%', '60%'],
                    }
                },
                /********************************价格区间占比样式 end   **************************/

                /********************************付款方式占比样式 start **************************/
                payTypeSetend: {
                    legend: {
                        orient: 'vertical',
                        left: 180,
                        top: 30,
                        // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
                        textStyle: {
                            color: '#d8d8d8'
                        },
                        icon: 'circle'
                    },
                    title: {
                        left: 180,
                        top: 1,
                        text: '各类付款方式占比',
                        textStyle: {
                            fontSize: 13
                        }
                    },
                    // graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
                    //     type: 'group',
                    //     left:57,
                    //     top: 83,
                    //     children: [
                    //         {
                    //             type: 'text',
                    //             style: {
                    //                 text: '付款方式',
                    //                 textAlign: 'center',
                    //                 fill: 'black',
                    //                 font: 'bolder 0.4rem "Microsoft YaHei", sans-serif'
                    //             }
                    //         },
                    //     ]
                    // },
                    series: {
                        // 线的样式
                        // labelLine: {
                        //     length: 50,
                        //     // 第二条线
                        //     length2: 5,
                        //     lineStyle: {
                        //         // 线的大小
                        //         width: 10
                        //     }
                        // },

                        // 扇叶样式
                        label: {
                            normal: {
                                // 是否显示扇叶
                                show: false,
                                position: 'insideLeft',
                                // 文字大小
                                fontSize: 30
                            }
                        },
                        // 位置
                        center: ['25%', '50%'],
                        // 大小
                        radius : ['48%', '60%'],
                    }
                },
                /********************************付款方式占比样式 end   **************************/

                chartSexData: {
                    columns: ['name', 'value'],
                    rows: [
                        // { 'name': '男', 'value': 1393 },
                        // { 'name': '女', 'value': 3530 },
                    ]
                },
                chartAgeData: {
                    columns: ['name', 'value'],
                    rows: [
                        // { 'name': '11-20', 'value': 1393 },
                        // { 'name': '21-30', 'value': 3530 },
                        // { 'name': '31-40', 'value': 2923 },
                        // { 'name': '41-50', 'value': 1723 },
                        // { 'name': '51-60', 'value': 3792 },
                    ]
                },
				//违约用户年龄
				breachGlobalUserAge: {
				    columns: ['name', 'value'],
				    rows: []
				},
				//违约用户性别
				breachGlobalUserSex: {
				    columns: ['name', 'value'],
				    rows: []
				},
                chartPriceSectionData: {
                    columns: ['name', 'value'],
                    rows: [
                        // { 'name': '700以下', 'value': 1393 },
                        // { 'name': '700-900', 'value': 3530 },
                        // { 'name': '900-1100', 'value': 2923 },
                        // { 'name': '1100-1300', 'value': 1723 },
                        // { 'name': '1300-1500', 'value': 3792 },
                        // { 'name': '1500以上', 'value': 4593 }
                    ]
                },
                chartPayTypeData: {
                    //columns: ['payTypeVal', 'num'],
					columns: ['name', 'value'],
                    rows: [
                        // { '付款方式': '微信', '人数': 1393 },
                        // { '付款方式': '支付宝', '人数': 3530 },
                        // { '付款方式': '银联', '人数': 2923 },
                    ]
                }
            }
        },
        mounted() {
            this.globaluserSex()
            this.globaluserAge()
            this.globaluserNum()
            this.breachGlobaluserNum()
            this.breachGlobaluserAge()
            this.breachGlobaluserSex()
            this.rentPriceRange()
            this.userPaymantType()
        },
        methods : {
            //用户性别占比
            globaluserSex(){
                const that = this
                let initData = {}
                initData.user_id = globaluserId() //userid
                globaluserSex(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
						
                        let list = response.data.data.list;
						
                        that.chartSexData.rows = list

                    })
                })
            },
            //用户年龄占比
            globaluserAge(){
                const that = this
                let initData = {}
                initData.user_id = globaluserId() //userid
                globaluserAge(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
                        let list = response.data.data.list;
                        that.chartAgeData.rows = list

                    })
                })
            },
            //用户数量
            globaluserNum(){
                const that = this
                let initData = {}
                initData.user_id = globaluserId() //userid
                globaluserNum(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
                        let totalUserNum = response.data.data.list[0].value;
                        that.totalUserNum = totalUserNum

                    })
                })
            },
            //违约用户数量
            breachGlobaluserNum(){
                const that = this
                let initData = {}
                initData.user_id = globaluserId() //userid
                breachGlobaluserNum(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
                        let defaultsNum = response.data.data.list[0].value;
                        that.defaultsNum = defaultsNum

                    })
                })
            },

            //违约用户性别
            breachGlobaluserSex(){
                const that = this
                let initData = {}
                //initData.user_id = globaluserId() //userid
                breachGlobaluserSex(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
                        let list = response.data.data.list;
                        that.breachGlobalUserSex.rows = list
						
                    })
                })
            },
            //违约用户年龄
            breachGlobaluserAge(){
                const that = this
                let initData = {}
                //initData.user_id = globaluserId() //userid
                breachGlobaluserAge(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.breachGlobalUserAge.rows = response.data.data.list;
						
                    })
                })
            },
            //租房价格区间
            rentPriceRange(){
                const that = this
                let initData = {}
                initData.user_id = globaluserId() //userid
                rentPriceRange(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
                        let list = response.data.data.list;
                        that.chartPriceSectionData.rows = list
                    })
                })
            },
            //各类付款方式占比
            userPaymantType(){
                const that = this
                let initData = {}
                //initData.user_id = globaluserId() //userid
                businessUserPaymantType(initData).then(function(response) {
                    responseUtil.dealResponse(that, response, () => {
						console.log(response)
                        let list = response.data.data.list;
                        // debugger
                        that.chartPayTypeData.rows = list
                    })
                })
            },
        }
    }
</script>

<style scoped>
    /*背景颜色*/
    .user-portrait {
        width: 10rem;
        background-color: #FAFAFA;
        height: 1300px;
    }

    /*模块样式*/
    .feature-module {
        width: 92%;
        /*background-color: #00B0B6;*/
        margin: 0 auto;
        margin-top: 20px;
    }

    /*左上角标题*/
    .feature-title p {
        font-size: 14px;
        font-family: PingFangSC-Semibold;
        color: #FF5D3B;
        font-weight: bolder;
        margin: 10px 0px 5px 0px;
    }

    /*模块主题内容*/
    .feature-content {
        width: 100%;
        /*height: 500px;*/
        border-radius: 8px;
        background-color: white;
    }

    /*子模块样式*/
    .submodule {
        width: 100%;
        height: 178.5px;
        border-bottom: #FF5D3B dashed 1px;
    }

    .submodule-other {
        width: 100%;
        height: 178.5px;
    }

    /*图表样式*/
    .chart-style {
        height: 115px;
        width: 115px;
    }

    /*违约用户数量样式*/
    .default-quantity {
        text-align: center;
        width: 100%;
        height:104px;
        border-bottom: #FF5D3B dashed 1px;
    }

    .default-title {
        width: 90px;
        height: 17px;
        margin: 0 auto;
        color: #999999;
        font-size: 12px;
        font-weight: bold;
        font-family: PingFangSC-Regular;
        padding-top: 25px;
    }

    .default-num {
        width: 150px;
        height: 52px;
        margin: 0 auto;
    }

    /*违约用户数量样式*/
    .user-num {
        color: #FF5D3B;
        font-size: 35px;
    }

    /*总用户数量样式*/
    .total-user-num {
        color: #999999;
        font-size: 14px;
        font-weight: bold;
    }
</style>

<template>
    <div>
      <div class="hotelStatisticsCardTitle">单日数据
        <div class="hotelStatisticsDateSearch">
          <div class="rightCheckTime" @click="leftSignArrow(-1)"></div>
          <span class="checkTimetitle" @click="checkStatisticsDate()">{{ statisticsDate }}</span>
          <div class="leftCheckTime" @click="leftSignArrow(1)"></div>
        </div>
      </div>
      <div class="maincomcon">
        <div class="maincomconT">
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{totalFormData.roomAmount}}</div>
              <div class="maincomconTItemfont">总房量</div>
            </div>
          </div>
          <div class="linedashed"></div>
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{totalFormData.nightAmount}}</div>
              <div class="maincomconTItemfont">已售间夜</div>
            </div>
          </div>
          <div class="linedashed"></div>
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{totalFormData.checkInRate}}%</div>
              <div class="maincomconTItemfont">出租率</div>
            </div>
          </div>
        </div>
        <div class="maincomconT">
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{totalFormData.dailyEarn}}</div>
              <div class="maincomconTItemfont">客房收入</div>
            </div>
          </div>
          <div class="linedashed"></div>
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{totalFormData.averageRoomPrice}}</div>
              <div class="maincomconTItemfont">平均房价</div>
            </div>
          </div>
          <div class="linedashed"></div>
          <div class="maincomconTItem">
            <div>
              <div>
                <div class="maincomconTItemcount">{{totalFormData.singleRoomEarn}}</div>
                <div class="maincomconTItemfont">单房收益</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <van-popup v-model="isSelectDateShow" position="bottom">
        <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            item-height="35px"
            @confirm="checkStatisticsDateConfirm"
            @cancel="isSelectDateShow = false"
        />
        <!--        :min-date="minSignDate"-->
        <!--        :max-date="maxSignDate"-->
      </van-popup>

      <div class="hotelStatisticsCardTitle">出租率</div>
      <div class="maincomcon chartsCommon">
        <div class="rentalRate" ref="rentalRate" style="width: 100%;height: 7rem;margin: auto">
        </div>
      </div>


      <div class="hotelStatisticsCardTitle">平均房价</div>
      <div class="maincomcon chartsCommon">
        <div class="averageHousePrice" ref="averageHousePrice" style="width: 100%;height: 7rem;margin: auto">
        </div>
      </div>




      <div class="hotelStatisticsCardTitle">单房收益</div>
      <div class="maincomcon chartsCommon">
        <div class="singleRoomIncome" ref="singleRoomIncome" style="width: 100%;height: 7rem;margin: auto">
        </div>
      </div>

    </div>
</template>

<script>
import {responseUtil} from "../../libs/rongxunUtil";
import { dataCount, intervalDataCount } from '../../getData/getData'
import util from "../contract/util";
import {DatetimePicker, Popup} from "vant";
var echarts = require('echarts')

export default {
  name: "hotelStatistics",
  components:{
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
  data(){
    return{
      statisticsDate:'',  //页面显示的日期
      statisticsRangeDate:'',  //周期搜索
      totalFormData:{  //单日数据
        roomAmount:0,  //总房间数
        nightAmount:0,  //间夜数(出房)
        checkInRate:0,  //出房率
        dailyEarn:0,   //日收益
        averageRoomPrice:0,  //房间均价
        singleRoomEarn:0,   //单房收益
      },
      totalRangeFormData:{  //表
        averageRoomPriceList:[],
        checkInRateList:[],
        dailyEarnList:[],
        nightAmountList:[],
        singleRoomEarnList:[],
      },
      isSelectDateShow:false,
      currentDate:'',  //日期选择器里的日期
    }
  },
  mounted() {
    this.statisticsDate = util.dateToString(new Date())
    this.initDataCount()
    this.initRangeData()
  },
  methods:{
    initDataCount(){
      let that = this
      let initData = {}
      initData.date = this.statisticsDate
      dataCount(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.totalFormData = response.data.data
        })
      })
    },
    initRangeData(){
      this.statisticsRangeDate = this.getWeekTwoDays(new Date(this.statisticsDate))
      let that = this
      let initData = {}
      initData.beginTime = util.dateToString(this.statisticsRangeDate[0])
      initData.endTime  = util.dateToString(this.statisticsRangeDate[1])
      intervalDataCount(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.totalRangeFormData.averageRoomPriceList = response.data.data.averageRoomPriceList   //平均房价
          that.totalRangeFormData.checkInRateList = response.data.data.checkInRateList  //出租率
          that.totalRangeFormData.dailyEarnList = response.data.data.dailyEarnList  //客房收入
          that.totalRangeFormData.nightAmountList = response.data.data.nightAmountList  //间夜
          that.totalRangeFormData.singleRoomEarnList = response.data.data.singleRoomEarnList  //单房收益
          that.rentalRate()
          that.averageHousePrice()
          that.singleRoomIncome()
        })
      })
    },
    leftSignArrow(paramType){  //0前一天，1后一天
      this.statisticsDate = util.dateToString(util.dateAddDay(new Date(this.statisticsDate),paramType))
      this.initDataCount()
      this.initRangeData()
    },

    checkStatisticsDate() {
      this.isSelectDateShow = true
      this.currentDate=new Date(this.statisticsDate)
    },
    checkStatisticsDateConfirm(){
      this.statisticsDate = util.dateToString(this.currentDate)
      this.isSelectDateShow = false
      this.initDataCount()
      this.initRangeData()

    },

    //出租率echarts
    rentalRate(){
      let maxCheckInRate = Math.max(...this.totalRangeFormData.checkInRateList)
      let maxNightAmount = Math.max(...this.totalRangeFormData.nightAmountList)

      let myChart = echarts.init(this.$refs.rentalRate)
      let option = {
        grid: {  //调整位置
          left: "4%",
          right:'3%',
          bottom:'-1%',
          containLabel: true
        },
        color: [   //series的颜色
          '#ca8622',
          '#8bc34a',
          '#5470C6',
          '#91CC75',
          '#EE6666',
          '#5AB1EF',
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {  //右上角工具箱
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ['line', 'bar'] },
            // restore: { show: true },
            // saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['出租率', '间夜']   ////series的name一样就会显示上方颜色说明，按照这个的排序
        },
        xAxis: [  //行维度
          {
            type: 'category',
            data: this.getWeekDays(new Date(this.statisticsDate)),
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [    //纵坐标出现的维度
          {
            type: 'value',
            name: '出租率',   //纵坐标的名
            min: 0,
            max: Math.ceil(maxCheckInRate/5)*5,
            interval: Math.ceil(maxCheckInRate/5),
            axisLabel: {
              formatter: '{value} %'
            }
          },
          {
            type: 'value',
            name: '间夜',
            min: 0,
            max: Math.ceil(maxNightAmount/5)*5,
            interval: Math.ceil(maxNightAmount/5),
            axisLabel: {
              formatter: '{value} 间'
            }
          }
        ],
        series: [   //数据
          {
            name: '出租率',//上面的标识
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            // label: {
            //   show: true,
            //   position: 'top',
            // },
            label: {
              show: true,
              position: 'inside',
              fontWeight:'bold',
            },
            data: this.totalRangeFormData.checkInRateList
          },
          {
            name: '间夜',
            yAxisIndex: 1,
            type: 'bar',
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 间';
              }
            },
            data: this.totalRangeFormData.nightAmountList
          }
        ]
      };
      myChart.setOption(option)
    },
    //平均房价echarts
    averageHousePrice(){
      let maxDailyEarn = Math.max(...this.totalRangeFormData.dailyEarnList)
      let maxNightAmount = Math.max(...this.totalRangeFormData.nightAmountList)
      let maxAverageRoomPrice = Math.max(...this.totalRangeFormData.averageRoomPriceList)
      let myChart = echarts.init(this.$refs.averageHousePrice)
      let option = {
        grid: {  //调整位置
          left: "4%",
          right:'-7%',
          bottom:'0%',
          containLabel: true
        },
        color: [   //series的颜色
          '#57a3f3',
          '#8bc34a',
          '#ca8622',
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {  //右上角工具箱
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ['line', 'bar'] },
            // restore: { show: true },
            // saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['客房收入', '间夜', '平均房价']   ////series的name一样就会显示上方颜色说明，按照这个的排序
        },
        xAxis: [  //行维度
          {
            type: 'category',
            data: this.getWeekDays(new Date(this.statisticsDate)),
            axisPointer: {
              type: 'shadow',  //'line' 直线指示器，'shadow' 阴影指示器，none' 无指示器
              // snap:true
            },
            axisLabel:{
              // rotate: 30
            }
          }
        ],
        yAxis: [    //纵坐标出现的维度
          {
            type: 'value', //数值轴、'category' 类目轴，适用于离散的类目数据、'time' 时间轴，适用于连续的时序数据，'log' 对数轴。适用于对数数据。
            name: '客房收入',   //纵坐标的名
            position: 'left',
            alignTicks: true,   // 在多个 y 轴为数值轴的时候，可以开启该配置项自动对齐刻度。
            min: 0,
            max: Math.ceil(maxDailyEarn/5)*5,
            interval: Math.ceil(maxDailyEarn/5),
            axisLine: {
              show: true,
              lineStyle: {
                // color: '#008000'
              }
            },
            axisLabel: {
              formatter: '{value} 元'
            }
          },
          {
            type: 'value',
            name: '间夜',
            position: 'right',
            alignTicks: true,
            min: 0,
            max: Math.ceil(maxNightAmount/5)*5,
            interval: Math.ceil(maxNightAmount/5),
            axisLabel: {
              formatter: '{value} 间'
            }
          },
          {
            type: 'value',
            name: '平均房价',
            position: 'right',
            alignTicks: true,
            show:false,
            offset: 60,
            min: 0,
            max: Math.ceil(maxAverageRoomPrice/5)*5,
            interval: Math.ceil(maxAverageRoomPrice/5),
            axisLabel: {
              formatter: '{value} 元'
            }
          },
        ],
        series: [   //数据
          {
            name: '客房收入',//上面的标识
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            // label: {
            //   show: true,
            //   position: 'insideTop',
            // },
            data: this.totalRangeFormData.dailyEarnList
          },
          {
            name: '间夜',//上面的标识
            type: 'bar',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml';
              }
            },
            // label: {
            //   show: true,
            //   position: 'insideTop',
            // },
            data: this.totalRangeFormData.nightAmountList
          },
          {
            name: '平均房价',
            type: 'line',
            yAxisIndex: 2,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            label: {
              show: true,
              position: 'top',
            },
            data: this.totalRangeFormData.averageRoomPriceList
          }
        ]
      };
      myChart.setOption(option)
    },

    //单房收入echarts
    singleRoomIncome(){
      let maxDailyEarn = Math.max(...this.totalRangeFormData.dailyEarnList)
      let maxSingleRoomEarn = Math.max(...this.totalRangeFormData.singleRoomEarnList)
      let myChart = echarts.init(this.$refs.singleRoomIncome)
      let option = {
        grid: {  //调整位置
          left: "4%",
          right:'3%',
          bottom:'-1%',
          containLabel: true
        },
        color: [   //series的颜色
          '#57a3f3',
          '#ca8622',
          '#91CC75',
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {  //右上角工具箱
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ['line', 'bar'] },
            // restore: { show: true },
            // saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['客房收入', '单房收益']   ////series的name一样就会显示上方颜色说明，按照这个的排序
        },
        xAxis: [  //行维度
          {
            type: 'category',
            data: this.getWeekDays(new Date(this.statisticsDate)),
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [    //纵坐标出现的维度
          {
            type: 'value',
            name: '客房收入',   //纵坐标的名
            min: 0,
            max: Math.ceil(maxDailyEarn/5)*5,
            interval: Math.ceil(maxDailyEarn/5),
            axisLabel: {
              formatter: '{value} 元'
            }
          },
          {
            type: 'value',
            name: '单房收益',
            min: 0,
            max: Math.ceil(maxSingleRoomEarn/5)*5,
            interval: Math.ceil(maxSingleRoomEarn/5),
            axisLabel: {
              formatter: '{value} '
            }
          }
        ],
        series: [   //数据
          {
            name: '客房收入',//上面的标识
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 元';
              }
            },
            // label: {
            //   show: true,
            //   position: 'top',
            // },
            data: this.totalRangeFormData.dailyEarnList
          },
          {
            name: '单房收益',
            yAxisIndex: 1,
            type: 'line',
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            label: {
              show: true,
              position: 'insideTop',
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            data: this.totalRangeFormData.singleRoomEarnList
          }
        ]
      };
      myChart.setOption(option)
    },


    //当前日所在星期
    getWeekDays(date) {
      const daysOfWeek = [];
      for (let i = 1; i <= 7; i++) {
        // let month = date.getMonth() + 1
        // if(Number(month) < 10){
        //   month = '0'+ month
        // }
        // console.log(date.getDay())  //从给定的Date对象中获取星期几0~6（7是0）
        let day = date.getDay()===0?date.getDate() - date.getDay() - 7 + i:date.getDate() - date.getDay() + i
        if(Number(day) < 10){
          day = '0'+ day
        }
        // month + '-' +
        daysOfWeek.push(day);
      }
      return daysOfWeek;
    },

    getWeekTwoDays(date){
      let day = date.getDay()===0?date.getDate() - date.getDay() - 7 + 1:date.getDate() - date.getDay() + 1
      let secday = date.getDay()===0?date.getDate() - date.getDay() - 7 + 7:date.getDate() - date.getDay() + 7
      let beginDate = new Date(new Date(date.getFullYear(), date.getMonth(), day))
      let endDate = new Date(new Date(date.getFullYear(), date.getMonth(), secday))
      return [beginDate,endDate]
    }
  }
}
</script>

<style scoped lang="less">
//小标题
.hotelStatisticsCardTitle {
  color: #ff5d3b;
  font-size: 0.37333rem;
  text-align: left;
  width: 90%;
  height: 0.6rem;
  line-height: 0.6rem;
  margin-left: 5%;
  padding-top: 0.4rem;
  margin-bottom: 10px;
  z-index: 8888;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hotelStatisticsDateSearch{
  display: flex;
  align-items: center;
}
//日期搜索
.leftCheckTime {
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
  margin-left: 5px;
}
.rightCheckTime {
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  margin-right: 5px;
}
.checkTimetitle {
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 0 1px;
}



//出房数据
.maincomcon {
  margin: 0 15px;
  background: #fff;
  height: 100%;
  border-radius: 8px;
}
.flexCommon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chartsCommon{
  padding: 12px 0px 16px;
}
.maincomconT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  /* background: #f8f8f8; */
}
//竖分割线
.linedashed {
  height: 40px;
  width: 0px;
  border-right: 1px dashed #ff5d3b;
}
//一项数据格
.maincomconItem {
  flex: 1;
  height: 80px;
}
.flexCenCommon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.maincomconTItem {
  flex: 1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
//数
.maincomconTItemcount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Oswald-Regular;
  font-size: 20px;
  color: #222222;
  font-weight: bolder;

}
//字
.maincomconTItemfont {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #999999;
  font-family: PingFangSC-Regular;
  margin-top: 4px;
  font-weight: bolder;
}
.maincomconTItemcount{
  font-size: 14px;
}





</style>
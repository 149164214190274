<template>
  <div class="decoration-statistics">
    <!--项目选择模块-->
    <div class="module-small" @click="showProject">
      <span class="project-name">{{
        departmentName != undefined && departmentId != ''
          ? departmentName
          : '部门检索'
      }}</span>
      <img class="drop-down-image" src="../../assets/images/drop-down.png" />
    </div>
    <!--拉下菜单-->
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <div class="choose-company-text">选择公司</div>
      <van-row justify="center">
        <div v-for="(item, index) in companyList" :key="index">
          <van-col span="8" offset="4" class="company-col-style"
            ><div
              :class="
                checkedIndex == index
                  ? 'company-col-checked-style'
                  : 'company-text-style'
              "
              @click="selectCompany(item, index)"
            >
              {{ item.departmentName ? item.departmentName : '部门检索' }}
            </div></van-col
          >
        </div>
      </van-row>
      <van-tree-select
        style="margin-top: 20px"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        :items="items"
        @click-nav="selectArea"
        @click-item="selectDepartment"
      />
      <div class="search">
        <van-button
          style="width: 2rem; color: orangered;border-color: orangered"
          plain
          type="primary"
          @click="resetDepartmentInfo"
          >清空</van-button
        >
        <van-button
          @click="searchDepartmentInfo"
          style="width: 2.8rem;margin-left: 4rem"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="normal"
        >
          筛选
        </van-button>
      </div>
    </van-popup>

    <!--各维修占比-->
    <div class="module-charts">
      <div>
        <!--统计标题-->
        <span class="left-title">
          各维修占比
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(11)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(11)">{{
            maintainRatioMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(12)">{{
            maintainRatioMaxDate
          }}</span>
          <img
            @click="leftArrow(12)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-chart">
        <ve-ring
          class="chart-style"
          width="345px"
          height="480px"
          :data="chartEveryComplaintData"
          :extend="everyComplaintSetend"
        ></ve-ring>
      </div>
    </div>
    <!--维修完成时长-->

    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          维修完成时长
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(1)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(1)">{{
            complaintFinishMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(2)">{{
            complaintFinishMaxDate
          }}</span>
          <img
            @click="leftArrow(2)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-figure">
        <div class="description-content">
          <div class="money-big-font">
            {{ maintainFinishInfo.maintainFinishTimeLong }}
          </div>
          <div class="description-text">平均时长/时</div>
        </div>
      </div>
    </div>

    <!--性别占比-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          满意度
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(3)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(3)">{{
            satisfactionMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(4)">{{
            satisfactionMaxDate
          }}</span>
          <img
            @click="leftArrow(4)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-pieChart">
        <!--保洁满意度-->
        <!-- <div class="left-content">
                    <ve-pie class="chart-style" width="4.9rem" height="3.8rem" :data="chartCleanSatisfactionData" :extend="cleanSatisfactionSetend"></ve-pie>
                </div> -->

        <div class="left-content">
          <div class="money-small-font">{{ cleanSatisfaction }}</div>
          <div class="description-text">保洁满意度</div>
        </div>
        <div class="right-content">
          <div class="money-small-font">{{ repairSatisfaction }}</div>
          <div class="description-text">维修满意度</div>
        </div>
        <!--维修满意度-->
        <!-- <div class="right-content">
                    <ve-pie class="chart-style" width="4.9rem" height="3.8rem" :data="chartMaintainSatisfactionData" :extend="maintainSatisfactionData"></ve-pie>
                </div> -->
      </div>
    </div>
    <!--维修数据-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          维修数据
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(5)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(5)">{{
            maintainMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(6)">{{
            maintainMaxDate
          }}</span>
          <img
            @click="leftArrow(6)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-figure">
        <!--左侧图片-->
        <div class="first-content">
          <img
            class="left-image"
            src="../../assets/images/statistics-maintain.png"
          />
        </div>
        <div class="middle-line"></div>
        <div class="third-content">
          <div class="money-small-font">
            {{ maintainInfo.maintainFinishTimeLong }}
          </div>
          <div class="description-text">报修解决时长</div>
        </div>
        <div class="middle-content">
          <div class="money-small-font">
            {{ maintainInfo.maintainResponseTimeLong }}
          </div>
          <div class="description-text">维修响应时长</div>
        </div>
      </div>
    </div>
    <!--保洁-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          保洁数据
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(7)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(7)">{{
            cleanMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(8)">{{
            cleanMaxDate
          }}</span>
          <img
            @click="leftArrow(8)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-figure">
        <!--左侧图片-->
        <div class="first-content">
          <img
            class="left-image"
            src="../../assets/images/statistics-clean.png"
          />
        </div>
        <div class="middle-line"></div>
        <div class="middle-content">
          <div class="money-small-font">
            {{ cleanInfo.cleanResponseTimeLong }}
          </div>
          <div class="description-text">保洁响应时长</div>
        </div>
        <div class="third-content">
          <div class="money-small-font">
            {{ cleanInfo.cleanFinishTimeLong }}
          </div>
          <div class="description-text">保洁完成时长</div>
        </div>
      </div>
    </div>

    <!--配货数据-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          配货数据
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(9)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(9)">{{
            distributionMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(10)">{{
            distributionMaxDate
          }}</span>
          <img
            @click="leftArrow(10)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-figure">
        <!--左侧图片-->
        <div class="first-content">
          <img
            class="left-image"
            src="../../assets/images/statistics-allocation.png"
          />
        </div>
        <div class="middle-line"></div>
        <div class="middle-content">
          <div class="money-small-font">
            {{ distributionInfo.distributionResponseTimeLong }}
          </div>
          <div class="description-text">配货响应时长</div>
        </div>
        <div class="third-content">
          <div class="money-small-font">
            {{ distributionInfo.distributionFinishTimeLong }}
          </div>
          <div class="description-text">配货平均时长</div>
        </div>
      </div>
    </div>
    <!--配货物品情况-->
    <div class="module-charts">
      <div>
        <!--统计标题-->
        <span class="left-title">
          配货物品情况
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(13)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(13)">{{
            distributionGoodsMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(14)">{{
            distributionGoodsMaxDate
          }}</span>
          <img
            @click="leftArrow(14)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-chart1">
        <ve-ring
          class="chart-style"
          width="345px"
          height="342px"
          :data="chartDistributionGoodsData"
          :extend="distributionGoodsSetend"
        ></ve-ring>
      </div>
    </div>

    <!--时间选择-->
    <van-popup v-model="isDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        item-height="35px"
        @confirm="dateConfirm"
        @cancel="isDateShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  DatetimePicker,
  Popup,
  Picker,
  TreeSelect,
  Col,
  Row,
  Button,
} from 'vant'

import {
  maintainGenre, //维修类型占比
  repairStatistics, //维修完成时长 报修解决时长  维修响应时长
  cleanResponseTime, //保洁响应时长
  cleanStatistics, //保洁完成时长
  distributionResponseTime, //配货响应时长
  distributionStatistics, //配货分类占比
  distributionFinishTime, //配货平均时长
  queryAuthLevelDepartment,
} from '../../getData/getData'
import { responseUtil, getStaffId } from '@/libs/rongxunUtil'
import util from '../contract/util2'
var echarts = require('echarts')

export default {
  name: 'afterRentStatistics',
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
  },
  data() {
    return {
      checkedIndex: 0,
      departmentName: '',
      departmentId: '', //部门id
      functionId: '1004',
      companyList: [],
      activeId: -1,
      activeIndex: 0,
      //新的列表接口所需数据
      departmentAuthData: {},
      items: [
        {
          text: '浙江',
          children: [
            { text: '杭州', id: 1 },
            { text: '温州', id: 2 },
          ],
        },
        {
          text: '辽宁',
          children: [
            { text: '沈阳', id: 3 },
            { text: '大连', id: 4 },
          ],
        },
        {
          text: '江苏',
          children: [
            { text: '南京', id: 5 },
            { text: '无锡', id: 6 },
          ],
        },
      ],
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: '集寓项目名称',
      projectDataInfo: [],

      maintainFinishInfo: {
        maintainFinishTimeLong: '0', //维修完成时长
      },

      cleanSatisfaction: {}, //保洁满意度
      repairSatisfaction: {}, //维修满意度

      //维修数据
      maintainInfo: {
        maintainResponseTimeLong: '324',
        maintainFinishTimeLong: '234',
      },

      //保洁数据
      cleanInfo: {
        cleanResponseTimeLong: '324',
        cleanFinishTimeLong: '234',
      },

      //配货数据
      distributionInfo: {
        distributionResponseTimeLong: '324',
        distributionFinishTimeLong: '234',
      },

      chooseType: '', //选择类型 时长&总额 最小时间 or 验收情况  最小时间  等等
      currentDate: new Date(), //时间选择器绑定的时间
      isDateShow: false, //时间选择显示与否

      complaintFinishMinDate: this.formatDate(new Date()), //维修完成 最小时间  1
      complaintFinishMaxDate: this.formatDate(new Date()), //维修完成 最大时间  2
      satisfactionMinDate: this.formatDate(new Date()), //投诉、维修满意度  最大时间  3
      satisfactionMaxDate: this.formatDate(new Date()), //投诉、维修满意度  最大时间  4
      maintainMinDate: this.formatDate(new Date()), //维修数据 最小时间  5
      maintainMaxDate: this.formatDate(new Date()), //维修数据 最大时间  6
      cleanMinDate: this.formatDate(new Date()), //保洁数据 最小时间  7
      cleanMaxDate: this.formatDate(new Date()), //保洁数据 最大时间  8
      distributionMinDate: this.formatDate(new Date()), //配货数据 最小时间  9
      distributionMaxDate: this.formatDate(new Date()), //配货数据 最大时间  10
      distributionGoodsMinDate: this.formatDate(new Date()), //配货物品分类数据 最小时间  9
      distributionGoodsMaxDate: this.formatDate(new Date()), //配货物品分类数据 最大时间  10

      maintainRatioMinDate: this.formatDate(new Date()), //维修占比最小时间  11
      maintainRatioMaxDate: this.formatDate(new Date()), //维修占比最大时间  12

      /********************************各类维修类型占比样式 start **************************/
      everyComplaintSetend: {
        legend: {
          //orient: 'vertical',
          left: 30,
          top: 230,
          // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        title: {
          left: 120,
          top: 200,
          text: '维修类型占比',
          textStyle: {
            fontSize: 13,
          },
        },
        // graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
        //     width: '30',
        //     type: 'group',
        //     left: 140,
        //     top: 90,
        //     children: [
        //         {
        //             type: 'text',
        //             left: 110,
        //             top: 83,
        //             style: {
        //                 width:'30',
        //                 //text: '累计投诉/次',
        //                 textAlign: 'center',
        //                 fill: '#999999',
        //                 font: 'bolder 12px "Microsoft YaHei", sans-serif'
        //             }
        //         },
        //         {
        //             type: 'text',
        //             left: 120,
        //             top: 100,
        //             style: {
        //                 //text: '3432',
        //                 textAlign: 'center',
        //                 fill: 'black',
        //                 font: 'bolder 16px "Microsoft YaHei", sans-serif'
        //             }
        //         },
        //     ]
        // },
        series: {
          // 扇叶样式
          label: {
            normal: {
              // 是否显示扇叶
              show: false,
              position: 'insideLeft',
              // 文字大小
              fontSize: 30,
            },
          },
          // 位置
          center: ['50%', '20%'],
          // 大小
          radius: ['32%', '40%'],
        },
      },

      chartEveryComplaintData: {
        columns: ['类型', '数量'],
        rows: [
          // { '价格区间': '500-1000', '人数': 1393 },
          // { '价格区间': '1000-1500', '人数': 3530 },
          // { '价格区间': '1500-2000', '人数': 2923 },
          // { '价格区间': '2000-2500', '人数': 1723 },
          // { '价格区间': '2500-3000', '人数': 3792 },
          // { '价格区间': '3000-3500', '人数': 4593 }
        ],
      },
      /********************************各类维修类型占比样式 end   **************************/

      /********************************保洁满意度占比样式 start **************************/
      // cleanSatisfactionSetend: {
      //     legend: {
      //         show:false,
      //         orient: 'none',
      //         left: 55,
      //         top:155,
      //         // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
      //         textStyle: {
      //             color: '#d8d8d8'
      //         },
      //         icon: 'none'
      //     },
      //     title: {
      //         left: 50,
      //         top: 90,
      //         text: '保洁满意度',
      //         textStyle: {
      //             fontSize: 13
      //         }
      //     },

      //     graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
      //         width: '30',
      //         type: 'group',
      //         left: 76,
      //         top: 120,
      //         children: [
      //             {
      //                 type: 'text',
      //                 left: 76,
      //                 top: 120,
      //                 style: {
      //                     width:'30',
      //                     text: '70%',
      //                     textAlign: 'center',
      //                     fill: '#FF5D3B',
      //                     font: 'bold 12px "Microsoft YaHei", sans-serif'
      //                 }
      //             },
      //         ]
      //     },

      //     series: {
      //         // 扇叶样式
      //         label: {
      //             normal: {
      //                 // 是否显示扇叶
      //                 show: false,
      //                 position: 'insideLeft',
      //                 // 文字大小
      //                 fontSize: 100
      //             }
      //         },
      //         // 位置
      //         center: ['47%', '35%'],
      //         // 大小
      //         radius : ['0', '40%'],
      //     }
      // },

      // chartCleanSatisfactionData: {
      //     columns: ['类型', '数量'],
      //     rows: [
      //         { '类型': '空置(343|8%)', '数量': 3530  },
      //         { '类型': '转租(343|8%)', '数量': 1393 },
      //     ]
      // },
      /********************************保洁满意度样式 end   **************************/

      /********************************维修满意度占比样式 start **************************/
      // maintainSatisfactionData: {
      //     legend: {
      //         show:false,
      //         orient: 'none',
      //         left: 55,
      //         top:155,
      //         // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
      //         textStyle: {
      //             color: '#d8d8d8'
      //         },
      //         icon: 'none'
      //     },
      //     title: {
      //         left: 50,
      //         top: 90,
      //         text: '维修满意度',
      //         textStyle: {
      //             fontSize: 13
      //         }
      //     },

      //     graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
      //         width: '30',
      //         type: 'group',
      //         left: 76,
      //         top: 120,
      //         children: [
      //             {
      //                 type: 'text',
      //                 left: 76,
      //                 top: 120,
      //                 style: {
      //                     width:'30',
      //                     text: '30%',
      //                     textAlign: 'center',
      //                     fill: '#FF5D3B',
      //                     font: 'bold 12px "Microsoft YaHei", sans-serif'
      //                 }
      //             },
      //         ]
      //     },

      //     series: {
      //         // 扇叶样式
      //         label: {
      //             normal: {
      //                 // 是否显示扇叶
      //                 show: false,
      //                 position: 'insideLeft',
      //                 // 文字大小
      //                 fontSize: 100
      //             }
      //         },
      //         // 位置
      //         center: ['47%', '35%'],
      //         // 大小
      //         radius : ['0', '40%'],
      //     }
      // },

      // chartMaintainSatisfactionData: {
      //     columns: ['类型', '数量'],
      //     rows: [
      //         { '类型': '空置(343|8%)', '数量': 1393 },
      //         { '类型': '转租(343|8%)', '数量': 3530 },
      //     ]
      // },
      /********************************维修满意度样式 end   **************************/

      /********************************配货物品分类占比样式 start **************************/
      distributionGoodsSetend: {
        legend: {
          //orient: 'vertical',
          left: 30,
          top: 230,
          // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        title: {
          left: 120,
          top: 200,
          text: '配货物品分类占比',
          textStyle: {
            fontSize: 13,
          },
        },
        series: {
          // 扇叶样式
          label: {
            normal: {
              // 是否显示扇叶
              show: false,
              position: 'insideLeft',
              // 文字大小
              fontSize: 30,
            },
          },
          // 位置
          center: ['50%', '30%'],
          // 大小
          radius: ['0%', '40%'],
        },
      },

      chartDistributionGoodsData: {
        columns: ['类型', '数量'],
        rows: [
          { 类型: '空置(343|8%)', 数量: 1393 },
          { 类型: '空置1(343|8%)', 数量: 3793 },
          { 类型: '空置2(343|8%)', 数量: 2923 },

          { 类型: '空置3(343|8%)', 数量: 4593 },
        ],
      },
      /********************************配货物品分类占比样式 end   **************************/
    }
  },

  mounted() {
    //公司初始化
    this.queryAuthLevelDepartment()
  },
  methods: {
    queryAuthLevelDepartment() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId() //员工id
      initData.function_id = that.functionId //方法模块id
      queryAuthLevelDepartment(initData).then(res =>
        responseUtil.dealResponse(that, res, () => {
          that.departmentAuthData = res.data.data
          let allList = that.departmentAuthData.list
          if (allList != null) {
            that.companyList = allList.filter(item => item.level == 1)
          }
          let departmentId = that.departmentAuthData.roleDepartmentId
          that.departmentId = departmentId
          let item = that.departmentAuthData.list.find(
            item => item.id == departmentId
          )
          that.departmentName = item ? item.departmentName || '' : ''
          that.initOtherDepartment()
          that.searchDepartmentInfo()
        })
      )
    },
    initOtherDepartment() {
      let that = this
      let allList = that.departmentAuthData.list
      let departmentId = that.departmentId
      let threeLevelDepartments = allList.filter(item => item.level == 3)
      let fiveLevelDepartments = allList.filter(item => item.level == 5)
      let levelDepartments = threeLevelDepartments
        .filter(item => {
          let levelTop = item.levelTop || ''
          return levelTop.indexOf(departmentId) != -1
        })
        .map(item => {
          let result = {}
          result.children = fiveLevelDepartments
            .filter(child => {
              let levelTop = child.levelTop || ''
              return levelTop.indexOf(',' + item.id) != -1
            })
            .map(child => {
              let childResult = {}
              childResult.text = child.departmentName
              childResult.id = child.id
              childResult.level = child.level
              return childResult
            })
          result.text = item.departmentName
          result.value = item.id
          result.level = item.level
          return result
        })
      that.items = levelDepartments // 公司列表
    },
    resetDepartmentInfo() {
      let departmentId = this.departmentAuthData.roleDepartmentId
      let item = this.departmentAuthData.list.find(
        item => item.id == departmentId
      )
      this.departmentName = item ? item.departmentName || '' : ''
      this.departmentId = departmentId
      this.isShowProjectMenu = false
      this.searchDepartmentInfo()
    },

    //选择公司，联动下方区、部
    selectCompany(item, index) {
      let that = this
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(that, '权限不足')
        return
      }
      this.checkedIndex = index
      this.departmentId = item.id
      this.activeId = this.departmentId
      this.departmentName = item.departmentName
      this.initOtherDepartment()
    },
    selectArea(value) {
      let item = this.items[value]
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = item.value
      this.departmentName = item.text
      this.activeId = this.departmentId
    },
    selectDepartment(data) {
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = data.id
      this.departmentName = data.text
    },
    searchDepartmentInfo() {
      //维修类型占比
      this.getMaintainGenre()
      //维修完成时长
      this.getRepairStatistics()
      //报修解决时长  维修响应时长
      this.getRepairStatistics1()
      //保洁响应
      this.getCleanResponseTime()
      //保洁完成时长
      this.getCleanStatistics()
      //配货响应时长
      this.getDistributionResponseTime()
      //配货分类占比
      this.getDistributionStatistics()
      //配货平均时长
      this.getDistributionFinishTime()
      //保洁满意度
      this.getCleanSatisfaction()
      //维修满意度
      this.getRepairSatisfaction()

      this.isShowProjectMenu = false
    },
    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true
    },
    //取消选择
    cancelChangeProject() {
      this.projectName = '集寓项目名称'
      this.projectId = ''
      this.isShowProjectMenu = false
    },

    //维修类型占比
    getMaintainGenre() {
      var that = this
      let data = {}
      data.beginTime = util.dateFormat(that.maintainRatioMinDate)
      data.endTime = util.getCurrentMonthLast(that.maintainRatioMaxDate)

      data.departmentId = that.departmentId //departmentId
      maintainGenre(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          var a = []
          a = response.data.data.repairTypeRatio
          that.chartEveryComplaintData.rows = []
          for (var i = 0; i < a.length; i++) {
            that.chartEveryComplaintData.rows.push({
              类型: a[i].name,
              数量: a[i].value,
            })
          }
        })
      })
    },
    //维修完成时长
    getRepairStatistics() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.complaintFinishMinDate)
      data.endTime = util.getCurrentMonthLast(that.complaintFinishMaxDate)

      repairStatistics(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          //维修完成时长
          that.maintainFinishInfo.maintainFinishTimeLong = Number(
            response.data.data.repairAvgFinishTime
          ).toFixed(2)
        })
      })
    },

    //报修解决时长  维修响应时长
    getRepairStatistics1() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.maintainMinDate)
      data.endTime = util.getCurrentMonthLast(that.maintainMaxDate)
      repairStatistics(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          //报修解决时长
          that.maintainInfo.maintainFinishTimeLong = Number(
            response.data.data.repairAvgSolveTime
          ).toFixed(2)

          //维修响应时长
          that.maintainInfo.maintainResponseTimeLong = Number(
            response.data.data.repairAvgResponseTime
          ).toFixed(2)
        })
      })
    },

    //保洁响应时长
    getCleanResponseTime() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.cleanMinDate)
      data.endTime = util.getCurrentMonthLast(that.cleanMaxDate)
      cleanResponseTime(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.cleanInfo.cleanResponseTimeLong = Number(
            response.data.data.cleanAvgResponseTime
          ).toFixed(2)
        })
      })
    },
    //保洁完成时长
    getCleanStatistics() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.cleanMinDate)
      data.endTime = util.getCurrentMonthLast(that.cleanMaxDate)

      cleanStatistics(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.cleanInfo.cleanFinishTimeLong = Number(
            response.data.data.cleanAvgFinishTime
          ).toFixed(2)
        })
      })
    },
    //保洁满意度
    getCleanSatisfaction() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.satisfactionMinDate)
      data.endTime = util.getCurrentMonthLast(that.satisfactionMaxDate)
      cleanStatistics(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (response.data.data.cleanAvgScore == undefined) {
            that.cleanSatisfaction = '0%'
          } else {
            that.cleanSatisfaction =
              Number(response.data.data.cleanAvgScore * 20).toFixed(2) + '%'
          }
        })
      })
    },
    //维修满意度
    getRepairSatisfaction() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.satisfactionMinDate)
      data.endTime = util.getCurrentMonthLast(that.satisfactionMaxDate)
      repairStatistics(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (response.data.data.repairAvgScore == undefined) {
            that.repairSatisfaction = '0%'
          } else {
            that.repairSatisfaction =
              Number(response.data.data.repairAvgScore * 20).toFixed(2) + '%'
          }
        })
      })
    },
    //配货响应时长
    getDistributionResponseTime() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.distributionMinDate)
      data.endTime = util.getCurrentMonthLast(that.distributionMaxDate)
      distributionResponseTime(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.distributionInfo.distributionResponseTimeLong = Number(
            response.data.data.distributionAvgResponseTime
          ).toFixed(2)
        })
      })
    },
    //配货平均时长
    getDistributionFinishTime() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.distributionMinDate)
      data.endTime = util.getCurrentMonthLast(that.distributionMaxDate)
      distributionFinishTime(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.distributionInfo.distributionFinishTimeLong = Number(
            response.data.data.distributionFinishTime[0].avgTime
          ).toFixed(2)
        })
      })
    },
    //配货分类占比
    getDistributionStatistics() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.distributionGoodsMinDate)
      data.endTime = util.getCurrentMonthLast(that.distributionGoodsMaxDate)
      distributionStatistics(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (response.data.data.classList.length == 0) {
            that.chartDistributionGoodsData.rows = [{ 类型: '类型', 数量: 0 }]
          } else {
            var a = []
            a = response.data.data.classList
            that.chartDistributionGoodsData.rows = []
            for (var i = 0; i < a.length; i++) {
              that.chartDistributionGoodsData.rows.push({
                类型: a[i].children[0].title,
                数量: a[i].children[0].count,
              })
            }
          }
        })
      })
    },

    //选择时间
    chooseTime(value) {
      this.chooseType = value //时长&总额 最小时间  1
      this.isDateShow = true
    },

    /*时间格式转化方法*/
    formatDate(dateTime) {
      let year = `${dateTime.getFullYear()}`
      let month = `${dateTime.getMonth() + 1}`
      if (month < 10) {
        month = `0${dateTime.getMonth() + 1}`
      }
      return year + '-' + month
    },

    //确认时间
    dateConfirm(date) {
      this.currentDate = this.formatDate(date)
      if (this.chooseType == 1) {
        this.complaintFinishMinDate = this.currentDate //维修完成时长 最小时间  1
        this.getRepairStatistics() //维修完成时长
      } else if (this.chooseType == 2) {
        this.complaintFinishMaxDate = this.currentDate //维修完成时长 最大时间  2
        this.getRepairStatistics() //维修完成时长
      } else if (this.chooseType == 3) {
        this.satisfactionMinDate = this.currentDate //投诉、维修满意度  最小时间  3
        //保洁满意度
        this.getCleanSatisfaction()
        //维修满意度
        this.getRepairSatisfaction()
      } else if (this.chooseType == 4) {
        this.satisfactionMaxDate = this.currentDate //投诉、维修满意度  最大时间  4
        //保洁满意度
        this.getCleanSatisfaction()
        //维修满意度
        this.getRepairSatisfaction()
      } else if (this.chooseType == 5) {
        this.maintainMinDate = this.currentDate //维修数据 最小时间  5
        //报修解决时长  维修响应时长
        this.getRepairStatistics1()
      } else if (this.chooseType == 6) {
        this.maintainMaxDate = this.currentDate //维修数据 最大时间  6
        //报修解决时长  维修响应时长
        this.getRepairStatistics1()
      } else if (this.chooseType == 7) {
        this.cleanMinDate = this.currentDate //保洁数据 最小时间  7
        //保洁响应时长
        this.getCleanResponseTime()
        //保洁完成时长
        this.getCleanStatistics()
      } else if (this.chooseType == 8) {
        this.cleanMaxDate = this.currentDate //保洁数据 最大时间  8
        //保洁响应时长
        this.getCleanResponseTime()
        //保洁完成时长
        this.getCleanStatistics()
      } else if (this.chooseType == 9) {
        this.distributionMinDate = this.currentDate //配货数据 最小时间  9
        //配货响应时长
        this.getDistributionResponseTime()
        //配货平均时长
        this.getDistributionFinishTime()
      } else if (this.chooseType == 10) {
        this.distributionMaxDate = this.currentDate //配货数据 最大时间  10
        //配货响应时长
        this.getDistributionResponseTime()
        //配货平均时长
        this.getDistributionFinishTime()
      } else if (this.chooseType == 11) {
        this.maintainRatioMinDate = this.currentDate //维修占比 最小时间  11
        this.getMaintainGenre()
      } else if (this.chooseType == 12) {
        this.maintainRatioMaxDate = this.currentDate //维修占比 最大时间  12
        this.getMaintainGenre()
      } else if (this.chooseType == 13) {
        this.distributionGoodsMinDate = this.currentDate //配货物品分类 最小时间  11
        this.getDistributionStatistics()
      } else if (this.chooseType == 14) {
        this.distributionGoodsMaxDate = this.currentDate //配货物品分类 最大时间  12
        this.getDistributionStatistics()
      }
      this.isDateShow = false

      // this.getRepairStatistics() //维修完成时长
      // //报修解决时长  维修响应时长
      // this.getRepairStatistics1()
      // //保洁响应时长
      // this.getCleanResponseTime()
      // //保洁完成时长
      // this.getCleanStatistics()
      // //配货响应时长
      // this.getDistributionResponseTime()
      // //配货平均时长
      // this.getDistributionFinishTime()
      // //保洁满意度
      // this.getCleanSatisfaction()
      // //维修满意度
      // this.getRepairSatisfaction()
    },

    /*点击箭头，增加或减少一个月*/
    leftArrow(paramType) {
      if (paramType == 1) {
        //维修完成时长 最小时间  1
        let date = new Date(this.complaintFinishMinDate)
        this.complaintFinishMinDate = this.reduceDate(date)
        this.getRepairStatistics() //维修完成时长
      } else if (paramType == 2) {
        //维修完成时长 最大时间  2
        let date = new Date(this.complaintFinishMaxDate)
        this.complaintFinishMaxDate = this.addDate(date)
        this.getRepairStatistics() //维修完成时长
      } else if (paramType == 3) {
        //投诉、维修满意度  最小时间  3
        let date = new Date(this.satisfactionMinDate)
        this.satisfactionMinDate = this.reduceDate(date)
        //保洁满意度
        this.getCleanSatisfaction()
        //维修满意度
        this.getRepairSatisfaction()
      } else if (paramType == 4) {
        //投诉、维修满意度  最大时间  4
        let date = new Date(this.satisfactionMaxDate)
        this.satisfactionMaxDate = this.addDate(date)
        //保洁满意度
        this.getCleanSatisfaction()
        //维修满意度
        this.getRepairSatisfaction()
      } else if (paramType == 5) {
        //维修数据 最小时间  5
        let date = new Date(this.maintainMinDate)
        this.maintainMinDate = this.reduceDate(date)
        //报修解决时长  维修响应时长
        this.getRepairStatistics1()
      } else if (paramType == 6) {
        //维修数据 最大时间  6
        let date = new Date(this.maintainMaxDate)
        this.maintainMaxDate = this.addDate(date)
        //报修解决时长  维修响应时长
        this.getRepairStatistics1()
      } else if (paramType == 7) {
        //保洁数据 最小时间  7
        let date = new Date(this.cleanMinDate)
        this.cleanMinDate = this.reduceDate(date)
        //保洁响应时长
        this.getCleanResponseTime()
        //保洁完成时长
        this.getCleanStatistics()
      } else if (paramType == 8) {
        //保洁数据 最大时间  8
        let date = new Date(this.cleanMaxDate)
        this.cleanMaxDate = this.addDate(date)
        //保洁响应时长
        this.getCleanResponseTime()
        //保洁完成时长
        this.getCleanStatistics()
      } else if (paramType == 9) {
        //配货数据 最小时间  9
        let date = new Date(this.distributionMinDate)
        this.distributionMinDate = this.reduceDate(date)
        //配货响应时长
        this.getDistributionResponseTime()
        //配货平均时长
        this.getDistributionFinishTime()
      } else if (paramType == 10) {
        //配货数据 最大时间  10
        let date = new Date(this.distributionMaxDate)
        this.distributionMaxDate = this.addDate(date)
        //配货响应时长
        this.getDistributionResponseTime()
        //配货平均时长
        this.getDistributionFinishTime()
      } else if (paramType == 11) {
        //维修占比 最小时间  11
        let date = new Date(this.maintainRatioMinDate)
        this.maintainRatioMinDate = this.reduceDate(date)
        this.getMaintainGenre()
      } else if (paramType == 12) {
        //维修占比 最大时间  12
        let date = new Date(this.maintainRatioMaxDate)
        this.maintainRatioMaxDate = this.addDate(date)
        this.getMaintainGenre()
      } else if (paramType == 13) {
        //配货物品分类 最小时间  11
        let date = new Date(this.distributionGoodsMinDate)
        this.distributionGoodsMinDate = this.reduceDate(date)
        this.getDistributionStatistics()
      } else if (paramType == 14) {
        //配货物品分类 最大时间  12
        let date = new Date(this.distributionGoodsMaxDate)
        this.distributionGoodsMaxDate = this.addDate(date)
        this.getDistributionStatistics()
      }

      // this.getRepairStatistics() //维修完成时长
      // //报修解决时长  维修响应时长
      // this.getRepairStatistics1()
      // //保洁响应时长
      // this.getCleanResponseTime()
      // //保洁完成时长
      // this.getCleanStatistics()
      // //配货响应时长
      // this.getDistributionResponseTime()
      // //配货平均时长
      // this.getDistributionFinishTime()
      // //保洁满意度
      // this.getCleanSatisfaction()
      // //维修满意度
      // this.getRepairSatisfaction()
    },

    //使年月格式的时间减少一个月
    reduceDate(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month
      }
    },

    //使年月格式的时间增加一个月
    addDate(dateTime) {
      // debugger
      if (dateTime.getMonth() + 1 > 11) {
        let year = `${dateTime.getFullYear() + 1}`
        let month = `01`
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 + 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 + 1}`
        }
        return year + '-' + month
      }
    },
  },
}
</script>

<style scoped>
/*项目选择模块*/
.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  margin: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  background-color: #f7f8fa;
  border-radius: 0.1rem;
  font-size: 17px;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}

/*背景颜色*/
.decoration-statistics {
  width: 10rem;
  background-color: #fafafa;
  height: 750px;
}

/*模块样式*/
.module-literal {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/*左上角标题*/
.left-title {
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  color: #ff5d3b;
  font-weight: bolder;
  margin: 10px 0px 5px 0px;
}

/*右上角选择时间*/
.min-date,
.max-date,
.middle-character {
  font-size: 14px;
  font-weight: 600;
  padding: 0 4px;
}

/*模块主题内容*/
.statistics-content-figure {
  width: 100%;
  height: 109px;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*性别占比-饼状图*/
.statistics-content-pieChart {
  width: 100%;
  height: 112px;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*含有图表的模块*/
.module-charts {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/*右上角统一div样式*/
.right-time {
  float: right;
  margin-right: 8px;
}

/*所有数字下方描述性质字体*/
.description-text {
  font-size: 12px;
  color: #999999;
  font-family: PingFangSC-Regular;
  margin-top: 4px;
  font-weight: bolder;
}

/*数字样式-小*/
.money-small-font {
  font-family: Oswald-Regular;
  font-size: 20px;
  color: #222222;
  font-weight: bolder;
  padding-top: 30px;
}

/*数字样式-大*/
.money-big-font {
  font-family: Oswald-Regular;
  font-size: 40px;
  color: #222222;
  font-weight: bolder;
  padding-top: 20px;
}

/*中间分割线*/
.middle-line {
  float: left;
  width: 1px;
  height: 60px;
  left: 50%;
  margin-top: 23px;
  border-right: #ff5d3b 1px dashed;
}

.description-content {
  width: 100%;
  margin: 0 auto;
  height: 100px;
}

.description-content-other {
  width: 8.2rem;
  margin: 0 auto;
}

.left-content {
  float: left;
  width: 49.5%;
}

.right-content {
  float: right;
  width: 49.5%;
}

.middle-content,
.third-content {
  float: left;
  width: 34%;
}

.first-content {
  float: left;
  margin-top: 32px;
  width: 31%;
}

.statistics-content-chart {
  width: 100%;
  height: 450px;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}
.statistics-content-chart1 {
  width: 100%;
  height: 330px;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*右上角选择时间左右箭头*/
.left-arrow,
.right-arrow {
  padding-bottom: 2px;
}
</style>

<template>
    <div class="fundModule">
		<!--选择月份-->
		<div class="part-inputpart" style="margin-top: 10px 18px 10px 18px">
			<div id="expectedDatePanel" class="part-inputpart-row" >
				<span :class=" 0==date.trim().length ? 'content-none' : 'content-have' "></span>
				<span class="part-inputpart-row-enablenon part-inputpart-row-header">日期</span>
				<span class="content-divide">|</span>
				<span class="part-inputpart-row-graytext" :class="0==date ? '': 'part-inputpart-row-redtext' " @click="clickDateShow">{{date}}</span>
                <span style="margin:0 15px;">~</span>
                <span class="part-inputpart-row-graytext" :class="0==twoDate ? '': 'part-inputpart-row-redtext' " @click="clickEndDateShow">{{twoDate}}</span>
				<img class="part-inputpart-row-right part-inputpart-row-right-timepick">
			</div>
			<div>
				<van-popup v-model="totalDateShow" position="bottom" class="selectTime">
					<div>
						<van-datetime-picker v-model="currentDate" type="date" title="选择年月日" item-height="35px"
						@confirm="dateConfirm" @cancel="dateCancel" />
					</div>
				</van-popup>
			</div>
		</div>

		<div class="part-inputpart" style="margin-top: 10px">
			<div>
				<van-popup v-model="totalEndDateShow" position="bottom" class="selectTime">
					<div>
						<van-datetime-picker v-model="endDate" type="date" title="选择年月日" item-height="35px"
						@confirm="dateConfirm" @cancel="dateCancel" />
					</div>
				</van-popup>
			</div>
		</div>

		<div  style="color:#ff5d3b;width:90%;margin:10px auto 0px auto">区间收款（实时交易日期）</div>
        <div style="width:90%;margin:10px auto 0 auto;background:#ffffff;border-radius: 8px;">
        	<van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="getCollectionList">
            	<div class="title">
                	<van-row justify="center">
                    	<van-col span="8">收款类型</van-col>
                    	<!--<van-col span="9">金额（收）</van-col>-->
                    	<van-col span="16">金额（收）</van-col>
                	</van-row>
            	</div>
            	<div class="content"  v-for="(item,index) in collectionList" :key="index">
                	<van-row style="position:relative">
                   		<van-col span="8" class="billFee">{{item.expenseType}}</van-col>

                    	<van-col span="16">{{item.actualAmount}} 元</van-col>
                	</van-row>
            	</div>
            	<div class="totalCount">
                	<van-row>
                	</van-row>
                	<van-row>
                    	<van-col span="8" class="billFee">总计:</van-col>
                    	<van-col span="16">{{collocationSum}} 元</van-col>
                	</van-row>
            	</div>
        	</van-list>
        </div>
        <div  style="color:#ff5d3b;width:90%;margin:10px auto 0px auto">区间付款（实时交易日期）</div>
        <div style="width:90%;margin:10px auto 0 auto;background:#ffffff;border-radius: 8px;">
        	<van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="getPayList">
            	<div class="title">
                	<van-row justify="center">
                    	<van-col span="8">支付类型</van-col>
                    	<!--<van-col span="9">金额（收）</van-col>-->
                    	<van-col span="16">金额（支）</van-col>
                	</van-row>
            	</div>
            	<div class="content"  v-for="item in payList" :key="item.billFeeType_id">
                	<van-row style="position:relative">
                   		<van-col span="8" class="billFee">{{item.houseTypeSonStr}}</van-col>
                    	<!--<van-col span="9">{{item.collectSumAmount}} 元</van-col>-->
                    	<van-col span="16">{{item.branchSumAmount}} 元</van-col>
                	</van-row>
            	</div>
            	<div class="totalCount">
                	<van-row>
                    	<!--<van-col span="8" class="billFee">合计:</van-col>
                    	<van-col span="9">{{paySum.payeeAmount}} 元</van-col>
                    	<van-col span="9">{{paySum.payerAmount}} 元</van-col>-->
                	</van-row>
                	<van-row>
                    	<van-col span="8" class="billFee">总计:</van-col>
                    	<van-col span="16">{{paySum.difference}} 元</van-col>
                	</van-row>
            	</div>
        	</van-list>
        </div>
		<div  style="color:#ff5d3b;width:90%;margin:10px auto 10px auto">区间收付款（财务对账日期）</div>
        <div style="width:90%;margin:0 auto;background:#ffffff;border-radius: 8px;">
        	<van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="getFundList">
            	<div class="title">
                	<van-row justify="center">
                    	<van-col span="8">费用类型</van-col>
                    	<van-col span="8">金额（收）</van-col>
                    	<van-col span="8">金额（支）</van-col>
                	</van-row>
            	</div>
            	<div class="content"  v-for="item in fundList" :key="item.billFeeType_id">
                	<van-row style="position:relative">
                   		<van-col span="8" class="billFee">{{item.billFeeTypeStr}}</van-col>
                    	<van-col span="8">{{item.collectSumAmount}} 元</van-col>
                    	<van-col span="8">{{item.branchSumAmount}} 元</van-col>
                	</van-row>
            	</div>
            	<div class="totalCount">
                	<van-row>
                    	<van-col span="8" class="billFee">合计:</van-col>
                    	<van-col span="8">{{sum.payeeAmount}} 元</van-col>
                    	<van-col span="8">{{sum.payerAmount}} 元</van-col>
                	</van-row>
                	<van-row>
                    	<van-col span="8" class="billFee">总计:</van-col>
                    	<van-col span="16">{{sum.difference}} 元</van-col>
                	</van-row>
            	</div>
        	</van-list>
        </div>


		<!--选择月份-->
		<div class="part-inputpart" style="margin-top: 20px">
			<div id="expectedDatePanel" class="part-inputpart-row" >
				<span :class=" 0==date.trim().length ? 'content-none' : 'content-have' "></span>
				<span class="part-inputpart-row-enablenon part-inputpart-row-header">日期</span>
				<span class="content-divide">|</span>
				<span class="part-inputpart-row-graytext" :class="0==nowDate ? '': 'part-inputpart-row-redtext' " @click="clickNowDateShow">{{threeDate}}</span>
				<img class="part-inputpart-row-right part-inputpart-row-right-timepick">
			</div>
			<div>
				<van-popup v-model="selectNowDateShow" position="bottom" class="selectTime">
					<div>
						<van-datetime-picker v-model="nowDate" type="date" title="选择年月日" item-height="35px"
						@confirm="nowDateConfirm" @cancel="nowDateCancel" />
					</div>
				</van-popup>
			</div>
		</div>

		<div  style="color:#ff5d3b;width:90%;margin:0 auto 10px auto">账户余额（财务对账日期）</div>
        <div style="width:90%;margin:0 auto;background:#ffffff;border-radius: 8px;">
        	<van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="getBalanceList">
            	<div class="title">
                	<van-row justify="center">
                    	<van-col span="10">账户</van-col>
                    	<van-col span="7">上期余额</van-col>
                    	<van-col span="7">本期余额</van-col>
                	</van-row>
            	</div>
            	<div class="content"  v-for="item in balanceList" :key="item.reconciliation_id">
                	<van-row style="position:relative">
                   		<van-col span="10" class="billFee">{{item.accountName}}</van-col>
                    	<van-col span="7">{{item.balanceOfLastPeriod}} </van-col>
                    	<van-col span="7">{{item.currentBalance}} </van-col>
                	</van-row>
            	</div>
            	<div class="totalCount">
                	<van-row>
                    	<van-col span="10" class="billFee">合计:</van-col>
                    	<van-col span="7">{{balanceSum.payerAmount}} 元</van-col>
                    	<van-col span="7">{{balanceSum.payeeAmount}} 元</van-col>
                	</van-row>
                	<!--<van-row>
                    	<van-col span="8" class="billFee">总计:</van-col>
                    	<van-col span="16">{{balanceSum.difference}} 元</van-col>
                	</van-row> -->
            	</div>
        	</van-list>
        </div>

      <div  style="color:#ff5d3b;width:90%;margin:10px auto">外部余额（财务对账日期）</div>
      <div style="width:90%;margin:0 auto;background:#ffffff;border-radius: 8px;">
        <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="getExBalanceList">
          <div class="title">
            <van-row justify="center">
              <van-col span="10">账户</van-col>
              <van-col span="7">上期余额</van-col>
              <van-col span="7">本期余额</van-col>
            </van-row>
          </div>
          <div class="content"  v-for="item in exBalanceList" :key="item.reconciliation_id">
            <van-row style="position:relative">
              <van-col span="10" class="billFee">{{item.accountName}}</van-col>
              <van-col span="7">{{item.balanceOfLastPeriod}} </van-col>
              <van-col span="7">{{item.currentBalance}} </van-col>
            </van-row>
          </div>
          <div class="totalCount">
            <van-row>
              <van-col span="10" class="billFee">合计:</van-col>
              <van-col span="7">{{exBalanceSum.payerAmount}} 元</van-col>
              <van-col span="7">{{exBalanceSum.payeeAmount}} 元</van-col>
            </van-row>
            <!--<van-row>
                <van-col span="8" class="billFee">总计:</van-col>
                <van-col span="16">{{balanceSum.difference}} 元</van-col>
            </van-row> -->
          </div>
        </van-list>
      </div>


    </div>
</template>
<script>
    import {
        DatetimePicker,
        Popup,
        Sticky,
        Picker,
        Tab,
        Tabs,
        TreeSelect,
        Col,
        Row,
        Button,
        List,
        Cell,
    } from 'vant'
    import {
        queryBillSumAmount,queryPayBillSumAmount,findBankBalance,queryPayeeBillSumAmount
    } from '../../getData/getData'
    import { responseUtil, addMonth, getStaffId } from '../../libs/rongxunUtil'
    import util from '../contract/util2'

    var echarts = require('echarts')
    export default {
        name: 'businessModule',
        components: {
            [Sticky.name]: Sticky,
            [Tab.name]: Tab,
            [Picker.name]: Picker,
            [Col.name]: Col,
            [Row.name]: Row,
            [Tabs.name]: Tabs,
            [Popup.name]: Popup,
            [Button.name]: Button,
            [TreeSelect.name]: TreeSelect,
            [DatetimePicker.name]: DatetimePicker,
            [List.name]: List,
            [Cell .name]:Cell,
        },
        data() {
            return {
                date:'',
                twoDate:'',
				threeDate:'',
                currentDate:new Date(),
                loading:false,
                finished:false,
                fundList:[],    //资金
                sum:{},
                totalDateShow:false,
                totalEndDateShow:false,
                endDate:new Date(),
				payList:[],    //支付
				paySum:{},
				selectNowDateShow:false,
				nowDate:new Date(),
				balanceList:[],
				balanceSum:{},
        exBalanceList:[],
        exBalanceSum:{},
				collectionList:[],  //收款
				collocationSum:{},
            }
        },
        mounted() {
        },
        methods: {
            getFundList(){  //区间收付款list
                let that=this
                let param={}
                param.currentPage=1
                param.numberPage='100'
                param.day=this.changeDate(this.currentDate)
                param.twoday=this.changeEndDate(this.endDate)
                param.user_id=getStaffId()
                queryBillSumAmount(param).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.fundList=response.data.data.data ?response.data.data.data :[]
                        that.sum=response.data.data.sum?response.data.data.sum:{}
                        that.loading=true
                        that.finished=true
						for(let i in that.fundList){
                            that.fundList[i].branchSumAmount=(that.fundList[i].branchSumAmount).toFixed(2)
							that.fundList[i].branchSumAmount=that.fundList[i].branchSumAmount.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							that.fundList[i].collectSumAmount=(that.fundList[i].collectSumAmount).toFixed(2)
							that.fundList[i].collectSumAmount=that.fundList[i].collectSumAmount.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
						}
					    if(that.sum.difference){
                            that.sum.difference=(that.sum.difference.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							that.sum.payeeAmount=(that.sum.payeeAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							that.sum.payerAmount=(that.sum.payerAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
					    }
						that.$forceUpdate()
                    })
                })
            },
			getPayList(){   //获取付款list
                let that=this
                let param={}
                param.currentPage=1
                param.numberPage='100'
                param.day=this.changeDate(this.currentDate)
                param.twoday=this.changeEndDate(this.endDate)
                param.user_id=getStaffId()
				if(!param.user_id){
					return
				}
                queryPayBillSumAmount(param).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.payList=response.data.data.data ?response.data.data.data :[]
                        that.paySum=response.data.data.sum?response.data.data.sum:{}
						for(let i in that.payList){
                            that.payList[i].branchSumAmount=(that.payList[i].branchSumAmount).toFixed(2)
							that.payList[i].branchSumAmount=that.payList[i].branchSumAmount.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							//that.payList[i].collectSumAmount=(that.payList[i].collectSumAmount).toFixed(2)
							//that.payList[i].collectSumAmount=that.payList[i].collectSumAmount.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
						}
					    if(that.paySum.difference){
                            that.paySum.difference=(that.paySum.difference.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							//that.paySum.payeeAmount=(that.paySum.payeeAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							that.paySum.payerAmount=(that.paySum.payerAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
					    }
						that.$forceUpdate()
                    })
                })
			},
			getCollectionList(){   //获取收款list
				let that = this
				let param = {}
				param.day=this.changeDate(this.currentDate)
                param.twoday=this.changeEndDate(this.endDate)
				queryPayeeBillSumAmount(param).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.collectionList = response.data.data.data?response.data.data.data:[]
						that.collocationSum = response.data.data.payeeAmount?(Number(response.data.data.payeeAmount).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,'):{}
						that.collocationSum = (Number(response.data.data.payeeAmount).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
						for(let i in that.collectionList){
							that.collectionList[i].actualAmount = (that.collectionList[i].actualAmount).toFixed(2)
							that.collectionList[i].actualAmount = (that.collectionList[i].actualAmount).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
						}
						that.$forceUpdate()
                    })
                })
			},
			getBalanceList(){  //账户余额

                let that=this
                let param={}
                param.currentPage=1
                param.numberPage='100'
                param.date=this.changeNowDate(this.nowDate)
                param.dictionaryValue=1
                findBankBalance(param).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.balanceList=response.data.data.data ?response.data.data.data :[]
                        that.balanceSum=response.data.data.sum?response.data.data.sum:{}

						for(let i in that.balanceList){
                            that.balanceList[i].balanceOfLastPeriod=(that.balanceList[i].balanceOfLastPeriod).toFixed(2)
							that.balanceList[i].balanceOfLastPeriod=that.balanceList[i].balanceOfLastPeriod.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							that.balanceList[i].currentBalance=(that.balanceList[i].currentBalance).toFixed(2)
							that.balanceList[i].currentBalance=that.balanceList[i].currentBalance.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
						}
					    if(that.balanceSum.difference){
                            that.balanceSum.difference=(that.balanceSum.difference.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							that.balanceSum.payeeAmount=(that.balanceSum.payeeAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
							that.balanceSum.payerAmount=(that.balanceSum.payerAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
					    }
						that.$forceUpdate()
                    })
                })
			},
          getExBalanceList(){  //账户余额

            let that=this
            let param={}
            param.currentPage=1
            param.numberPage='100'
            param.date=this.changeNowDate(this.nowDate)
            param.dictionaryValue=2
            findBankBalance(param).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                that.exBalanceList=response.data.data.data ?response.data.data.data :[]
                that.exBalanceSum=response.data.data.sum?response.data.data.sum:{}

                for(let i in that.exBalanceList){
                  that.exBalanceList[i].balanceOfLastPeriod=(that.exBalanceList[i].balanceOfLastPeriod).toFixed(2)
                  that.exBalanceList[i].balanceOfLastPeriod=that.exBalanceList[i].balanceOfLastPeriod.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
                  that.exBalanceList[i].currentBalance=(that.exBalanceList[i].currentBalance).toFixed(2)
                  that.exBalanceList[i].currentBalance=that.exBalanceList[i].currentBalance.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
                }
                if(that.exBalanceSum.difference){
                  that.exBalanceSum.difference=(that.exBalanceSum.difference.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
                  that.exBalanceSum.payeeAmount=(that.exBalanceSum.payeeAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
                  that.exBalanceSum.payerAmount=(that.exBalanceSum.payerAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
                }
                that.$forceUpdate()
              })
            })
          },

            changeDate(currentDate){
                let year=new Date(currentDate).getFullYear()
                let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
                let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
                this.date=year+'-'+month+'-'+day
                return  this.date
            },
            changeEndDate(currentDate){
                let year=new Date(currentDate).getFullYear()
                let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
                let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
                this.twoDate=year+'-'+month+'-'+day
                return  this.twoDate
            },
            changeNowDate(currentDate){
                let year=new Date(currentDate).getFullYear()
                let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
                let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
                this.threeDate=year+'-'+month+'-'+day
                return  this.threeDate
            },
            clickDateShow(){
                this.totalDateShow=!this.totalDateShow
            },
            clickEndDateShow(){
                this.totalEndDateShow=!this.totalEndDateShow
            },
			clickNowDateShow(){
                this.selectNowDateShow=!this.selectNowDateShow
			},
            dateConfirm(){
                this.totalDateShow=false
                this.totalEndDateShow=false
                this.getFundList()
				this.getPayList()
				this.getCollectionList()
            },
            dateCancel(){
                this.totalDateShow=false
                this.totalEndDateShow=false
            },
			nowDateConfirm(){
				this.selectNowDateShow=false
                this.getBalanceList()
              this.getExBalanceList()
			},
			nowDateCancel(){
                this.selectNowDateShow=false
			}
        },
    }
</script>
<style scoped>
    .fundModule{
        font-size:12px;
        font-weight:bold
    }
    .van-col{
        padding:8px 10px;
        border:0.5px solid #fafafa;
        text-align: right;
    }
    .title .van-col{
        padding:8px 10px;
        border:0.5px solid #fafafa;
        text-align: center;
    }
    .content .billFee,.totalCount .billFee{
        padding:8px 10px;
        border:0.5px solid #fafafa;
        text-align: center;
    }
	/*选择月份的CSS*/
	.part {
		margin: 25px;
	}

	.part-subtitle {
		color: #ff5d3b;
		font-size: 12px;
		font-weight: 600;
	}

	.part-inputpart {
		display: inline-block;
		flex-direction: column;
		border-radius: 8px;
		white-space: nowrap;
		overflow: hidden;
		font-size: 12px;
	}

	.part-inputpart-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 40px;
		background-color: #ffffff;
		margin-top: 2px;
		width: 342px;
		height: 50px;
		border-radius: 8px;
		margin-left: 15px;
		margin-bottom: 20px;
	}

	.part-inputpart-row-header {
		font-weight: 800;
	}

	.part-inputpart-row input {
		border: 0;
		width: 100%;
	}

	.part-inputpart-dropDownList {
		height: 23%;
		/*垂直方向滚动*/
		/*overflow-y: scroll;*/
		overflow: auto;
		width: 92.5%;
		position: absolute;
		margin-left: 20px;
		height: 185px;
		width: 335px;
		z-index: 99;
	}

	.part-inputpart-row-enablenon {
		/*margin-left: 26px;*/
	}

	.part-inputpart-row-graytext {
		color: #d8d8d8;
		width: 22%;
	}


	.part-inputpart-row-redtext {
		width: 22%;
		color: #ff5d3b;
		font-weight: 600;
        margin:0 auto;
	}

	.part-inputpart-row-right {
		float: right;
		margin-right: 10px;
	}


	.part-inputpart-row-right-timepick {
		width: 20px;
	}

	.content-have {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #ff5d3b;
	}

	.content-none {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #c7c7c7;
	}

	.content-divide {
		font-size: 12px;
		color: #efefef;
		margin: 0 10px;
	}

	.content-have {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #ff5d3b;
	}

	.content-none {
		padding: 3px;
		margin: 0 10px;
		border-radius: 50%;
		background-color: #c7c7c7;
		margin-top: 1px;


	}

	/*上升图片的CSS */
	.big {
		width: 335px;
		height: 213px;
		background-color: white;
		margin-left: 20px;
		margin-top: -16px;
		border-radius: 8px;
		position: relative;
	}

	.up {
		width: 45px;
		height: 45px;
		position: absolute;
		left: 45%;
		top: 25.5%;
	}

	.money {
		font-size: 35px;
		font-weight: 600;
		position: absolute;
		left: 50%;
		top: 62%;
		color: #FF5E3B;
		transform: translate(-50%, -50%);
		width: 100%;
		text-align: center;
	}

	/*.money {*/
		/*width: 100%;*/
		/*height: auto;*/
		/*border: 1px solid red;*/
		/*!*line-height: 25px;*!*/
		/*font-size: 35px;*/
		/*font-weight: 600;*/
		/*color: #FF5E3B;*/
	/*}*/
	/*.money-flex{*/
		/*display: flex;*/
		/*align-items: center;*/
		/*justify-content: center;*/
		/*flex-direction: column;*/
	/*}*/
	.total {
		font-size: 13px;
		;
		position: absolute;
		left: 42%;
		top: 80%;
		color: #B6B6B6;

	}

	/*循环部分*/
	.green {
		margin: 0;
		width: 80px;
		height: 17px;
		font-size: 9px;
		text-align: center;
		line-height: 20px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 8px;
		background: linear-gradient(to right, #63cb96, #30d0ac);
		color: #EDFCF5;
	}

	.orange {
		margin: 0;
		width: 43px;
		height: 17px;
		font-size: 9px;
		text-align: center;
		line-height: 20px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 8px;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: #EDFCF5;
	}

	.red {
		margin: 0;
		width: 43px;
		height: 17px;
		font-size: 9px;
		text-align: center;
		line-height: 20px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 8px;
		background: linear-gradient(to right, #FC3F1C, #E22220);
		color: #EDFCF5;
	}

	.reviews {
		width: 100%;
		overflow: hidden;
		height: auto;
		position: relative;
		top: 15px;
		background-color: #fAfAfA;
	}

	.review {
		margin: 0 15px 15px 20px;
		height: auto;
		background-color: white;
		border-radius: 8px;
		overflow: hidden;
		width: 335px;
	}

	.top-title-orange {
		margin: 0;
		width: 45px;
		height: 18px;
		font-size: 10px;
		text-align: center;
		line-height: 20px;
		border-radius: 8px 0;
		background: linear-gradient(to right, #FFC274, #FF5D3B);
		color: white;
	}

	.top-title-green {
		margin: 0;
		width: 45px;
		height: 18px;
		font-size: 10px;
		text-align: center;
		line-height: 20px;
		border-radius: 8px 0;
		background: linear-gradient(to right, #63cb96, #30d0ac);
		color: white;
	}

	.top-title-red {
		margin: 0;
		width: 45px;
		height: 18px;
		font-size: 10px;
		text-align: center;
		line-height: 20px;
		border-radius: 8px 0;
		background: linear-gradient(to right, #FF431B, #E02020);
		color: white;
	}

	.position {
		margin: 5px 15px 15px 17px;
		/*width: 345px;*/
		height: auto;
		text-align: left;
		font-size: 15px;
		font-weight: bold;
		text-overflow: ellipsis;
		display: -webkit-box;
		line-height: 10px;
	}

	.position-span-left {
		line-height: 20px;
		color: #FE5C40;
	}

	.position-span-right {
		line-height: 23px;
		color: black;
	}


	.demand {
		width: 100%;
		height: auto;
		border-top: 1px solid #f4f4f4;
	}

	.demand-reason {
		margin-top: 5px;
		margin-left: 15px;
		color: #222222;
		font-weight: 800;
		font-size: 13px;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
    .types{
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
    }
    .type{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .small-icon{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        /*background-image: url("../../../assets/images/small-icon.png");*/
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .small-icon-rotate{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-bottom: 5px;
        /*background-image: url("../../../assets/images/small-icon.png");*/
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform:rotate(180deg);
    }
</style>

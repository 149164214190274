<template>
  <div class="basicPersonnelModule">
    <!--项目选择模块-->
    <div class="module-small" @click="showProject">
      <span class="project-name">{{
        departmentName != undefined && departmentId != ''
          ? departmentName
          : '部门检索'
      }}</span>
      <img class="drop-down-image" src="../../assets/images/drop-down.png" />
    </div>
    <!--拉下菜单-->
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <div style="background-color: #f7f8fa">
        <div class="choose-company-text">选择公司</div>
        <van-row justify="center" style="background-color: #f7f8fa">
          <div v-for="(item, index) in companyList" :key="index">
            <van-col span="8" offset="4" class="company-col-style"
              ><div
                :class="
                  checkedIndex == index
                    ? 'company-col-checked-style'
                    : 'company-text-style'
                "
                @click="selectCompany(item, index)"
              >
                {{ item.departmentName ? item.departmentName : '部门检索' }}
              </div></van-col
            >
          </div>
        </van-row>
      </div>
      <van-tree-select
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        :items="items"
        @click-nav="selectArea"
        @click-item="selectDepartment"
      />
      <div class="search">
        <van-button
          style="width: 2rem; color: orangered;border-color: orangered"
          plain
          type="primary"
          @click="resetDepartmentInfo"
          >清空</van-button
        >
        <van-button
          @click="searchDepartmentInfo"
          style="width: 2.8rem;margin-left: 4rem"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="normal"
        >
          筛选
        </van-button>
      </div>
    </van-popup>
    <div class="basicPersonnelModuleCardTitle">在职情况</div>
    <div class="basicPersonnelModuleCardBody">
      <div class="basicPersonnelModuleCardBody_Style" id="inJob"></div>
    </div>

    <div class="basicPersonnelModuleCardTitle">中后台占比</div>
    <div class="basicPersonnelModuleCardBody1">
      <!-- <div class="basicPersonnelModuleCardBody_backstageStyle" id="backstage"></div> -->

      <!-- <div class="zhonghou" >中后台占比   {{zhong}}:{{hou}}</div> -->
      <div class="statistics-content-figure">
        <div class="description-content1">
          <div class="left-content1">
            <div class="money-small-font1">中后台占比</div>
          </div>

          <div class="right-content1">
            <div class="money-small-font1">{{ zhong }} : {{ hou }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="basicPersonnelModuleCardTitle">入职渠道分析
            <div class="leftCheckTime"></div>
            <span class="checkTimetitle" @click="checkInDate(2)">{{entryChannelsEndTime | formatYearMonth}}</span>
            <span class="checkTimetitle" style="margin-left: 5px;margin-right: 5px">至</span>
            <span class="checkTimetitle" @click="checkInDate(1)">{{entryChannelsBeginTime | formatYearMonth}}</span>
            <div class="rightCheckTime"></div>
        </div> -->

    <div class="basicPersonnelModuleCardTitle1">
      <span class="basicPersonnelModuleCardTitle">
        入职渠道分析
      </span>
      <div class="right-time">
        <img
          @click="leftArrow(1)"
          class="left-arrow"
          src="../../assets/images/left-arrow.png"
        />
        <span class="max-date" @click="checkInDate(1)">{{
          entryChannelsBeginTime | formatYearMonth
        }}</span>
        <span class="middle-character">至</span>
        <span class="min-date" @click="checkInDate(2)">{{
          entryChannelsEndTime | formatYearMonth
        }}</span>
        <img
          @click="leftArrow(2)"
          class="right-arrow"
          src="../../assets/images/right-arrow.png"
        />
      </div>
    </div>

    <div class="basicPersonnelModuleCardBody">
      <div
        class="basicPersonnelModuleCardBody_backstageStyle1"
        id="entryChannels"
      ></div>
      <div v-for="(item, index) in recruitWayList" :key="index">
        <div class="basicPersonnelModuleCardBody_entryChannelsName">
          {{ item.name }}：{{ item.value }}人|{{ pathList[index] }}%
        </div>
        <!--                <div class="basicPersonnelModuleCardBody_entryChannelsName">{{item.name}}：{{item.value}}人 | 23%</div>-->
      </div>
      <!--            <div class="basicPersonnelModuleCardBody_entryChannelsName">渠道1：15621人 | 23%</div>-->
      <!--            <div class="basicPersonnelModuleCardBody_entryChannelsName">渠道1：15621人 | 23%</div>-->
      <!--            <div class="basicPersonnelModuleCardBody_entryChannelsName">渠道1：15621人 | 23%</div>-->
      <!--            <div class="basicPersonnelModuleCardBody_entryChannelsName">渠道1：15621人 | 23%</div>-->
      <!--            <div class="basicPersonnelModuleCardBody_entryChannelsName">渠道1：15621人 | 23%</div>-->
      <div style="clear: both"></div>
    </div>

    <!-- <div class="basicPersonnelModuleCardTitle">人员流动情况
            <div class="leftCheckTime"></div>

            <span class="checkTimetitle" @click="checkInDate(4)">{{personnelTurnoverEndTime | formatYearMonth}}</span>
            <span class="checkTimetitle" style="margin-left: 5px;margin-right: 5px">至</span>
            <span class="checkTimetitle" @click="checkInDate(3)">{{personnelTurnoverBeginTime | formatYearMonth}}</span>
            <div class="rightCheckTime"></div>
        </div> -->
    <div class="basicPersonnelModuleCardTitle1">
      <span class="basicPersonnelModuleCardTitle">
        人员流动情况
      </span>
      <div class="right-time">
        <img
          @click="leftArrow(3)"
          class="left-arrow"
          src="../../assets/images/left-arrow.png"
        />
        <span class="max-date" @click="checkInDate(3)">{{
          personnelTurnoverBeginTime | formatYearMonth
        }}</span>
        <span class="middle-character">至</span>
        <span class="min-date" @click="checkInDate(4)">{{
          personnelTurnoverEndTime | formatYearMonth
        }}</span>

        <img
          @click="leftArrow(4)"
          class="right-arrow"
          src="../../assets/images/right-arrow.png"
        />
      </div>
    </div>
    <!-- <div class="basicPersonnelModuleCardBody">
            <div class="basicPersonnelModuleCardBody_backstageStyle" id="personnelTurnover" style="height: 100px"></div>
        </div> -->

    <div class="statistics-content-figure">
      <div class="description-content1">
        <div class="left-content1">
          <div class="money-small-font">{{ ruzhi }}</div>
          <div class="description-text">入职量</div>
        </div>
        <div class="middle-line"></div>
        <div class="right-content1">
          <div class="money-small-font">{{ lizhi }}</div>
          <div class="description-text">离职量</div>
        </div>
      </div>
    </div>

    <div class="basicPersonnelModuleCardTitle">在职时间分析</div>
    <div class="basicPersonnelModuleCardBody">
      <div
        class="basicPersonnelModuleCardBody_backstageStyle"
        style="height: 6rem"
        id="workingHours"
      ></div>
    </div>

    <div class="basicPersonnelModuleCardTitle">员工年龄分析</div>
    <div class="basicPersonnelModuleCardBody">
      <div class="basicPersonnelModuleCardBodyAge_Style" id="staffAge"></div>
    </div>

    <div class="basicPersonnelModuleCardTitle">性别占比</div>
    <div class="basicPersonnelModuleCardBody">
      <div class="basicPersonnelModuleCardBody_Gender">
        <div class="basicPersonnelModuleCardBody_Gender_imgTitle">
          <van-image
            class="basicPersonnelModuleCardBody_Gender_imgStyle"
            fit="contain"
            :src="require('../../assets/images/nansheng.png')"
          ></van-image>
        </div>
        <div class="gender_Name">男</div>
        <div class="gender_Num">{{ staffMaleNum }}</div>
        <div class="gender_percentage">
          {{ Number(staffMalePercent).toFixed(2) }}%
        </div>
      </div>
      <div class="basicPersonnelModuleCardBody_Gender">
        <div class="basicPersonnelModuleCardBody_Gender_imgTitle">
          <van-image
            class="basicPersonnelModuleCardBody_Gender_imgStyle"
            fit="contain"
            :src="require('../../assets/images/nvsheng.png')"
          ></van-image>
        </div>
        <div class="gender_Name">女</div>
        <div class="gender_Num">{{ staffFemaleNum }}</div>
        <div class="gender_percentage">
          {{ Number(staffFemalePercent).toFixed(2) }}%
        </div>
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="basicPersonnelModuleCardTitle">学历占比</div>
    <div class="basicPersonnelModuleCardBody">
      <div
        class="basicPersonnelModuleCardBody_backstageStyle"
        style="height: 7.2rem"
        id="education"
      ></div>
    </div>

    <div class="basicPersonnelModuleCardTitle">离职人员入职时长占比</div>
    <div class="basicPersonnelModuleCardBody">
      <div
        class="basicPersonnelModuleCardBody_backstageStyle"
        style="height: 6rem"
        id="staffLeaving"
      ></div>
    </div>

    <!--时间选择器-->
    <van-popup v-model="isCheckInDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        item-height="35px"
        @confirm="checkInDateConfirm"
        @cancel="isCheckInDateShow = false"
      />
    </van-popup>
  </div>
</template>
<script>
import {
  DatetimePicker,
  Popup,
  Sticky,
  Picker,
  Tab,
  Tabs,
  TreeSelect,
  Col,
  Row,
  Button,
  Image as VanImage,
} from 'vant'
import { globaluserId, responseUtil, getStaffId } from '../../libs/rongxunUtil'
import {
  backstageData,
  educationData,
  entryChannelsData,
  inJobData,
  personnelTurnoverData,
  staffAgeData,
  staffLeavingData,
  staffSexData,
  workingHoursData,
  queryAuthLevelDepartment,
} from '../../getData/getData'
import util from '../contract/util2'
var echarts = require('echarts')
export default {
  name: 'basicPersonnelModule',
  components: {
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [VanImage.name]: VanImage,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
  },
  filters: {
    formatDateTime(value) {
      if (null == value || '' == value) return
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d
    },
    formatYearMonth(value) {
      if (null == value || '' == value) return
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM
    },
  },
  data() {
    return {
      checkedIndex: 0,
      departmentName: '',
      departmentId: '', //部门id
      functionId: '391',
      companyList: [],
      activeId: -1,
      activeIndex: 0,
      items: [
        {
          text: '浙江',
          children: [
            { text: '杭州', id: 1 },
            { text: '温州', id: 2 },
          ],
        },
        {
          text: '辽宁',
          children: [
            { text: '沈阳', id: 3 },
            { text: '大连', id: 4 },
          ],
        },
        {
          text: '江苏',
          children: [
            { text: '南京', id: 5 },
            { text: '无锡', id: 6 },
          ],
        },
      ],
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: '集寓项目名称',
      projectDataInfo: [],

      staffMaleNum: 0,
      staffMalePercent: 0,
      staffFemaleNum: 0,
      staffFemalePercent: 0,
      recruitWayList: [], // 入职渠道
      timeIndex: '',
      currentDate: new Date(),
      isCheckInDateShow: false,
      entryChannelsBeginTime: this.formatDate(new Date()),
      entryChannelsEndTime: this.formatDate(new Date()),
      personnelTurnoverBeginTime: this.formatDate(new Date()),
      personnelTurnoverEndTime: this.formatDate(new Date()),

      zhong: 1,
      hou: 1,
      ruzhi: 1,
      lizhi: 1,

      pathList: [],
      //新的列表接口所需数据
      departmentAuthData: {},
    }
  },
  watch: {
    entryChannelsBeginTime(newVal, oldVal) {
      let that = this
      if (newVal > this.entryChannelsEndTime) {
        responseUtil.alertErrMsg(that, '开始时间不能大于结束时间')
        this.entryChannelsBeginTime = oldVal
      }
    },
    entryChannelsEndTime(newVal, oldVal) {
      let that = this
      if (this.entryChannelsBeginTime > newVal) {
        responseUtil.alertErrMsg(that, '结束时间不能小于开始时间')
        this.entryChannelsEndTime = oldVal
      }
    },
    personnelTurnoverBeginTime(newVal, oldVal) {
      let that = this
      if (newVal > this.personnelTurnoverEndTime) {
        responseUtil.alertErrMsg(that, '开始时间不能大于结束时间')
        this.personnelTurnoverBeginTime = oldVal
      }
    },
    personnelTurnoverEndTime(newVal, oldVal) {
      let that = this
      if (this.personnelTurnoverBeginTime > newVal) {
        responseUtil.alertErrMsg(that, '结束时间不能小于开始时间')
        this.personnelTurnoverEndTime = oldVal
      }
    },
  },
  mounted() {
    //中后台占比
    //中后台数据
    this.backstageData()

    //公司初始化
    this.queryAuthLevelDepartment()
  },
  methods: {
    queryAuthLevelDepartment() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId() //员工id
      initData.function_id = that.functionId //方法模块id
      queryAuthLevelDepartment(initData).then(res =>
        responseUtil.dealResponse(that, res, () => {
          that.departmentAuthData = res.data.data
          let allList = that.departmentAuthData.list
          if (allList != null) {
            that.companyList = allList.filter(item => item.level == 1)
          }
          let departmentId = that.departmentAuthData.roleDepartmentId
          that.departmentId = departmentId
          let item = that.departmentAuthData.list.find(
            item => item.id == departmentId
          )
          that.departmentName = item ? item.departmentName || '' : ''
          that.initOtherDepartment()
          that.searchDepartmentInfo()
        })
      )
    },
    initOtherDepartment() {
      let that = this
      let allList = that.departmentAuthData.list
      let departmentId = that.departmentId
      let threeLevelDepartments = allList.filter(item => item.level == 3)
      let fiveLevelDepartments = allList.filter(item => item.level == 5)
      let levelDepartments = threeLevelDepartments
        .filter(item => {
          let levelTop = item.levelTop || ''
          return levelTop.indexOf(departmentId) != -1
        })
        .map(item => {
          let result = {}
          result.children = fiveLevelDepartments
            .filter(child => {
              let levelTop = child.levelTop || ''
              return levelTop.indexOf(',' + item.id) != -1
            })
            .map(child => {
              let childResult = {}
              childResult.text = child.departmentName
              childResult.id = child.id
              childResult.level = child.level
              return childResult
            })
          result.text = item.departmentName
          result.value = item.id
          result.level = item.level
          return result
        })
      that.items = levelDepartments // 公司列表
    },
    resetDepartmentInfo() {
      let departmentId = this.departmentAuthData.roleDepartmentId
      let item = this.departmentAuthData.list.find(
        item => item.id == departmentId
      )
      this.departmentName = item ? item.departmentName || '' : ''
      this.departmentId = departmentId
      this.isShowProjectMenu = false
      this.searchDepartmentInfo()
    },

    //选择公司，联动下方区、部
    selectCompany(item, index) {
      let that = this
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(that, '权限不足')
        return
      }
      this.checkedIndex = index
      this.departmentId = item.id
      this.departmentName = item.departmentName
      this.initOtherDepartment()
    },
    selectArea(value) {
      let item = this.items[value]
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = item.value
      this.departmentName = item.text
    },
    selectDepartment(data) {
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = data.id
      this.departmentName = data.text
    },
    searchDepartmentInfo() {
      //在职情况
      this.inJobData()
      this.inJob()

      //入职渠道分析
      this.entryChannelsData()

      //人员流动情况
      this.personnelTurnoverData()

      //在职时间
      this.workingHoursData()

      //员工年龄
      this.staffAgeData()
      //员工性别
      this.staffSexData()

      //学历占比
      this.educationData()

      //离职人员入职时长占比
      this.staffLeavingData()
      this.isShowProjectMenu = false
    },
    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true
    },
    //取消选择
    cancelChangeProject() {
      this.projectName = '集寓项目名称'
      this.projectId = ''
      this.isShowProjectMenu = false
    },

    //职务级别占比
    inJobData() {
      const that = this
      let initData = {}
      initData.user_id = globaluserId() //userid
      initData.depId = this.departmentId //departmentId
      inJobData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list
          let levelList = response.data.data.levelList

          if (list == '') {
            list = [{ name: '职务级别占比', value: 0 }]
          }
          if (levelList.length == 0) {
            levelList = ['职务级别占比']
          }
          that.inJob(list, levelList)
        })
      })
    },
    inJob(list, levelList) {
      let myChart = echarts.init(document.getElementById('inJob'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#c23531',
          '#2f4554',
          '#61a0a8',
          '#d48265',
          '#91c7ae',
          '#749f83',
          '#ca8622',
          '#bda29a',
          '#6e7074',
          '#546570',
          '#c4ccd3',
        ],
        title: {
          left: 130,
          top: 255,
          text: '职务级别占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'horizontal',
          left: 10,
          top: 285,
          align: 'left',
          itemWidth: 21,
          // data: ['空置', '转租', '申退', '预到期', '已到期'],
          data: levelList,
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
          selectedMode: false, //空置是否可以点击取消
        },

        series: [
          {
            name: '职务级别',
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['50%', '28%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '空置'},
            //     {value: 310, name: '转租'},
            //     {value: 234, name: '申退'},
            //     {value: 135, name: '预到期'},
            //     {value: 1548, name: '已到期'}
            // ]
            data: list,
          },
        ],
      })
    },
    //中后台
    backstageData() {
      const that = this
      let initData = {}
      initData.user_id = globaluserId() //userid
      backstageData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list

          //that.backstage(list);
          that.zhong = Number(list[0].value / list[1].value).toFixed(2)

          //that.hou = list[1].value
        })
      })
    },
    //中后台占比
    // backstage(list){
    //     let myChart = echarts.init(document.getElementById('backstage'));
    //     // 绘制图表
    //     myChart.setOption({
    //         color: ["#19d4ae", "#5ab1ef", "#fa6e86", "#ffb980", "#0067a6", "#c4b4e4", "#d87a80", "#9cbbff", "#d9d0c7", "#87a997", "#d49ea2", "#5b4947", "#7ba3a8"],
    //         title: {
    //             left: 190,
    //             top: 50,
    //             text: '中后台人员情况',
    //             textStyle: {
    //                 fontSize: 13
    //             }
    //         },
    //         tooltip: {
    //             trigger: 'item',
    //             formatter: '{a} <br/>{b} : {c} ({d}%)'
    //         },
    //         legend: {
    //             orient: 'vertical',
    //             left: 190,
    //             top: 72,
    //             data: ['运营', '职能'],
    //             textStyle: {
    //                 color: '#d8d8d8'
    //             },
    //             icon: 'circle'
    //         },
    //         series: [
    //             {
    //                 name: '中后台人员情况',
    //                 type: 'pie',
    //                 radius: '55%',
    //                 center: ['30%', '50%'],
    //                 label: {
    //                     show: false
    //                 },
    //                 // data: [
    //                 //     {value: 335, name: '已结'},
    //                 //     {value: 310, name: '未结'},
    //                 // ]
    //                 data : list
    //             }
    //         ]
    //     })
    // },
    //入职渠道分析
    entryChannelsData() {
      const that = this
      let num = 0
      let temp = 0
      let initData = {}
      initData.user_id = globaluserId() //userid
      initData.depId = this.departmentId //departmentId
      //initData.beginTime = formatYearMonth(that.entryChannelsBeginTime) //
      //initData.endTime = formatYearMonth(that.entryChannelsEndTime) //userid

      initData.beginTime = util.dateFormat(that.entryChannelsBeginTime)
      initData.endTime = util.getCurrentMonthLast(that.entryChannelsEndTime)

      entryChannelsData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.recruitWayList = response.data.data.list
          let pathList1 = []
          let pathList2 = []

          for (let i = 0; i < response.data.data.list.length; i++) {
            num += response.data.data.list[i].value
          }
          for (let j = 0; j < that.recruitWayList.length; j++) {
            temp = Number(that.recruitWayList[j].value) / num
            if (isNaN(temp)) {
              pathList2.push(0)
            } else {
              pathList2.push((temp * 100).toFixed(2))
            }

            pathList1.push(that.recruitWayList[j].name)
          }

          //that.entryChannels(pathList2,pathList1,that.recruitWayList)
          that.entryChannels1(pathList1, that.recruitWayList)
          that.pathList = pathList2
        })
      })
    },

    entryChannels1(pathList1, b) {
      let myChart = echarts.init(document.getElementById('entryChannels'))
      // 绘制图表
      myChart.setOption({
        legend: {
          show: true, //  各项备注是否显示
          top: 300,
          left: 55,
          icon: 'none',
          // data: ['非常满意', '满意', '一般', '不满意', '差评','渠道']
        },
        tooltip: {
          trigger: 'axis',
          // axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          //     type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          // }
        },

        xAxis: {
          type: 'category',
          data: pathList1,
          //data: ['非常满意', '满意', '一般', '不满意', '差评'],
          //X轴文字样式
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 8,
            interval: 0,
            rotate: -30,
          },
          axisLine: {
            show: false, //是否显示坐标轴轴线。
          },
          axisTick: {
            show: false, //是否显示坐标轴刻度。
          },
        },

        //Y轴样式
        yAxis: {
          position: 'left',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D8D8D8',
            },
          },
          //背景中的分割线样式
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F3F3F3',
            },
          },

          //splitNumber: 8,   //y轴数值间隔
          show: true, //是否显示Y轴
        },

        color: ['#5AB1EF'],

        barMaxWidth: 8,

        series: {
          //data: [120, 200, 150, 80, 70, 110, 130],
          data: b,
          type: 'bar',
          // itemStyle
          // 位置
          center: ['5%', '5%'],
          // 大小
          radius: ['0', '50%'],
        },
      })
    },

    //入职渠道分析
    // entryChannels(pathList2,pathList1,recruitWayList){
    //     let myChart = echarts.init(document.getElementById('entryChannels'));
    //     // 绘制图表
    //     myChart.setOption({
    //         color: ['#3398DB'],
    //         tooltip: {
    //             trigger: 'axis',
    //             axisPointer: {            // 坐标轴指示器，坐标轴触发有效
    //                 type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
    //             }
    //         },
    //         grid: {
    //             left: '1%',
    //             right: '8%',
    //             bottom: '20%',
    //             containLabel: true,
    //             show:true,
    //             borderWidth:1,
    //         },
    //         xAxis: [
    //             {
    //                 type: 'category',
    //                 // data: ['Boss', '58', '线上', '线下222', '内推'],
    //                 data: pathList1,
    //                 axisTick: {
    //                     alignWithLabel: true
    //                 },
    //             }
    //         ],
    //         yAxis: [
    //             {
    //                 type: 'value',
    //                 nameTextStyle:{
    //                     color: 'blue',
    //                 }
    //             }
    //         ],
    //         series: [
    //             {
    //                 //name: '直接访问',
    //                 type: 'bar',
    //                 barWidth: '25%',
    //                 //data: [10, 52, 200, 334, 390]
    //                 data: pathList2
    //             }
    //         ]
    //     })
    // },
    //人员流动情况
    personnelTurnoverData() {
      const that = this
      let num = 0
      let temp = 0
      let initData = {}
      initData.user_id = globaluserId() //userid
      //initData.beginTime = formatYearMonth(that.personnelTurnoverBeginTime) //
      //initData.endTime = formatYearMonth(that.personnelTurnoverEndTime) //userid

      initData.beginTime = util.dateFormat(that.personnelTurnoverBeginTime)
      initData.endTime = util.getCurrentMonthLast(that.personnelTurnoverEndTime)

      initData.depId = this.departmentId //departmentId
      personnelTurnoverData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          //let list = response.data.data.list;
          //that.personnelTurnover(list);
          that.ruzhi = response.data.data.ruzhi
          that.lizhi = response.data.data.lizhi
        })
      })
    },

    // personnelTurnover(list) {
    //     let myChart = echarts.init(document.getElementById('personnelTurnover'));
    //     // 绘制图表
    //     myChart.setOption({
    //         grid: {
    //             top: '20%',
    //             left: '10%',
    //             right: '25%',
    //             height: '80%',
    //             containLabel: true
    //         },
    //         xAxis: [
    //             {
    //                 show: false,
    //                 type: 'value'
    //             }
    //         ],
    //         yAxis: [
    //             {
    //                 type: 'category',
    //                 data: [
    //                     {
    //                         value: '入职量',
    //                         textStyle: {
    //                             fontSize: 15,
    //                             fontWeight: 900
    //                         }
    //                     },
    //                     {
    //                         value: '离职量',
    //                         textStyle: {
    //                             fontSize: 15,
    //                             fontWeight: 900
    //                         }
    //                     },
    //                 ],
    //                 axisLine: {
    //                     show: false
    //                 },
    //                 axisTick: {
    //                     show: false
    //                 }
    //             }
    //         ],
    //         series: [
    //             {
    //                 itemStyle: {
    //                     normal: {
    //                         // 定制显示（按顺序）
    //                         color: function(params) {
    //                             var colorList = ["#ffc652", "#5ab1ef"];
    //                             return colorList[params.dataIndex]
    //                         }
    //                     },
    //                 },
    //                 label: {
    //                     show: true,
    //                     position: [170,2],
    //                     color: 'black',
    //                     fontWeight: 900,
    //                     fontSize: 15
    //                 },
    //                 barCategoryGap: '50%',
    //                 type: 'bar',
    //                 // data: [41231.21,341241.21]
    //                 data: list
    //             }
    //         ]
    //     })
    // },
    //在职时间分析
    workingHoursData() {
      const that = this
      let num = 0
      let temp = 0
      let initData = {}
      initData.user_id = globaluserId() //userid
      initData.depId = this.departmentId //departmentId
      workingHoursData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list

          that.workingHours(list)
        })
      })
    },
    workingHours(data) {
      let myChart = echarts.init(document.getElementById('workingHours'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 170,
          top: 10,
          text: '在职时长',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'horizontal',
          left: 170,
          top: 40,
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < data.length; i++) {
              totalNum += Number(data[i].value)
            }
            var res = ''
            for (var i = 0; i < data.length; i++) {
              if (data[i].name == name) {
                res = data[i].value
              }
            }
            if (0 == Number(res)) {
              return name + '  (0 / 0%)'
            }
            return (
              name +
              '  (' +
              res +
              ' / ' +
              Number((Number(res) / totalNum) * 100).toFixed(2) +
              '%)'
            )
          },
          width: 80,
          data: [
            '1个月以内',
            '1-3个月',
            '3-6个月',
            '半年',
            '一年',
            '三年',
            '五年以上',
          ],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        series: [
          {
            name: '在职时长',
            type: 'pie',
            radius: '70%',
            center: ['25%', '50%'],
            label: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '<1月'},
            //     {value: 335, name: '1个月'},
            //     {value: 335, name: '3个月'},
            //     {value: 335, name: '半年'},
            //     {value: 310, name: '一年'},
            //     {value: 310, name: '三年以上'},
            // ]
            data: data,
          },
        ],
      })
    },
    //员工性别
    staffAgeData() {
      const that = this
      let initData = {}
      initData.user_id = globaluserId() //userid
      initData.depId = this.departmentId //departmentId
      staffSexData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list

          if (list.length > 1) {
            if (list[0].name == '女') {
              that.staffFemaleNum = list[0].value
              that.staffFemalePercent =
                (Number(list[0].value) /
                  (Number(list[0].value) + Number(list[1].value))) *
                100
            }
            if (list[1].name == '男') {
              that.staffMaleNum = list[1].value
              that.staffMalePercent =
                (Number(list[1].value) /
                  (Number(list[0].value) + Number(list[1].value))) *
                100
            }
          }
          if (list == '') {
            that.staffMaleNum = 0
            that.staffFemaleNum = 0
            that.staffFemalePercent = 0
            that.staffMalePercent = 0
          }
        })
      })
    },
    staffAge(list) {
      let myChart = echarts.init(document.getElementById('staffAge'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#c23531',
          '#2f4554',
          '#61a0a8',
          '#d48265',
          '#91c7ae',
          '#749f83',
          '#ca8622',
          '#bda29a',
          '#6e7074',
          '#546570',
          '#c4ccd3',
        ],
        title: {
          left: 115,
          top: 250,
          text: '员工年龄占比情况',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'horizontal',
          left: 10,
          top: 285,
          align: 'left',
          itemWidth: 50,
          data: [
            '20岁以下',
            '20-25岁',
            '26-30岁',
            '31-35岁',
            '36-40岁',
            '40-50岁',
            '50岁以上',
          ],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
          selectedMode: false, //空置是否可以点击取消
        },

        series: [
          {
            name: '年龄',
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['50%', '30%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '空置'},
            //     {value: 310, name: '转租'},
            //     {value: 234, name: '申退'},
            //     {value: 135, name: '预到期'},
            //     {value: 1548, name: '已到期'}
            // ]
            data: list,
          },
        ],
      })
    },
    //员工年龄
    staffSexData() {
      const that = this
      let initData = {}
      initData.user_id = globaluserId() //userid
      initData.depId = this.departmentId //departmentId
      staffAgeData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list
          that.staffAge(list)
        })
      })
    },
    //学历占比
    educationData() {
      const that = this
      let initData = {}
      initData.user_id = globaluserId() //userid
      initData.depId = this.departmentId //departmentId
      educationData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list
          that.education(list)
        })
      })
    },
    education(data) {
      let myChart = echarts.init(document.getElementById('education'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 190,
          top: 10,
          text: '员工学历占比情况',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'horizontal',
          left: 190,
          top: 40,
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < data.length; i++) {
              totalNum += Number(data[i].value)
            }
            var res = ''
            for (var i = 0; i < data.length; i++) {
              if (data[i].name == name) {
                res = data[i].value
              }
            }
            if (0 == Number(res)) {
              return name + '  (0 / 0%)'
            }
            return (
              name +
              '  (' +
              res +
              ' / ' +
              Number((Number(res) / totalNum) * 100).toFixed(2) +
              '%)'
            )
          },
          width: 90,
          data: [
            '高中',
            '初中',
            '专科',
            '本科',
            '硕士',
            '博士',
            '中专',
            '其他',
            '未知',
          ],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        series: [
          {
            name: '学历占比',
            type: 'pie',
            radius: '60%',
            center: ['30%', '50%'],
            label: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '初中'},
            //     {value: 335, name: '高中'},
            //     {value: 335, name: '大专'},
            //     {value: 335, name: '本科'},
            //     {value: 310, name: '博士'},
            // ]
            data: data,
          },
        ],
      })
    },
    //离职人员入职时长占比
    staffLeavingData() {
      const that = this
      let initData = {}
      initData.user_id = globaluserId() //userid
      initData.depId = this.departmentId //departmentId
      staffLeavingData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list

          that.staffLeaving(list)
        })
      })
    },
    staffLeaving(data) {
      let myChart = echarts.init(document.getElementById('staffLeaving'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 170,
          top: 28,
          text: '离职人员入职时长占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'horizontal',
          left: 170,
          top: 58,
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < data.length; i++) {
              totalNum += Number(data[i].value)
            }
            var res = ''
            for (var i = 0; i < data.length; i++) {
              if (data[i].name == name) {
                res = data[i].value
              }
            }
            if (0 == Number(res)) {
              return name + '  (0 / 0%)'
            }
            return (
              name +
              '  (' +
              res +
              ' / ' +
              Number((Number(res) / totalNum) * 100).toFixed(2) +
              '%)'
            )
          },
          width: 80,
          //data: ['一个月以内', '1-3个月', '3-6个月', '半年', '一年', '三年以上'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        series: [
          {
            name: '数量',
            type: 'pie',
            radius: '70%',
            center: ['25%', '50%'],
            label: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '<1月'},
            //     {value: 335, name: '1个月'},
            //     {value: 335, name: '3个月'},
            //     {value: 335, name: '半年'},
            //     {value: 310, name: '一年'},
            //     {value: 310, name: '三年以上'},
            // ]
            data: data,
          },
        ],
      })
    },

    /*点击箭头，增加或减少一个月*/
    leftArrow(paramType) {
      if (paramType == 1) {
        // 最小时间  1
        let date = new Date(this.entryChannelsBeginTime)
        this.entryChannelsBeginTime = this.reduceDate(date)
        //入职渠道分析
        this.entryChannelsData()
      } else if (paramType == 2) {
        // 最大时间  2
        let date = new Date(this.entryChannelsEndTime)
        this.entryChannelsEndTime = this.addDate(date)
        //入职渠道分析
        this.entryChannelsData()
      } else if (paramType == 3) {
        //  最小时间  3
        let date = new Date(this.personnelTurnoverBeginTime)
        this.personnelTurnoverBeginTime = this.reduceDate(date)
        //人员流动情况
        this.personnelTurnoverData()
      } else if (paramType == 4) {
        //  最大时间  4
        let date = new Date(this.personnelTurnoverEndTime)
        this.personnelTurnoverEndTime = this.addDate(date)
        //人员流动情况
        this.personnelTurnoverData()
      }
    },
    /*时间格式转化方法*/
    formatDate(dateTime) {
      let year = `${dateTime.getFullYear()}`
      let month = `${dateTime.getMonth() + 1}`
      if (month < 10) {
        month = `0${dateTime.getMonth() + 1}`
      }
      return year + '-' + month
    },

    //使年月格式的时间减少一个月
    reduceDate(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month
      }
    },

    //使年月格式的时间增加一个月
    addDate(dateTime) {
      // debugger
      if (dateTime.getMonth() + 1 > 11) {
        let year = `${dateTime.getFullYear() + 1}`
        let month = `01`
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 + 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 + 1}`
        }
        return year + '-' + month
      }
    },

    //显示时间弹层
    checkInDate(i) {
      this.isCheckInDateShow = true
      this.timeIndex = i
    },
    //选择时间确认
    checkInDateConfirm() {
      switch (this.timeIndex) {
        case 1: //入职渠道分析 开始时间
          this.entryChannelsBeginTime = this.currentDate
          this.entryChannelsData()
          break
        case 2: //入职渠道分析 结束时间
          this.entryChannelsEndTime = this.currentDate
          this.entryChannelsData()
          break
        case 3: //人员流动情况 开始时间
          this.personnelTurnoverBeginTime = this.currentDate
          this.personnelTurnoverData()
          break
        case 4: //人员流动情况 结束时间
          this.personnelTurnoverEndTime = this.currentDate
          this.personnelTurnoverData()
          break
      }
      this.isCheckInDateShow = false
    },
  },
}
</script>
<style scoped>
/*项目选择模块*/
.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  padding: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式 未选中*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  /*background-color:#f7f8fa;*/
  border-radius: 0.1rem;
  font-size: 17px;
}
.basicPersonnelModuleCardTitle {
  color: #ff5d3b;
  font-size: 0.37333rem;
  text-align: left;
  width: 90%;
  height: 0.6rem;
  line-height: 0.6rem;
  margin-left: 5%;
  padding-top: 0.4rem;
  margin-bottom: 10px;
  z-index: 8888;
  font-weight: bolder;
}
.basicPersonnelModuleCardTitle1 {
  font-size: 0.37333rem;

  height: 0.6rem;
  line-height: 0.6rem;

  padding-top: 0.4rem;
  margin-bottom: 10px;
  z-index: 8888;
  font-weight: bolder;
}
.basicPersonnelModuleCardBody {
  border-radius: 8px;
  background-color: #ffffff;
  width: 90%;
  min-height: 3rem;
  margin-left: 5%;
  overflow: hidden;
}
.basicPersonnelModuleCardBody1 {
  border-radius: 8px;
  background-color: #ffffff;
  width: 90%;
  min-height: 2rem;
  margin-left: 5%;
  overflow: hidden;
  height: 50px;
}
.basicPersonnelModuleCardBody_Style {
  width: 345px;
  height: 500px;
}
.basicPersonnelModuleCardBodyAge_Style {
  margin-top: 10px;
  width: 345px;
  height: 360px;
}
.basicPersonnelModuleCardBody_backstageStyle {
  width: 345px;
  height: 170px;
}
.basicPersonnelModuleCardBody_backstageStyle1 {
  width: 345px;
  height: 260px;
}
.basicPersonnelModuleCardLine {
  border-bottom: #ff5d3b dashed 0.05333rem;
  width: 100%;
}
.leftCheckTime {
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
  float: right;
  margin-top: 6px;
  margin-left: 5px;
}
.rightCheckTime {
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  float: right;
  margin-top: 6px;
  margin-right: 5px;
}
.checkTimetitle {
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 0 1px;
  float: right;
}
.basicPersonnelModuleCardBody_entryChannelsName {
  width: 45%;
  margin-left: 3%;
  height: 1rem;
  line-height: 0.8rem;
  float: left;
  /* font-size: 0.32rem; */
  text-align: left;
  color: #222222;
  margin-top: -0.5rem;
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  font-weight: bolder;
}
.basicPersonnelModuleCardBody_Gender {
  width: 50%;
  height: 169px;
  float: left;
}
.basicPersonnelModuleCardBody_Gender_imgTitle {
  width: 100%;
  height: 50%;
}
.basicPersonnelModuleCardBody_Gender_imgStyle {
  width: 1.14187rem;
  height: 1.14213rem;
  margin-top: 15%;
  margin-left: 37%;
}
.gender_Name {
  width: 100%;
  height: 12%;
  font-size: 12px;
  text-align: center;
}
.gender_Num {
  width: 100%;
  height: 12%;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.gender_percentage {
  width: 100%;
  height: 12%;
  font-size: 12px;
  text-align: center;
}

.description-content1 {
  width: 85%;
  margin: 0 auto;
  height: 100px;
}
.left-content1 {
  float: left;
  width: 49.5%;
}
/*数字样式-小*/
.money-small-font {
  font-family: Oswald-Regular;
  font-size: 20px;
  color: #222222;
  font-weight: bolder;
  padding-top: 30px;
}
.money-small-font1 {
  font-family: Oswald-Regular;

  font-size: 0.37333rem;
  color: #222222;
  font-weight: bolder;
  padding-top: 22px;
}
/*所有数字下方描述性质字体*/
.description-text {
  font-size: 12px;
  color: #999999;
  font-family: PingFangSC-Regular;
  margin-top: 4px;
  font-weight: bolder;
}
/*中间分割线*/
.middle-line {
  float: left;
  width: 1px;
  height: 43px;
  left: 50%;
  margin-top: 33px;
  border-right: #ff5d3b 1px dashed;
}
.right-content1 {
  /*margin-left: 20px;*/
  float: right;
  width: 49.5%;
}
/*模块主题内容*/
.statistics-content-figure {
  width: 90%;

  border-radius: 8px;
  background-color: white;

  min-height: 3rem;
  margin-left: 5%;
  overflow: hidden;

  margin-top: 5px;
  text-align: center;
}

/*右上角选择时间左右箭头*/
.left-arrow,
.right-arrow {
  padding-bottom: 2px;
}
/*左上角标题*/
.left-title {
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  color: #ff5d3b;
  font-weight: bolder;
  margin: 10px 0px 5px 0px;
}
/*右上角统一div样式*/
.right-time {
  float: right;
  margin-right: 20px;
}
/*右上角选择时间*/
.min-date,
.max-date,
.middle-character {
  font-size: 14px;
  font-weight: 600;
  padding: 0 4px;
}
.zhonghou {
  margin-top: 23px;
  text-align: center;
  font-size: 20px;
}
</style>

<template>
  <div class="achievementModule">
    <!--项目选择模块-->
    <div class="module-small" @click="showProject">
      <span class="project-name">{{
        departmentName != undefined && departmentId != ''
          ? departmentName
          : '部门检索'
      }}</span>
      <img class="drop-down-image" src="../../assets/images/drop-down.png" />
    </div>
    <!--拉下菜单-->
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <div class="choose-company-text">选择公司</div>
      <van-row justify="center">
        <div v-for="(item, index) in companyList" :key="index">
          <van-col span="8" offset="4" class="company-col-style"
            ><div
              :class="
                checkedIndex == index
                  ? 'company-col-checked-style'
                  : 'company-text-style'
              "
              @click="selectCompany(item, index)"
            >
              {{ item.departmentName ? item.departmentName : '部门检索' }}
            </div></van-col
          >
        </div>
      </van-row>
      <van-tree-select
        style="margin-top: 20px"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        :items="items"
        @click-nav="selectArea"
        @click-item="selectDepartment"
      />
      <div class="search">
        <van-button
          style="width: 2rem; color: orangered;border-color: orangered"
          plain
          type="primary"
          @click="resetDepartmentInfo"
          >清空</van-button
        >
        <van-button
          @click="searchDepartmentInfo"
          style="width: 2.8rem;margin-left: 4rem"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="normal"
        >
          筛选
        </van-button>
      </div>
    </van-popup>

    <div>
      <span class="achievementModuleCardTitle">租后费用（元）</span>
      <div class="right-time">
        <img
          @click="leftArrow(1)"
          class="left-arrow"
          src="../../assets/images/left-arrow.png"
        />
        <span class="min-date" @click="chooseTime(1)">{{
          achievementMinData
        }}</span>
        <span class="middle-character">至</span>
        <span class="max-date" @click="chooseTime(2)">{{
          achievementMaxData
        }}</span>
        <img
          @click="leftArrow(2)"
          class="right-arrow"
          src="../../assets/images/right-arrow.png"
        />
      </div>
    </div>

    <div class="achievementModuleCardBody" style="height: 148px;">
      <div class="achievementModule_afterCost_left">
        <div class="achievementModule_afterCost_left_title">
          <span style="padding-left: 12%;">已结业绩</span>
        </div>
        <div class="achievementModule_afterCost_left_Num">
          <span style="padding-left: 4%;">{{ closedAchievement }}</span>
        </div>
      </div>
      <div class="achievementModule_afterCost_right">
        <div class="achievementModule_afterCost_right_title">
          <span style="padding-left: 6%;">预估业绩</span>
        </div>
        <div class="achievementModule_afterCost_right_Num">
          <span style="padding-left: 4%;">{{ achievement }}</span>
        </div>
      </div>
    </div>

    <div class="achievementModuleCardTitle">业绩类型统计</div>
    <div class="achievementModuleCardBody">
      <div
        class="achievementModuleCardBody_Style"
        id="achievementTypeRatio"
      ></div>
    </div>

    <div class="achievementModuleCardTitle">单量情况（笔）</div>
    <div class="achievementModuleCardBody">
      <div class="single_Style" id="single"></div>
    </div>

    <!--时间选择-->
    <van-popup v-model="isDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        item-height="35px"
        @confirm="dateConfirm"
        @cancel="isDateShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { responseUtil, getStaffId } from '@/libs/rongxunUtil'
import util from '../contract/util2'
import { fmoney } from '../../libs/rongxunMoneyUtil'
import {
  DatetimePicker,
  Popup,
  Sticky,
  Tab,
  Tabs,
  Picker,
  TreeSelect,
  Col,
  Row,
  Button,
} from 'vant'
import {
  estimateAchievement, //预估业绩、已结业绩
  achievementType, //业绩类型占比
  single, //单量情况
  queryAuthLevelDepartment,
} from '../../getData/getData'
var echarts = require('echarts')
export default {
  name: 'achievementModule',
  components: {
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
  },
  watch: {
    achievementMinData(newVal, oldVal) {
      if (this.achievementMaxData && this.achievementMaxData <= newVal) {
        responseUtil.alertMsg(this, '开始时间不能晚于结束时间')
        this.achievementMinData = oldVal
      }
    },
    achievementMaxData(newVal, oldVal) {
      if (this.achievementMinData && this.achievementMinData >= newVal) {
        responseUtil.alertMsg(this, '结束时间不能早于于开始时间')
        this.achievementMaxData = oldVal
      }
    },
    financeTimeBegin(newVal, oldVal) {
      if (this.financeTimeEnd && this.financeTimeEnd <= newVal) {
        responseUtil.alertMsg(this, '开始时间不能晚于结束时间')
        this.financeTimeBegin = oldVal
      }
    },
    financeTimeEnd(newVal, oldVal) {
      if (this.financeTimeBegin && this.financeTimeBegin >= newVal) {
        responseUtil.alertMsg(this, '结束时间不能早于于开始时间')
        this.financeTimeEnd = oldVal
      }
    },
    time5(newVal, oldVal) {
      if (this.time6 && this.time6 <= newVal) {
        responseUtil.alertMsg(this, '开始时间不能晚于结束时间')
        this.time5 = oldVal
      }
    },
    time6(newVal, oldVal) {
      if (this.time5 && this.time5 >= newVal) {
        responseUtil.alertMsg(this, '结束时间不能早于于开始时间')
        this.time6 = oldVal
      }
    },
    time9(newVal, oldVal) {
      if (this.time10 && this.time10 <= newVal) {
        responseUtil.alertMsg(this, '开始时间不能晚于结束时间')
        this.time9 = oldVal
      }
    },
    time10(newVal, oldVal) {
      if (this.time9 && this.time9 >= newVal) {
        responseUtil.alertMsg(this, '结束时间不能早于于开始时间')
        this.time10 = oldVal
      }
    },
  },
  data() {
    return {
      checkedIndex: 0,
      departmentName: '',
      departmentId: '', //部门id
      companyList: [],
      functionId: '1031',
      activeId: -1,
      activeIndex: 0,
      //新的列表接口所需数据
      departmentAuthData: {},
      items: [
        {
          text: '浙江',
          children: [
            { text: '杭州', id: 1 },
            { text: '温州', id: 2 },
          ],
        },
        {
          text: '辽宁',
          children: [
            { text: '沈阳', id: 3 },
            { text: '大连', id: 4 },
          ],
        },
        {
          text: '江苏',
          children: [
            { text: '南京', id: 5 },
            { text: '无锡', id: 6 },
          ],
        },
      ],
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: '集寓项目名称',
      projectDataInfo: [],

      achievement: {}, //预估
      closedAchievement: {}, //已结
      achievementMinData: this.formatDate(new Date()),
      achievementMaxData: this.formatDate(new Date()),

      currentDate: new Date(),
      chooseType: '', //选择类型 时长&总额 最小时间 or 验收情况  最小时间  等等
      isDateShow: false, //时间选择显示与否
    }
  },
  mounted() {
    this.getAchievementType() //业绩类型占比
    this.getSingle() //单笔情况
    //公司初始化
    this.queryAuthLevelDepartment()
  },
  methods: {
    queryAuthLevelDepartment() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId() //员工id
      initData.function_id = that.functionId //方法模块id
      queryAuthLevelDepartment(initData).then(res =>
        responseUtil.dealResponse(that, res, () => {
          that.departmentAuthData = res.data.data
          let allList = that.departmentAuthData.list
          if (allList != null) {
            that.companyList = allList.filter(item => item.level == 1)
          }
          let departmentId = that.departmentAuthData.roleDepartmentId
          that.departmentId = departmentId
          let item = that.departmentAuthData.list.find(
            item => item.id == departmentId
          )
          that.departmentName = item ? item.departmentName || '' : ''
          that.initOtherDepartment()
          that.searchDepartmentInfo()
        })
      )
    },
    initOtherDepartment() {
      let that = this
      let allList = that.departmentAuthData.list
      let departmentId = that.departmentId
      let threeLevelDepartments = allList.filter(item => item.level == 3)
      let fiveLevelDepartments = allList.filter(item => item.level == 5)
      let levelDepartments = threeLevelDepartments
        .filter(item => {
          let levelTop = item.levelTop || ''
          return levelTop.indexOf(departmentId) != -1
        })
        .map(item => {
          let result = {}
          result.children = fiveLevelDepartments
            .filter(child => {
              let levelTop = child.levelTop || ''
              return levelTop.indexOf(',' + item.id) != -1
            })
            .map(child => {
              let childResult = {}
              childResult.text = child.departmentName
              childResult.id = child.id
              childResult.level = child.level
              return childResult
            })
          result.text = item.departmentName
          result.value = item.id
          result.level = item.level
          return result
        })
      that.items = levelDepartments // 公司列表
    },
    resetDepartmentInfo() {
      let departmentId = this.departmentAuthData.roleDepartmentId
      let item = this.departmentAuthData.list.find(
        item => item.id == departmentId
      )
      this.departmentName = item ? item.departmentName || '' : ''
      this.departmentId = departmentId
      this.isShowProjectMenu = false
      this.searchDepartmentInfo()
    },

    //选择公司，联动下方区、部
    selectCompany(item, index) {
      let that = this
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(that, '权限不足')
        return
      }
      this.checkedIndex = index
      this.departmentId = item.id
      this.activeId = this.departmentId
      this.departmentName = item.departmentName
      this.initOtherDepartment()
    },
    selectArea(value) {
      let item = this.items[value]
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = item.value
      this.departmentName = item.text
      this.activeId = this.departmentId
    },
    selectDepartment(data) {
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = data.id
      this.departmentName = data.text
    },
    searchDepartmentInfo() {
      this.getEstimateAchievement()

      this.isShowProjectMenu = false
    },
    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true
    },
    //取消选择
    cancelChangeProject() {
      this.projectName = '集寓项目名称'
      this.projectId = ''
      this.isShowProjectMenu = false
    },

    //预估业绩、已结业绩
    getEstimateAchievement() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.achievementMinData)
      data.endTime = util.getCurrentMonthLast(that.achievementMaxData)

      estimateAchievement(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.achievement = fmoney(response.data.data.achievement)
          that.closedAchievement = fmoney(response.data.data.closedAchievement)
        })
      })
    },

    //业绩类型占比
    getAchievementType() {
      var that = this
      let data = {}
      //data.beginTime = util.dateFormat(that.achievementMinData)
      //data.endTime = util.dateFormat(that.achievementMaxData)
      achievementType(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.achievementTypeData = [
            {
              value: response.data.data.achievementTypeRatio[0].a,
              name: '首次业绩',
            },
            {
              value: response.data.data.achievementTypeRatio[0].b,
              name: '中退业绩',
            },
            {
              value: response.data.data.achievementTypeRatio[0].c,
              name: '二次业绩',
            },
            {
              value: response.data.data.achievementTypeRatio[0].d,
              name: '空置业绩',
            },
            {
              value: response.data.data.achievementTypeRatio[0].e,
              name: '租后扣减业绩',
            },
            {
              value: response.data.data.achievementTypeRatio[0].f,
              name: '集寓业绩',
            },
          ]
          that.achievementTypeRatio(that.achievementTypeData)
        })
      })
    },

    //单量情况
    getSingle() {
      var that = this
      let data = {}

      single(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.singleData = [
            { value: response.data.data.singleNum[0].num, name: '已结' },
            { value: response.data.data.un[0].un, name: '未结' },
          ]
          that.single(that.singleData)
        })
      })
    },
    //单量情况饼图
    single(data) {
      let myChart = echarts.init(document.getElementById('single'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 180,
          top: 20,
          text: '单量情况',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 180,
          top: 50,
          //data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },

        series: [
          {
            name: '单量情况',
            type: 'pie',
            radius: '60%',
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },

            data: data,
          },
        ],
      })
    },

    //业绩类型空心图
    achievementTypeRatio(list) {
      let myChart = echarts.init(
        document.getElementById('achievementTypeRatio')
      )
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 130,
          top: 200,
          text: '业绩类型占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'horizontal',
          left: 10,
          top: 230,
          align: 'left',
          itemWidth: 21,
          //data: ['空置', '转租', '申退', '预到期', '已到期'],
          //data: levelList,
          formatter: function(name) {
            var totalNum = 0
            for (var j = 0; j < list.length; j++) {
              totalNum += Number(list[j].value)
            }
            var res = ''
            for (var i = 0; i < list.length; i++) {
              if (list[i].name == name) {
                res = list[i].value
              }
            }
            if (totalNum == 0 || res == 0) {
              return name + '  (0 / 0%)'
            }
            // debugger
            return (
              name +
              '  (' +
              res +
              ' / ' +
              Number((res / totalNum) * 100).toFixed(2) +
              '%)'
            )
          },
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
          selectedMode: false, //空置是否可以点击取消
        },

        series: [
          {
            name: '业绩类型',
            type: 'pie',
            radius: ['40%', '50%'],
            center: ['50%', '35%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '空置'},
            //     {value: 310, name: '转租'},
            //     {value: 234, name: '申退'},
            //     {value: 135, name: '预到期'},
            //     {value: 1548, name: '已到期'}
            // ]
            data: list,
          },
        ],
      })
    },

    //选择时间
    chooseTime(value) {
      this.chooseType = value //时长&总额 最小时间  1
      this.isDateShow = true
    },

    /*时间格式转化方法*/
    formatDate(dateTime) {
      let year = `${dateTime.getFullYear()}`
      let month = `${dateTime.getMonth() + 1}`
      if (month < 10) {
        month = `0${dateTime.getMonth() + 1}`
      }
      return year + '-' + month
    },
    //使年月格式的时间减少一个月
    reduceDate(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month
      }
    },
    //使年月格式的时间增加一个月
    addDate(dateTime) {
      if (dateTime.getMonth() + 1 > 11) {
        let year = `${dateTime.getFullYear() + 1}`
        let month = `01`
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 + 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 + 1}`
        }
        return year + '-' + month
      }
    },
    /*点击箭头，增加或减少一个月*/
    leftArrow(paramType) {
      if (paramType == 1) {
        //时长&总额 最小时间  1
        let date = new Date(this.achievementMinData)
        this.achievementMinData = this.reduceDate(date)
        this.getEstimateAchievement()
      } else if (paramType == 2) {
        //时长&总额 最大时间  2
        let date = new Date(this.achievementMaxData)
        this.achievementMaxData = this.addDate(date)
        this.getEstimateAchievement()
      }
    },
    //确认时间
    dateConfirm(date) {
      this.currentDate = this.formatDate(date)
      if (this.chooseType == 1) {
        this.achievementMinData = this.currentDate //时长&总额 最小时间  1
        this.getEstimateAchievement()
      } else if (this.chooseType == 2) {
        this.achievementMaxData = this.currentDate //时长&总额 最大时间  2
        this.getEstimateAchievement()
      } else if (this.chooseType == 3) {
        this.everyComplaintMinDate = this.currentDate //各类投诉占比  最小时间  3
      } else if (this.chooseType == 4) {
        this.everyComplaintMaxDate = this.currentDate //各类投诉占比  最大时间  4
      } else if (this.chooseType == 5) {
        this.finalAccountHouseMaxDate = this.currentDate //投诉处理情况 最大时间  5
      } else if (this.chooseType == 6) {
        this.finalAccountHouseMaDate = this.currentDate //投诉处理情况 最大时间  5
      }
      this.isDateShow = false
      //this.getEstimateAchievement()
    },
  },
}
</script>

<style scoped>
/*项目选择模块*/
.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  margin: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  background-color: #f7f8fa;
  border-radius: 0.1rem;
  font-size: 17px;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}

.achievementModuleCardTitle {
  color: #ff5d3b;
  font-size: 0.37333rem;
  text-align: left;
  width: 90%;
  height: 0.6rem;
  line-height: 0.6rem;
  margin-left: 5%;
  padding-top: 0.4rem;
  margin-bottom: 10px;
  z-index: 8888;
  font-weight: bolder;
}
.achievementModuleCardBody {
  border-radius: 8px;
  background-color: #ffffff;
  width: 90%;
  min-height: 3rem;
  margin-left: 5%;
  overflow: hidden;
}
.achievementModule_afterCost_left {
  width: 60%;
  height: 100%;
  float: left;
}
.achievementModule_afterCost_right {
  width: 40%;
  height: 100%;
  float: left;
}
.achievementModule_afterCost_left_title {
  color: #999999;
  font-size: 0.32rem;
  width: 100%;
  height: 40%;
  line-height: 2.5rem;
}
.achievementModule_afterCost_left_Num {
  color: #ff5d3b;
  font-size: 35px;
  text-align: center;
  width: 100%;
  height: 60%;
}
.achievementModule_afterCost_right_title {
  color: #999999;
  font-size: 0.32rem;
  width: 100%;
  height: 49%;
  line-height: 3rem;
}
.achievementModule_afterCost_right_Num {
  color: #222222;
  font-size: 20px;
  width: 100%;
  height: 51%;
}

/*右上角选择时间左右箭头*/
.left-arrow,
.right-arrow {
  padding-bottom: 2px;
}
/*右上角选择时间*/
.min-date,
.max-date,
.middle-character {
  font-size: 14px;
  font-weight: 600;
  padding: 0 4px;
}
/*右上角统一div样式*/
.right-time {
  float: right;
  margin-right: 23px;
}

.achievementModuleCardBody_Style {
  width: 345px;
  height: 300px;
}
.single_Style {
  width: 345px;
  height: 130px;
}
</style>

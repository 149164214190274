<template>
  <div class="fundModule">
    <!--选择月份-->
    <div class="part-inputpart" style="margin-top: 10px 18px 10px 18px">
      <div class="part-inputpart-row" >
        <span :class=" 0==date.trim().length ? 'content-none' : 'content-have' "></span>
        <span class="part-inputpart-row-enablenon part-inputpart-row-header">日期</span>
        <span class="content-divide">|</span>
        <span class="part-inputpart-row-graytext" :class="0==date ? '': 'part-inputpart-row-redtext' " @click="clickDateShow">{{date}}</span>
        <span style="margin:0 15px;">~</span>
        <span class="part-inputpart-row-graytext" :class="0==twoDate ? '': 'part-inputpart-row-redtext' " @click="clickEndDateShow">{{twoDate}}</span>
        <img class="part-inputpart-row-right part-inputpart-row-right-timepick">
      </div>
      <div>
        <van-popup v-model="totalDateShow" position="bottom" class="selectTime">
          <div>
            <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" item-height="35px"
                                 @confirm="dateConfirm" @cancel="dateCancel" />
          </div>
        </van-popup>
      </div>
    </div>

    <div class="part-inputpart" style="margin-top: 10px">
      <div>
        <van-popup v-model="totalEndDateShow" position="bottom" class="selectTime">
          <div>
            <van-datetime-picker v-model="endDate" type="date" title="选择年月日" item-height="35px"
                                 @confirm="dateConfirm" @cancel="dateCancel" />
          </div>
        </van-popup>
      </div>
    </div>

    <div  style="color:#ff5d3b;width:90%;margin:10px auto 0px auto">内部账户收款</div>
    <div style="width:90%;margin:10px auto 0 auto;background:#ffffff;border-radius: 8px;">
      <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="getCollectionList">
        <div class="title">
          <van-row justify="center">
            <van-col span="8">收款类型</van-col>
            <!--<van-col span="9">金额（收）</van-col>-->
            <van-col span="16">金额（收）</van-col>
          </van-row>
        </div>
        <div class="content"  v-for="(item,index) in collectionList" :key="index">
          <van-row style="position:relative">
            <van-col span="8" class="billFee">{{item.feeName}}</van-col>

            <van-col span="16">{{item.sumActualAmount}} 元</van-col>
          </van-row>
        </div>
        <div class="totalCount">
          <van-row>
          </van-row>
          <van-row>
            <van-col span="8" class="billFee">总计:</van-col>
            <van-col span="16">{{collocationSum}} 元</van-col>
          </van-row>
        </div>
      </van-list>
    </div>
    <div  style="color:#ff5d3b;width:90%;margin:10px auto 0px auto">内部账户付款</div>
    <div style="width:90%;margin:10px auto 0 auto;background:#ffffff;border-radius: 8px;">
      <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="getPayList">
        <div class="title">
          <van-row justify="center">
            <van-col span="8">支付类型</van-col>
            <!--<van-col span="9">金额（收）</van-col>-->
            <van-col span="16">金额（支）</van-col>
          </van-row>
        </div>
        <div class="content"  v-for="item in payList" :key="item.billFeeType_id">
          <van-row style="position:relative">
            <van-col span="8" class="billFee">{{item.houseTypeSonStr}}</van-col>
            <!--<van-col span="9">{{item.collectSumAmount}} 元</van-col>-->
            <van-col span="16">{{item.totalAmount}} 元</van-col>
          </van-row>
        </div>
        <div class="totalCount">
          <van-row>
            <!--<van-col span="8" class="billFee">合计:</van-col>
            <van-col span="9">{{paySum.payeeAmount}} 元</van-col>
            <van-col span="9">{{paySum.payerAmount}} 元</van-col>-->
          </van-row>
          <van-row>
            <van-col span="8" class="billFee">总计:</van-col>
            <van-col span="16">{{paySum}} 元</van-col>
          </van-row>
        </div>
      </van-list>
    </div>


    <div  style="color:#ff5d3b;width:90%;margin:10px auto 0px auto">内部账户余额</div>
    <div style="width:90%;margin:10px auto 0 auto;background:#ffffff;border-radius: 8px;">
      <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="getBalanceList">
        <div class="title">
          <van-row justify="center">
            <van-col span="10">账户</van-col>
            <van-col span="7">上期余额</van-col>
            <van-col span="7">本期余额</van-col>
          </van-row>
        </div>
        <div class="content"  v-for="item in balanceList" :key="item.reconciliation_id">
          <van-row style="position:relative">
            <van-col span="10" class="billFee">{{item.accountName}}</van-col>
            <van-col span="7">{{item.balanceOfLastPeriod}} </van-col>
            <van-col span="7">{{item.currentBalance}} </van-col>
          </van-row>
        </div>
        <div class="totalCount">
          <van-row>
            <van-col span="10" class="billFee">合计:</van-col>
            <van-col span="7">{{balanceOfLastPeriodSum}} 元</van-col>
            <van-col span="7">{{currentBalanceSum}} 元</van-col>
          </van-row>
          <!--<van-row>
              <van-col span="8" class="billFee">总计:</van-col>
              <van-col span="16">{{balanceSum.difference}} 元</van-col>
          </van-row> -->
        </div>
      </van-list>
    </div>

  </div>
</template>

<script>
import {
  DatetimePicker,
  Popup,
  Sticky,
  Picker,
  Tab,
  Tabs,
  TreeSelect,
  Col,
  Row,
  Button,
  List,
  Cell,
} from 'vant'
import {
  queryCashFlowReceiveAmount,queryCashFlowPayAmount,queryCashFlowAccountAmount,queryPayeeBillSumAmount
} from '../../getData/getData'
import { responseUtil, addMonth, getStaffId } from '../../libs/rongxunUtil'
import util from '../contract/util2'

var echarts = require('echarts')
export default {
  name: "cashFlow",
  components: {
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
    [DatetimePicker.name]: DatetimePicker,
    [List.name]: List,
    [Cell .name]:Cell,
  },
  data() {
    return {
      date:'',
      twoDate:'',
      threeDate:'',
      currentDate:new Date(),
      loading:false,
      finished:false,
      fundList:[],    //资金
      sum:{},
      totalDateShow:false,
      totalEndDateShow:false,
      endDate:new Date(),
      payList:[],    //支付
      paySum:0,
      balanceList:[],
      balanceOfLastPeriodSum:0,
      currentBalanceSum:0,
      exBalanceSum:{},
      collectionList:[],  //收款
      collocationSum:{},
    }
  },
  mounted() {
  },
  methods: {
    getPayList(){   //获取付款list
      let that=this
      let param={}
      param.currentPage=1
      param.numberPage='100'
      param.beginTime=this.changeDate(this.currentDate)
      param.endTime=this.changeEndDate(this.endDate)
      param.user_id=getStaffId()
      if(!param.user_id){
        return
      }
      queryCashFlowPayAmount(param).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.payList=response.data.data.data ?response.data.data.data :[]
          that.paySum = response.data.data.sum?(Number(response.data.data.sum).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,'):{}
          that.paySum = (Number(response.data.data.sum).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          for(let i in that.payList){
            that.payList[i].totalAmount=(that.payList[i].totalAmount).toFixed(2)
            that.payList[i].totalAmount=that.payList[i].totalAmount.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
            //that.payList[i].collectSumAmount=(that.payList[i].collectSumAmount).toFixed(2)
            //that.payList[i].collectSumAmount=that.payList[i].collectSumAmount.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          }
          that.$forceUpdate()
        })
      })
    },
    getCollectionList(){   //获取收款list
      let that = this
      let param = {}
      param.beginTime=this.changeDate(this.currentDate)
      param.endTime=this.changeEndDate(this.endDate)
      queryCashFlowReceiveAmount(param).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.collectionList = response.data.data.data?response.data.data.data:[]
          that.collocationSum = response.data.data.sum?(Number(response.data.data.sum).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,'):{}
          that.collocationSum = (Number(response.data.data.sum).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          for(let i in that.collectionList){
            that.collectionList[i].sumActualAmount = (that.collectionList[i].sumActualAmount).toFixed(2)
            that.collectionList[i].sumActualAmount = (that.collectionList[i].sumActualAmount).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          }
          that.$forceUpdate()
        })
      })
    },
    getBalanceList(){  //账户余额

      let that=this
      let param={}
      // param.currentPage=1
      // param.numberPage='100'
      param.beginTime=this.changeDate(this.currentDate)
      param.endTime=this.changeEndDate(this.endDate)
      // param.dictionaryValue=1
      queryCashFlowAccountAmount(param).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.balanceList=response.data.data.data ?response.data.data.data :[]
          that.balanceOfLastPeriodSum = response.data.data.balanceOfLastPeriodSum?(Number(response.data.data.balanceOfLastPeriodSum).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,'):{}
          that.balanceOfLastPeriodSum = (Number(response.data.data.balanceOfLastPeriodSum).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          that.currentBalanceSum = response.data.data.currentBalanceSum?(Number(response.data.data.currentBalanceSum).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,'):{}
          that.currentBalanceSum = (Number(response.data.data.currentBalanceSum).toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          that.loading=true
          that.finished=true
          for(let i in that.balanceList){
            that.balanceList[i].balanceOfLastPeriod=(that.balanceList[i].balanceOfLastPeriod).toFixed(2)
            that.balanceList[i].balanceOfLastPeriod=that.balanceList[i].balanceOfLastPeriod.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
            that.balanceList[i].currentBalance=(that.balanceList[i].currentBalance).toFixed(2)
            that.balanceList[i].currentBalance=that.balanceList[i].currentBalance.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          }
          // if(that.balanceSum.difference){
          //   that.balanceSum.difference=(that.balanceSum.difference.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          //   that.balanceSum.payeeAmount=(that.balanceSum.payeeAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          //   that.balanceSum.payerAmount=(that.balanceSum.payerAmount.toFixed(2)).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,')
          // }
          that.$forceUpdate()
        })
      })
    },

    changeDate(currentDate){
      let year=new Date(currentDate).getFullYear()
      let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
      let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
      this.date=year+'-'+month+'-'+day
      return  this.date
    },
    changeEndDate(currentDate){
      let year=new Date(currentDate).getFullYear()
      let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
      let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
      this.twoDate=year+'-'+month+'-'+day
      return  this.twoDate
    },
    changeNowDate(currentDate){
      let year=new Date(currentDate).getFullYear()
      let month=new Date(currentDate).getMonth()+1>9?new Date(currentDate).getMonth()+1:'0'+(new Date(currentDate).getMonth()+1)
      let day=new Date(currentDate).getDate()>9?new Date(currentDate).getDate():'0'+new Date(currentDate).getDate()
      this.threeDate=year+'-'+month+'-'+day
      return  this.threeDate
    },
    clickDateShow(){
      this.totalDateShow=!this.totalDateShow
    },
    clickEndDateShow(){
      this.totalEndDateShow=!this.totalEndDateShow
    },
    clickNowDateShow(){
      this.selectNowDateShow=!this.selectNowDateShow
    },
    dateConfirm(){
      if(this.currentDate.getTime()>this.endDate.getTime()){
        responseUtil.alertErrMsg(this,'开始日期不可晚于结束日期')
        return
      }
      this.totalDateShow=false
      this.totalEndDateShow=false
      // this.getFundList()
      this.getPayList()
      this.getCollectionList()
      this.getBalanceList()
    },
    dateCancel(){
      this.totalDateShow=false
      this.totalEndDateShow=false
    },
  },
}
</script>

<style scoped>
.fundModule{
  font-size:12px;
  font-weight:bold
}
.van-col{
  padding:8px 10px;
  border:0.5px solid #fafafa;
  text-align: right;
}
.title .van-col{
  padding:8px 10px;
  border:0.5px solid #fafafa;
  text-align: center;
}
.content .billFee,.totalCount .billFee{
  padding:8px 10px;
  border:0.5px solid #fafafa;
  text-align: center;
}
/*选择月份的CSS*/
.part {
  margin: 25px;
}

.part-subtitle {
  color: #ff5d3b;
  font-size: 12px;
  font-weight: 600;
}

.part-inputpart {
  display: inline-block;
  flex-direction: column;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background-color: #ffffff;
  margin-top: 2px;
  width: 342px;
  height: 50px;
  border-radius: 8px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.part-inputpart-row-header {
  font-weight: 800;
}

.part-inputpart-row input {
  border: 0;
  width: 100%;
}

.part-inputpart-dropDownList {
  height: 23%;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 92.5%;
  position: absolute;
  margin-left: 20px;
  height: 185px;
  width: 335px;
  z-index: 99;
}

.part-inputpart-row-enablenon {
  /*margin-left: 26px;*/
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 22%;
}


.part-inputpart-row-redtext {
  width: 22%;
  color: #ff5d3b;
  font-weight: 600;
  margin:0 auto;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}


.part-inputpart-row-right-timepick {
  width: 20px;
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}

.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
  margin-top: 1px;


}

/*上升图片的CSS */
.big {
  width: 335px;
  height: 213px;
  background-color: white;
  margin-left: 20px;
  margin-top: -16px;
  border-radius: 8px;
  position: relative;
}

.up {
  width: 45px;
  height: 45px;
  position: absolute;
  left: 45%;
  top: 25.5%;
}

.money {
  font-size: 35px;
  font-weight: 600;
  position: absolute;
  left: 50%;
  top: 62%;
  color: #FF5E3B;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

/*.money {*/
/*width: 100%;*/
/*height: auto;*/
/*border: 1px solid red;*/
/*!*line-height: 25px;*!*/
/*font-size: 35px;*/
/*font-weight: 600;*/
/*color: #FF5E3B;*/
/*}*/
/*.money-flex{*/
/*display: flex;*/
/*align-items: center;*/
/*justify-content: center;*/
/*flex-direction: column;*/
/*}*/
.total {
  font-size: 13px;
;
  position: absolute;
  left: 42%;
  top: 80%;
  color: #B6B6B6;

}

/*循环部分*/
.green {
  margin: 0;
  width: 80px;
  height: 17px;
  font-size: 9px;
  text-align: center;
  line-height: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(to right, #63cb96, #30d0ac);
  color: #EDFCF5;
}

.orange {
  margin: 0;
  width: 43px;
  height: 17px;
  font-size: 9px;
  text-align: center;
  line-height: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(to right, #FFC274, #FF5D3B);
  color: #EDFCF5;
}

.red {
  margin: 0;
  width: 43px;
  height: 17px;
  font-size: 9px;
  text-align: center;
  line-height: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(to right, #FC3F1C, #E22220);
  color: #EDFCF5;
}

.reviews {
  width: 100%;
  overflow: hidden;
  height: auto;
  position: relative;
  top: 15px;
  background-color: #fAfAfA;
}

.review {
  margin: 0 15px 15px 20px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  width: 335px;
}

.top-title-orange {
  margin: 0;
  width: 45px;
  height: 18px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  border-radius: 8px 0;
  background: linear-gradient(to right, #FFC274, #FF5D3B);
  color: white;
}

.top-title-green {
  margin: 0;
  width: 45px;
  height: 18px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  border-radius: 8px 0;
  background: linear-gradient(to right, #63cb96, #30d0ac);
  color: white;
}

.top-title-red {
  margin: 0;
  width: 45px;
  height: 18px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  border-radius: 8px 0;
  background: linear-gradient(to right, #FF431B, #E02020);
  color: white;
}

.position {
  margin: 5px 15px 15px 17px;
  /*width: 345px;*/
  height: auto;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 10px;
}

.position-span-left {
  line-height: 20px;
  color: #FE5C40;
}

.position-span-right {
  line-height: 23px;
  color: black;
}


.demand {
  width: 100%;
  height: auto;
  border-top: 1px solid #f4f4f4;
}

.demand-reason {
  margin-top: 5px;
  margin-left: 15px;
  color: #222222;
  font-weight: 800;
  font-size: 13px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.types{
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
}
.type{
  font-size: 14px;
  color: #fe5e3a;
  margin-left: 15px;
}
.small-icon{
  width: 8px;
  height: 8px;
  margin-left: 5px;
  /*background-image: url("../../../assets/images/small-icon.png");*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.small-icon-rotate{
  width: 8px;
  height: 8px;
  margin-left: 5px;
  margin-bottom: 5px;
  /*background-image: url("../../../assets/images/small-icon.png");*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform:rotate(180deg);
}
</style>
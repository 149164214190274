<template>
  <div class="fundModule">
    <div class="head">
      <div class="search">
        <div class="search-icon" @click="initData"></div>
        <input
          type="text"
          placeholder="目标名称"
          v-model="searchContent"
          @keyup.enter="initData"
        />
      </div>

      <!-- <div class="searchRight">
        <div class="module-small" @click="showProject">
          <span class="project-name">{{ projectName }}</span>
          <img
            class="drop-down-image"
            src="../../assets/images/drop-down.png"
          />
        </div>
      </div> -->
    </div>
    <div class="types">
      <span class="type" @click="showProject" id="downList">{{ projectName ? projectName : '全部' }}</span>
      <div :class="isShowProjectMenu?'small-icon-rotate':'small-icon'"></div>
    </div>
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <van-picker
        show-toolbar
        :columns="projectDataInfo"
        value-key="projectName"
        @cancel="cancelChangeProject"
        @confirm="selectProject"
        :default-index="defaultIndex"
      />
    </van-popup>
    <div class="mainCont">
      <div
        v-for="(item, index) in dataList"
        :key="index"
        class="mianContItem"
        @click="targetListPage(item)"
      >
        {{ item.MBOName + "-" + item.statusName }}
      </div>
    </div>
  </div>
</template>
<script>
import { Popup, Picker } from "vant";
import { queryManagementByObjectivesList } from "../../getData/getData";
import { responseUtil, getStaffId } from "../../libs/rongxunUtil";

export default {
  name: "businessModule",
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  data() {
    return {
      dataList: [
        // {MBOName: '111',statusName: '22'},
        // {MBOName: '111',statusName: '22'},
      ],
      searchContent: "",
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: "进行中",
      projectDataInfo: [
        { projectName: "全部", value: "" },
        { projectName: "进行中", value: "进行中" },
        { projectName: "已结束", value: "已结束" },
        { projectName: "未开始", value: "未开始" },
      ],
      projectId: "进行中",
      defaultIndex: 1,
    };
  },
  mounted() {
    this.initData();
    // alert(11)
  },
  methods: {
    initData() {
      var that = this;
      const params = {
        MBOName: that.searchContent,
        user_id: getStaffId(),
        statusName: that.projectId,
      };
      queryManagementByObjectivesList(params).then((res) => {
        responseUtil.dealResponse(that, res, () => {
          that.dataList = res.data.data.data;
        });
      });
    },
    targetListPage(item) {
      localStorage.removeItem('hourse_id_type');
      localStorage.setItem('hourse_id_type',JSON.stringify(item.id))
      this.$router.push({
        name: "targetStatisticsList",
        query: {
          form: item,
        },
      });
    },
    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true;
    },
    //选择项目
    selectProject(item) {
      this.projectName = item.projectName;
      this.projectId = item.value;
      this.isShowProjectMenu = false;
      this.initData();
    },
    //取消选择
    cancelChangeProject() {
      // this.projectName = "进行中";
      // this.projectId = "进行中";
      // this.defaultIndex = 1;
      this.isShowProjectMenu = false;

      // this.initData();
    },
  },
};
</script>
<style lang="less" scoped>
.fundModule {
  font-size: 12px;
  font-weight: bold;
  background: #fafafa;
}
.mainCont {
  margin: 0 10px;
  background: #fff;
  /* height: 80vh; */
  overflow: auto;
  border-radius: 10px;
}
.mianContItem {
  display: flex;
  align-items: center;
  height: 50px;
  /* background: salmon; */
  padding-left: 20px;
  border-bottom: 1px solid #fafafa;
}
.head {
  width: 100%;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
  display: flex;
}

.search {
  margin: 0 10px 0px 10px;
  height: 36px;
  border-radius: 30px;
  //   background-color:skyblue;
  background-color: rgba(244, 244, 244, 1);
  width: 100%;
  display: flex;

  align-items: center;
}
.searchRight {
  width: 50%;
}

.search-icon {
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
input {
  font-size: 14px;
  margin-left: 10px;
  //   background-color: rgba(244, 244, 244, 1);
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 85%;
}

/*项目选择模块*/
.module-small {
  height: 36px;
  margin-right: 10px;
  background-color: rgba(244, 244, 244, 1);
  border-radius: 30px;
  line-height: 36px;
}

/*项目名称样式*/
.project-name {
  font-size: 14px;
  color: #666666;
  margin-left: 20px;
  font-weight: bold;
}

/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 18px;
  /*padding-bottom: 4px;*/
}


.types {
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  // background: #fe5e3a;
}

.type {
  text-align: left;
  color: #fe5e3a;
  margin-left: 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.small-icon {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-image: url("../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin-bottom: 5px;
  margin-left: 5px;
  background-image: url("../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}
</style>

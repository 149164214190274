<template>
  <div class="decoration-statistics">
    <!--项目选择模块-->
    <div class="module-small" @click="showProject">
      <span class="project-name">{{
        departmentName != undefined && departmentId != ''
          ? departmentName
          : '部门检索'
      }}</span>
      <img class="drop-down-image" src="../../assets/images/drop-down.png" />
    </div>
    <!--拉下菜单-->
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <div class="choose-company-text">选择公司</div>
      <van-row justify="center">
        <div v-for="(item, index) in companyList" :key="index">
          <van-col span="8" offset="4" class="company-col-style"
            ><div
              :class="
                checkedIndex == index
                  ? 'company-col-checked-style'
                  : 'company-text-style'
              "
              @click="selectCompany(item, index)"
            >
              {{ item.departmentName ? item.departmentName : '部门检索' }}
            </div></van-col
          >
        </div>
      </van-row>
      <van-tree-select
        style="margin-top: 20px"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        :items="items"
        @click-nav="selectArea"
        @click-item="selectDepartment"
      />
      <div class="search">
        <van-button
          style="width: 2rem; color: orangered;border-color: orangered"
          plain
          type="primary"
          @click="resetDepartmentInfo"
          >清空</van-button
        >
        <van-button
          @click="searchDepartmentInfo"
          style="width: 2.8rem;margin-left: 4rem"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="normal"
        >
          筛选
        </van-button>
      </div>
    </van-popup>
    <div class="mianComputed">
      <div style="margin: 0 0 5px 15px">
        <span class="left-title">
          装修状态统计
        </span>
      </div>
      <div class="maincomcon">
        
        <div class="maincomconT">
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{formdata.toExamined}}</div>
              <div class="maincomconTItemfont">待处理</div>
            </div>
          </div>
          <div class="linedashed"></div>
          <div class="maincomconTItem">
            <div>
             <div class="maincomconTItemcount">{{formdata.inDesign}}</div>
              <div class="maincomconTItemfont">设计中</div>
            </div>
          </div>
          <div class="linedashed"></div>
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{formdata.my}}</div>
              <div class="maincomconTItemfont">装配中</div>
            </div>
          </div>
        </div>
        <div class="maincomconT">
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{formdata.assembly}}</div>
              <div class="maincomconTItemfont">待验收</div>
            </div>
          </div>
          <div class="linedashed"></div>
          <div class="maincomconTItem">
            <div>
              <div class="maincomconTItemcount">{{formdata.finalAccounts}}</div>
              <div class="maincomconTItemfont">待决算</div>
            </div>
          </div>
          <!-- <div class="linedashed"></div> -->
          <div class="maincomconTItem">
            <div>
              <!-- <div class="maincomconTItemcount">111</div>
              <div class="maincomconTItemfont">描述</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="totalContant">
      <div style="margin: 0 0 5px 15px">
        <span class="left-title">
          装修数量统计
        </span>
      </div>
      <div class="maincomcon flexCommon">
        <div class="maincomconItem flexCenCommon">
          <div>
            <div style="font-size: 15px" class="maincomconTItemcount">{{(totalFormData.sethouseCount ? totalFormData.sethouseCount : 0) + '套（' + (totalFormData.totalCount ? totalFormData.totalCount : 0) +'间）'}}</div>
            <div class="maincomconTItemfont">间数</div>
          </div>
        </div>
        <div class="linedashed"></div>
        <div class="maincomconItem flexCenCommon">
          <div>
            <div style="font-size: 15px" class="maincomconTItemcount">{{totalFormData.toConfig ? totalFormData.toConfig : 0}}</div>
            <div class="maincomconTItemfont">配置中</div>
          </div>
        </div>
        <div class="linedashed"></div>
        <div class="maincomconItem flexCenCommon">
          <div>
            <div style="font-size: 15px" class="maincomconTItemcount">{{totalFormData.rentAlready ? totalFormData.rentAlready : 0}}</div>
            <div class="maincomconTItemfont">在租中</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mainList">
      
        <span class="left-title">
          列表数据
        </span>
            <!-- 数据详情 -->
            <div class="dataDetail">
                <div class="dataStaffName">
                    <div style="font-size:14px;">房源地址</div>
                    <div v-for="item in additionList" :key="item.id">{{ item.roomFuzzyAddress ? item.roomFuzzyAddress.split("-")[0] : '--' }}</div>
                </div>
                <div class="staffData">
                    <!-- <div class="dataDetailHeaderRow"> -->
                    <div class="dataDetailRightDataRow" >
                        <div class="dataDetailRightData">预计天数</div> 
                        <div class="dataDetailRightData">实际天数</div>
                        <div class="dataDetailRightData ">进度</div>
                        <div class="dataDetailRightData1 " >详细地址</div>
                        <!-- <div v-for="item,index in additionHeaderList" :key="index" class="dataDetailHeader">
                            {{ item }}
                        </div> -->
                    </div>
                    <div v-for="item in additionList" :key="item.id" class="dataDetailRightDataRow">
                        <div :style="Number(item.percentageNum) > 100 ? unFinished : '' " class="dataDetailRightData">{{ item.totalAppointmentTimeLong ? item.totalAppointmentTimeLong : '--' }}</div>
                        <div :style="Number(item.percentageNum) > 100 ? unFinished : '' " class="dataDetailRightData">{{ item.actualTimeLong ? item.actualTimeLong : '--' }}</div>
                        <div :style="Number(item.percentageNum) > 100 ? unFinished : '' " class="dataDetailRightData">{{ item.percentageNum ? item.percentageNum.toFixed(2) + '%' : '--' }}</div>
                        <div :style="Number(item.percentageNum) > 100 ? unFinished : '' " class="dataDetailRightData1">{{ item.roomDetailedAddress ? item.roomDetailedAddress : '--' }}</div>
                    </div>
                    <div style="height: 50px;line-height:50px" v-if="additionList.length == 0">
                      暂无数据
                    </div>
                </div>

            </div>

            
    </div>
    <!--时长&金额模块-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          验收情况
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(1)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(1)">{{
            totalMoneyMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(2)">{{
            totalMoneyMaxDate
          }}</span>
          <img
            @click="leftArrow(2)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-figure">
        <div class="description-content1">
          <div class="left-content1">
            <div class="money-small-font">{{ avgDecorationTimeLong }}</div>
            <div class="description-text">平均装修时长/天</div>
          </div>
          <div class="middle-line"></div>
          <div class="right-content1">
            <div class="money-small-font">{{ checkInHouseNum }}</div>
            <div class="description-text">验收量/套</div>
          </div>
        </div>
      </div>
    </div>
    <!--验收情况-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          决算情况
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(3)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(3)">{{
            checkInMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(4)">{{
            checkInMaxDate
          }}</span>
          <img
            @click="leftArrow(4)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <!-- <div class="statistics-content-figure">
                <div class="description-content">
                    <div class="money-big-font">{{checkInHouseNum}}</div>
                    <div class="description-text">验收量/套</div>
                </div>
            </div> -->
      <div class="statistics-content-figure">
        <div class="description-content1">
          <div class="left-content1">
            <div class="money-small-font">{{ avgDecorationMoney }}</div>
            <div class="description-text">套均装修款/元</div>
          </div>
          <div class="middle-line"></div>
          <div class="right-content1">
            <div class="money-small-font">{{ monthDecoration }}</div>
            <div class="description-text">间均月装修款</div>
          </div>
        </div>
      </div>
    </div>
    
    <!--决算房源-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          决算房源
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(5)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(5)">{{
            finalAccountHouseMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(6)">{{
            finalAccountHouseMaxDate
          }}</span>
          <img
            @click="leftArrow(6)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-figure">
        <div class="description-content-other">
          <div class="left-content">
            <div class="money-small-font">
              {{ finalAccount.avgDecorationTimeLong }}
            </div>
            <div class="description-text">硬装平均费用</div>
          </div>
          <div class="middle-content">
            <div class="money-small-font">
              {{ finalAccount.avgDecorationMoney }}
            </div>
            <div class="description-text">软装平均费用</div>
          </div>
          <div class="right-content">
            <div class="money-other-font">
              {{ finalAccount.overBudgetHouseNum }}
            </div>
            <div class="description-text">超预算房量/套</div>
          </div>
        </div>
      </div>
    </div>

    <!--验收情况(间均月)-->
    <!-- <div class="module-literal">
		    
		    <div>
		        <span class="left-title">
		        验收情况（间均月）
		        </span>
		        <div class="right-time">
		            <img @click="leftArrow(7)" class="left-arrow" src="../../assets/images/left-arrow.png" >
		            <span class="min-date" @click="chooseTime(7)">{{goodsCostMinDate}}</span>
		            <span class="middle-character">至</span>
		            <span class="max-date" @click="chooseTime(8)">{{goodsCostMaxDate}}</span>
		            <img @click="leftArrow(8)" class="right-arrow" src="../../assets/images/right-arrow.png">
		        </div>
		    </div> -->
    <!--特征内容-->
    <!-- <div class="statistics-content-figure">
		        <div class="description-content">
		            <div class="money-big-font">{{monthDecoration}}</div>
		            <div class="description-text">间均月装修款</div>
		        </div>
		    </div>
		</div> -->
    <!--各类物品配置费用-->
    <!-- <div class="module-charts">
            <div> -->
    <!--统计标题-->
    <!-- <span class="left-title">
                各类物品配置费用
                </span>
                <div class="right-time">
                    <img @click="leftArrow(7)" class="left-arrow" src="../../assets/images/left-arrow.png" >
                    <span class="min-date" @click="chooseTime(7)">{{goodsCostMinDate}}</span>
                    <span class="middle-character">至</span>
                    <span class="max-date" @click="chooseTime(8)">{{goodsCostMaxDate}}</span>
                    <img @click="leftArrow(8)" class="right-arrow" src="../../assets/images/right-arrow.png">
                </div>
            </div> -->
    <!--特征内容-->
    <!-- <div class="statistics-content-chart">
                <ve-pie class="chart-style" width="345px" height="200px" :data="chartGoodsCostData" :extend="goodsCostSetend"></ve-pie>
            </div>
        </div> -->
    <!--验收情况-->
    <!--        <div class="module-literal">-->
    <!--            &lt;!&ndash;统计标题&ndash;&gt;-->
    <!--            <div>-->
    <!--                <span class="left-title">-->
    <!--                验收情况-->
    <!--                </span>-->
    <!--                <div class="right-time">-->
    <!--                    <img @click="leftArrow(9)" class="left-arrow" src="../../assets/images/left-arrow.png" >-->
    <!--                    <span class="min-date" @click="chooseTime(9)">{{intelligentDevicesMinDate}}</span>-->
    <!--                    <span class="middle-character">至</span>-->
    <!--                    <span class="max-date" @click="chooseTime(10)">{{intelligentDevicesMaxDate}}</span>-->
    <!--                    <img @click="leftArrow(10)" class="right-arrow" src="../../assets/images/right-arrow.png">-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            &lt;!&ndash;特征内容&ndash;&gt;-->
    <!--            <div class="statistics-content-figure">-->
    <!--                <div class="description-content">-->
    <!--                    <div class="money-big-font">3211</div>-->
    <!--                    <div class="description-text">间均月装修款</div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--智能设备-->
    <div class="module-charts">
      <div>
        <!--统计标题-->
        <span class="left-title">
          智能设备
        </span>
        <!-- <div class="right-time">
                    <img @click="leftArrow(9)" class="left-arrow" src="../../assets/images/left-arrow.png" >
                    <span class="min-date" @click="chooseTime(9)">{{intelligentDevicesMinDate}}</span>
                    <span class="middle-character">至</span>
                    <span class="max-date" @click="chooseTime(10)">{{intelligentDevicesMaxDate}}</span>
                    <img @click="leftArrow(10)" class="right-arrow" src="../../assets/images/right-arrow.png">
                </div> -->
      </div>
      <!--智能门锁-->
      <div class="statistics-IDL-chart">
        <ve-pie
          class="chart-style"
          width="345px"
          height="170px"
          :data="chartIntelligentDoorLockData"
          :extend="intelligentDoorLockSetend"
        ></ve-pie>
      </div>
      <!--智能设备-->
      <div class="statistics-ID-chart">
        <ve-pie
          class="chart-style"
          width="345px"
          height="170px"
          :data="chartIntelligentDevicesData"
          :extend="intelligentDevicesSetend"
        ></ve-pie>
      </div>
    </div>
    <!--装修满意度-->
    <div class="module-charts">
      <div>
        <!--统计标题-->
        <span class="left-title">
          装修满意度
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(11)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(11)">{{
            decorationSatisfactionMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(12)">{{
            decorationSatisfactionMaxDate
          }}</span>
          <img
            @click="leftArrow(12)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容--><!--:data="chartSatisfactionData"-->
      <div class="statistics-content-chart">
        <ve-histogram
          class="chart-style"
          width="345px"
          height="352px"
          :extend="satisfactionSetend"
        ></ve-histogram>
      </div>
    </div>

    <!--时间选择-->
    <van-popup v-model="isDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        item-height="35px"
        @confirm="dateConfirm"
        @cancel="isDateShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { responseUtil, getStaffId } from '@/libs/rongxunUtil'
import util from '../contract/util2'
import { fmoney } from '../../libs/rongxunMoneyUtil'
var echarts = require('echarts')
import {
  DatetimePicker,
  Popup,
  Picker,
  TreeSelect,
  Col,
  Row,
  Button,
} from 'vant'
import {
  decorationTimes, //平均装修时长
  setDecoration, //套均装修款
  acceptanceCount, //验收量
  intelligentDoorLock, //智能门锁占比
  smartMeter, //智能电表占比
  decorationSatisfaction, //装修满意度
  monthDecoration, //间均月装修款
  queryAuthLevelDepartment,
  workbenchafterRentDecorationList
} from '../../getData/getData'

export default {
  name: 'decorationStatistics',
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
  },
  data() {
    return {
      levelInfo: '',
      unFinished: {'color': 'red'},
      formdata: {
        acceptance: 0,
        assembly: 0,
        finalAccounts: 0,
        inDesign: 0,
        my: 0,
        toExamined: 0,
      },
      totalFormData: {},
      additionList: [
      ],
      additionHeaderList: [
        '预计天数',
        '实际天数',
        '进度',
        '详细地址'
      ],
      checkedIndex: 0,
      departmentName: '',
      departmentId: '', //部门id
      companyList: [],
      functionId: '1005',
      activeId: -1,
      activeIndex: 0,
      //新的列表接口所需数据
      departmentAuthData: {},
      items: [
        {
          text: '浙江',
          children: [
            { text: '杭州', id: 1 },
            { text: '温州', id: 2 },
          ],
        },
        {
          text: '辽宁',
          children: [
            { text: '沈阳', id: 3 },
            { text: '大连', id: 4 },
          ],
        },
        {
          text: '江苏',
          children: [
            { text: '南京', id: 5 },
            { text: '无锡', id: 6 },
          ],
        },
      ],
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: '集寓项目名称',
      projectDataInfo: [],

      avgDecorationTimeLong: '8', // 时长&金额  平均装修时长
      avgDecorationMoney: '3333333', // 时长&金额  平均装修款
      checkInHouseNum: '8', // 验收情况  验收量
      monthDecoration: '8', // 验收情况(间均月)
      finalAccount: {
        avgDecorationTimeLong: '8',
        avgDecorationMoney: '3333333',
        overBudgetHouseNum: '34',
      },
      chooseType: '', //选择类型 时长&总额 最小时间 or 验收情况  最小时间  等等
      currentDate: new Date(), //时间选择器绑定的时间
      isDateShow: false, //时间选择显示与否

      totalMoneyMinDate: this.formatDate(new Date()), //时长&总额 最小时间  1
      totalMoneyMaxDate: this.formatDate(new Date()), //时长&总额 最大时间  2
      checkInMinDate: this.formatDate(new Date()), //验收情况  最大时间  3
      checkInMaxDate: this.formatDate(new Date()), //验收情况  最大时间  4
      finalAccountHouseMinDate: this.formatDate(new Date()), //决算房源 最小时间  5
      finalAccountHouseMaxDate: this.formatDate(new Date()), //决算房源 最大时间  6
      goodsCostMinDate: this.formatDate(new Date()), //各类物品配置费用 最小时间  7
      goodsCostMaxDate: this.formatDate(new Date()), //各类物品配置费用 最大时间  8
      intelligentDevicesMinDate: this.reduceDate(new Date()), //智能设备 最小时间  9
      intelligentDevicesMaxDate: this.formatDate(new Date()), //智能设备 最小时间  10
      decorationSatisfactionMinDate: this.formatDate(new Date()), //装修满意度 最小时间  11
      decorationSatisfactionMaxDate: this.formatDate(new Date()), //装修满意度 最大时间  12

      defaultsNum: '332', //违约用户人数
      totalUserNum: '323123', //总用户数量

      /********************************各类物品费用占比样式 start **************************/
      // goodsCostSetend: {
      //     legend: {
      //         orient: 'vertical',
      //         left: 180,
      //         top:50,
      //         // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
      //         textStyle: {
      //             color: '#d8d8d8'
      //         },
      //         icon: 'circle'
      //     },
      //     title: {
      //         left: 180,
      //         top: 20,
      //         text: '各类物品费用占比',
      //         textStyle: {
      //             fontSize: 13
      //         }
      //     },

      //     series: {
      //         // 扇叶样式
      //         label: {
      //             normal: {
      //                 // 是否显示扇叶
      //                 show: false,
      //                 position: 'insideLeft',
      //                 // 文字大小
      //                 fontSize: 30
      //             }
      //         },
      //         // 位置
      //         center: ['25%', '55%'],
      //         // 大小
      //         radius : ['0', '60%'],
      //     }
      // },

      // chartGoodsCostData: {
      //     columns: ['价格区间', '人数'],
      //     rows: [
      //         { '价格区间': '<1月(234|98%)', '人数': 1393 },
      //         { '价格区间': '1个月(234|98%)', '人数': 3530 },
      //         { '价格区间': '<3个月(234|98%)', '人数': 2923 },
      //         { '价格区间': '<半年(234|98%)', '人数': 1723 },
      //         { '价格区间': '<一年(234|98%)', '人数': 3792 },
      //         { '价格区间': '<三年以上(234|98%)', '人数': 4593 }
      //     ]
      // },
      /********************************各类物品费用占比样式 end   **************************/

      /********************************智能门锁占比样式 start **************************/
      intelligentDoorLockSetend: {
        legend: {
          orient: 'vertical',
          left: 180,
          top: 70,
          // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        title: {
          left: 180,
          top: 40,
          text: '智能门锁占比',
          textStyle: {
            fontSize: 13,
          },
        },

        series: {
          // 扇叶样式
          label: {
            normal: {
              // 是否显示扇叶
              show: false,
              position: 'insideLeft',
              // 文字大小
              fontSize: 30,
            },
          },
          // 位置
          center: ['25%', '55%'],
          // 大小
          radius: ['0', '50%'],
        },
      },

      chartIntelligentDoorLockData: {
        columns: ['name', 'value'],
        rows: [
          //     { '类型': '空置(343|8%)', '数量': 1393 },
          //     { '类型': '转租(343|8%)', '数量': 3530 },
          //     { '类型': '申退(343|8%)', '数量': 2923 },
          //     { '类型': '预到期(343|8%)', '数量': 1723 },
          //     { '类型': '可租(343|8%)', '数量': 3792 },
          //     { '类型': '不可租(343|8%)', '数量': 3792 },
          //     { '类型': '配置(343|8%)', '数量': 4593 }
        ],
      },
      /********************************智能门锁占比样式 end   **************************/

      /********************************智能电表占比样式 start **************************/
      intelligentDevicesSetend: {
        legend: {
          orient: 'vertical',
          left: 180,
          top: 70,
          // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        title: {
          left: 180,
          top: 40,
          text: '智能电表占比',
          textStyle: {
            fontSize: 13,
          },
        },

        series: {
          // 扇叶样式
          label: {
            normal: {
              // 是否显示扇叶
              show: false,
              position: 'insideLeft',
              // 文字大小
              fontSize: 30,
            },
          },
          // 位置
          center: ['25%', '55%'],
          // 大小
          radius: ['0', '50%'],
        },
      },

      chartIntelligentDevicesData: {
        columns: ['类型', '数量'],
        rows: [
          // { '类型': '空置(343|8%)', '数量': 1393 },
          // { '类型': '转租(343|8%)', '数量': 3530 },
          // { '类型': '申退(343|8%)', '数量': 2923 },
          // { '类型': '预到期(343|8%)', '数量': 1723 },
        ],
      },
      /********************************智能电表占比样式 end   **************************/

      /********************************装修满意度占比样式 start **************************/
      satisfactionSetend: {
        legend: {
          show: true, //  各项备注是否显示
          top: 300,
          left: 55,
          icon: 'none',
          // data: ['非常满意', '满意', '一般', '不满意', '差评','渠道']
        },

        xAxis: {
          type: 'category',
          data: ['非常满意', '满意', '一般', '不满意', '差评'],
          //X轴文字样式
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 11,
          },
        },

        //Y轴样式
        yAxis: {
          position: 'left',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D8D8D8',
            },
          },
          //背景中的分割线样式
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F3F3F3',
            },
          },

          splitNumber: 8, //y轴数值间隔
          show: true, //是否显示Y轴
        },

        color: ['#5AB1EF'],

        barMaxWidth: 20,

        series: {
          name: '',
          data: [], // 数据
          type: 'bar',
          // itemStyle
          // 位置
          center: ['5%', '5%'],
          // 大小
          radius: ['0', '50%'],
        },
      },

      // chartSatisfactionData: {
      //     columns: ['1', '数量'],
      //     rows: [
      //         { '1': '非常满意', '数量': 139 },
      //         { '1': '满意', '数量': 353 },
      //         { '1': '一般', '数量': 292 },
      //         { '1': '不满意', '数量': 172 },
      //         { '1': '差评', '数量': 172 },
      //         { '1': '渠道', '数量': 172 },
      //     ]
      // },
      /********************************装修满意度占比样式 end   **************************/
    }
  },

  mounted() {
    //装修满意度
    this.getDecorationSatisfaction()
    //公司初始化
    this.queryAuthLevelDepartment()
    this.getworkbenchafterRentDecorationList()
  },
  methods: {
    
    getworkbenchafterRentDecorationList() {

      var that = this
      const params = {
        staff_id: getStaffId(),
        store_group: that.departmentId,
        level: that.levelInfo
      }

      workbenchafterRentDecorationList(params).then(res => {
        responseUtil.dealResponse(that, res, () => {
          console.log(res.data.data,"res.data.data====")
          that.formdata = res.data.data.renovationMap
          that.totalFormData = res.data.data.houseRoomCount
          that.additionList = res.data.data.data
        })
      })
    },
    queryAuthLevelDepartment() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId() //员工id
      initData.function_id = that.functionId //方法模块id
      queryAuthLevelDepartment(initData).then(res =>
        responseUtil.dealResponse(that, res, () => {
          that.departmentAuthData = res.data.data
          let allList = that.departmentAuthData.list
          if (allList != null) {
            that.companyList = allList.filter(item => item.level == 1)
          }
          let departmentId = that.departmentAuthData.roleDepartmentId
          that.departmentId = departmentId
          let item = that.departmentAuthData.list.find(
            item => item.id == departmentId
          )
          that.departmentName = item ? item.departmentName || '' : ''
          that.initOtherDepartment()
          that.searchDepartmentInfo()
        })
      )
    },
    initOtherDepartment() {
      let that = this
      let allList = that.departmentAuthData.list
      let departmentId = that.departmentId
      let threeLevelDepartments = allList.filter(item => item.level == 3)
      let fiveLevelDepartments = allList.filter(item => item.level == 5)
      let levelDepartments = threeLevelDepartments
        .filter(item => {
          let levelTop = item.levelTop || ''
          return levelTop.indexOf(departmentId) != -1
        })
        .map(item => {
          let result = {}
          result.children = fiveLevelDepartments
            .filter(child => {
              let levelTop = child.levelTop || ''
              return levelTop.indexOf(',' + item.id) != -1
            })
            .map(child => {
              let childResult = {}
              childResult.text = child.departmentName
              childResult.id = child.id
              childResult.level = child.level
              return childResult
            })
          result.text = item.departmentName
          result.value = item.id
          result.level = item.level
          return result
        })
      that.items = levelDepartments // 公司列表
    },
    resetDepartmentInfo() {
      let departmentId = this.departmentAuthData.roleDepartmentId
      let item = this.departmentAuthData.list.find(
        item => item.id == departmentId
      )
      this.departmentName = item ? item.departmentName || '' : ''
      this.departmentId = departmentId
      this.levelInfo = ''
      this.isShowProjectMenu = false
      this.searchDepartmentInfo()
    },

    //选择公司，联动下方区、部
    selectCompany(item, index) {
      let that = this
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(that, '权限不足')
        return
      }
      this.checkedIndex = index
      this.departmentId = item.id
      this.activeId = this.departmentId
      this.departmentName = item.departmentName
      this.levelInfo = item.level
      this.initOtherDepartment()
    },
    selectArea(value) {
      let item = this.items[value]
      this.levelInfo = item.level
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = item.value
      this.departmentName = item.text
      this.activeId = this.departmentId
    },
    selectDepartment(data) {
      this.levelInfo = data.level
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = data.id
      this.departmentName = data.text
    },
    searchDepartmentInfo() {
      //平均装修时长
      this.getDecorationTimes()
      //套均装修款
      this.getSetDecoration()
      //验收量
      this.getAcceptanceCount()
      //决算房源
      this.getFinalAccounts()
      //智能门锁占比
      this.getIntelligentDoorLock()
      //智能电表占比
      this.getSmartMeter()

      //间均月装修款
      this.getMonthDecoration()

      this.getworkbenchafterRentDecorationList()

      this.isShowProjectMenu = false
    },
    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true
    },
    //取消选择
    cancelChangeProject() {
      this.projectName = '集寓项目名称'
      this.projectId = ''
      this.isShowProjectMenu = false
    },

    //平均装修时长
    getDecorationTimes() {
      var that = this
      let data = {}
      data.beginTime = util.dateFormat(that.totalMoneyMinDate)
      data.endTime = util.getCurrentMonthLast(that.totalMoneyMaxDate)

      data.depId = that.departmentId //departmentId
      decorationTimes(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.avgDecorationTimeLong = response.data.data.renovationTime
        })
      })
    },

    //套均装修款
    getSetDecoration() {
      var that = this
      let data = {}
      data.depId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.checkInMinDate)
      data.endTime = util.getCurrentMonthLast(that.checkInMaxDate)
      setDecoration(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.avgDecorationMoney = fmoney(response.data.data.result)
        })
      })
    },

    //验收量
    getAcceptanceCount() {
      var that = this
      let data = {}
      data.depId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.totalMoneyMinDate)
      data.endTime = util.getCurrentMonthLast(that.totalMoneyMaxDate)
      acceptanceCount(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.checkInHouseNum = response.data.data.result
        })
      })
    },
    //决算房源 （硬装平均费用、软装平均费用、超预算房源量）
    getFinalAccounts() {
      var that = this
      let data = {}
      data.depId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.finalAccountHouseMinDate)
      data.endTime = util.getCurrentMonthLast(that.finalAccountHouseMaxDate)

      decorationTimes(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.finalAccount.avgDecorationTimeLong = fmoney(
            response.data.data.hardPrice
          ) //硬装
          that.finalAccount.avgDecorationMoney = fmoney(
            response.data.data.softPrice
          ) //软装
          that.finalAccount.overBudgetHouseNum = response.data.data.overPrice //超预算房源量
        })
      })
    },

    //智能门锁占比
    getIntelligentDoorLock() {
      var that = this
      let data = {}
      data.depId = that.departmentId //departmentId
      //data.beginTime = util.dateFormat(that.finalAccountHouseMinDate)
      //data.endTime = util.dateFormat(that.finalAccountHouseMaxDate)
      intelligentDoorLock(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.lockNumList
          that.chartIntelligentDoorLockData.rows = list

          // that.chartIntelligentDoorLockData.rows = [
          //     { '类型': response.data.data.lockNumList[0].name, '数量': response.data.data.lockNumList[0].value },
          // 	{ '类型': response.data.data.lockNumList[1].name, '数量': response.data.data.lockNumList[1].value },
          // 	{ '类型': response.data.data.lockNumList[2].name, '数量': response.data.data.lockNumList[2].value }

          // ]
        })
      })
    },

    //智能电表占比
    getSmartMeter() {
      var that = this
      let data = {}
      data.depId = that.departmentId //departmentId
      smartMeter(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.chartIntelligentDevicesData.rows = [
            {
              类型: response.data.data.dianbiaoNumList[0].name,
              数量: response.data.data.dianbiaoNumList[0].value,
            },
            {
              类型: response.data.data.dianbiaoNumList[1].name,
              数量: response.data.data.dianbiaoNumList[1].value,
            },
          ]
        })
      })
    },
    //装修满意度
    getDecorationSatisfaction() {
      var that = this
      let data = {}
      data.beginTime = util.dateFormat(that.decorationSatisfactionMinDate)
      data.endTime = util.getCurrentMonthLast(
        that.decorationSatisfactionMaxDate
      )

      decorationSatisfaction(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.satisfactionSetend.series.data = [
            response.data.data.five,
            response.data.data.four,
            response.data.data.three,
            response.data.data.two,
            response.data.data.one,
          ]
        })
      })
    },
    //间均月装修款
    getMonthDecoration() {
      var that = this
      let data = {}
      data.depId = that.departmentId //departmentId
      data.beginTime = util.dateFormat(that.checkInMinDate)
      data.endTime = util.getCurrentMonthLast(that.checkInMaxDate)

      monthDecoration(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.monthDecoration = fmoney(response.data.data.result)
        })
      })
    },

    //选择时间
    chooseTime(value) {
      this.chooseType = value //时长&总额 最小时间  1
      this.isDateShow = true
    },

    /*时间格式转化方法*/
    formatDate(dateTime) {
      let year = `${dateTime.getFullYear()}`
      let month = `${dateTime.getMonth() + 1}`
      if (month < 10) {
        month = `0${dateTime.getMonth() + 1}`
      }
      return year + '-' + month
    },

    //确认时间
    dateConfirm(date) {
      this.currentDate = this.formatDate(date)
      if (this.chooseType == 1) {
        this.totalMoneyMinDate = this.currentDate //时长&总额 最小时间  1
        this.getDecorationTimes() //平均装修时长
        //this.getSetDecoration()//套均装修款
        this.getAcceptanceCount() //验收量
      } else if (this.chooseType == 2) {
        this.totalMoneyMaxDate = this.currentDate //时长&总额 最大时间  2
        this.getDecorationTimes() //平均装修时长
        //this.getSetDecoration()//套均装修款
        this.getAcceptanceCount() //验收量
      } else if (this.chooseType == 3) {
        this.checkInMinDate = this.currentDate //验收情况  最小时间  3
        //this.getAcceptanceCount()//验收量
        this.getSetDecoration() //套均装修款
        this.getMonthDecoration() //间均月装修款
      } else if (this.chooseType == 4) {
        this.checkInMaxDate = this.currentDate //验收情况  最大时间  4
        //this.getAcceptanceCount()//验收量
        this.getSetDecoration() //套均装修款
        this.getMonthDecoration() //间均月装修款
      } else if (this.chooseType == 5) {
        this.finalAccountHouseMinDate = this.currentDate //决算房源 最小时间  5
        this.getFinalAccounts() //决算房源
      } else if (this.chooseType == 6) {
        this.finalAccountHouseMaxDate = this.currentDate //决算房源 最大时间  6
        this.getFinalAccounts() //决算房源
      } else if (this.chooseType == 7) {
        this.goodsCostMinDate = this.currentDate //各类物品配置费用 最小时间  7
        //this.getMonthDecoration()//间均月装修款
      } else if (this.chooseType == 8) {
        this.goodsCostMaxDate = this.currentDate //各类物品配置费用 最大时间  8
        //this.getMonthDecoration()//间均月装修款
      } else if (this.chooseType == 9) {
        this.intelligentDevicesMinDate = this.currentDate //智能设备 最小时间  9
      } else if (this.chooseType == 10) {
        this.intelligentDevicesMaxDate = this.currentDate //智能设备 最小时间  10
      } else if (this.chooseType == 11) {
        this.decorationSatisfactionMinDate = this.currentDate //装修满意度 最小时间  11
        this.getDecorationSatisfaction() //装修满意度
      } else if (this.chooseType == 12) {
        this.decorationSatisfactionMaxDate = this.currentDate //装修满意度 最大时间  12
        this.getDecorationSatisfaction() //装修满意度
      }

      this.isDateShow = false

      // this.getDecorationTimes()//平均装修时长
      // this.getSetDecoration()//套均装修款
      // this.getAcceptanceCount()//验收量
      // this.getFinalAccounts()//决算房源
      // this.getDecorationSatisfaction()//装修满意度

      // this.getMonthDecoration()//间均月装修款
    },

    /*点击箭头，增加或减少一个月*/
    leftArrow(paramType) {
      if (paramType == 1) {
        //时长&总额 最小时间  1
        let date = new Date(this.totalMoneyMinDate)
        this.totalMoneyMinDate = this.reduceDate(date)
        this.getDecorationTimes() //平均装修时长
        //this.getSetDecoration()//套均装修款
        this.getAcceptanceCount() //验收量
      } else if (paramType == 2) {
        //时长&总额 最大时间  2
        let date = new Date(this.totalMoneyMaxDate)
        this.totalMoneyMaxDate = this.addDate(date)
        this.getDecorationTimes() //平均装修时长
        //this.getSetDecoration()//套均装修款
        this.getAcceptanceCount() //验收量
      } else if (paramType == 3) {
        //验收情况  最小时间  3
        let date = new Date(this.checkInMinDate)
        this.checkInMinDate = this.reduceDate(date)
        //this.getAcceptanceCount()//验收量
        this.getSetDecoration() //套均装修款
        this.getMonthDecoration() //间均月装修款
      } else if (paramType == 4) {
        //验收情况  最大时间  4
        let date = new Date(this.checkInMaxDate)
        this.checkInMaxDate = this.addDate(date)
        //this.getAcceptanceCount()//验收量
        this.getSetDecoration() //套均装修款
        this.getMonthDecoration() //间均月装修款
      } else if (paramType == 5) {
        //决算房源 最小时间  5
        let date = new Date(this.finalAccountHouseMinDate)
        this.finalAccountHouseMinDate = this.reduceDate(date)
        this.getFinalAccounts() //决算房源
      } else if (paramType == 6) {
        //决算房源 最大时间  6
        let date = new Date(this.finalAccountHouseMaxDate)
        this.finalAccountHouseMaxDate = this.addDate(date)
        this.getFinalAccounts() //决算房源
      } else if (paramType == 7) {
        //各类物品配置费用 最小时间  7
        let date = new Date(this.goodsCostMinDate)
        this.goodsCostMinDate = this.reduceDate(date)
        //this.getMonthDecoration()//间均月装修款
      } else if (paramType == 8) {
        //各类物品配置费用 最大时间  8
        let date = new Date(this.goodsCostMaxDate)
        this.goodsCostMaxDate = this.addDate(date)
        //this.getMonthDecoration()//间均月装修款
      } else if (paramType == 9) {
        //智能设备 最小时间  9
        let date = new Date(this.intelligentDevicesMinDate)
        this.intelligentDevicesMinDate = this.reduceDate(date)
      } else if (paramType == 10) {
        //智能设备 最小时间  10
        let date = new Date(this.intelligentDevicesMaxDate)
        this.intelligentDevicesMaxDate = this.addDate(date)
      } else if (paramType == 11) {
        //装修满意度 最小时间  11
        let date = new Date(this.decorationSatisfactionMinDate)
        this.decorationSatisfactionMinDate = this.reduceDate(date)
        this.getDecorationSatisfaction() //装修满意度
      } else if (paramType == 12) {
        //装修满意度 最大时间  12
        let date = new Date(this.decorationSatisfactionMaxDate)
        this.decorationSatisfactionMaxDate = this.addDate(date)
        this.getDecorationSatisfaction() //装修满意度
      }

      // this.getDecorationTimes()//平均装修时长
      // this.getSetDecoration()//套均装修款
      // this.getAcceptanceCount()//验收量
      // this.getFinalAccounts()//决算房源
      // this.getDecorationSatisfaction()//装修满意度
      // //间均月装修款
      // this.getMonthDecoration()
    },

    //使年月格式的时间减少一个月
    reduceDate(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month
      }
    },

    //使年月格式的时间增加一个月
    addDate(dateTime) {
      if (dateTime.getMonth() + 1 > 11) {
        let year = `${dateTime.getFullYear() + 1}`
        let month = `01`
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 + 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 + 1}`
        }
        return year + '-' + month
      }
    },
  },
}
</script>

<style scoped lang="less">
/*项目选择模块*/
.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  margin: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  background-color: #f7f8fa;
  border-radius: 0.1rem;
  font-size: 17px;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}
/*背景颜色*/
.decoration-statistics {
  width: 10rem;
  background-color: #fafafa;
  height: 1350px;
}

/*模块样式*/
.module-literal {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/*左上角标题*/
.left-title {
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  color: #ff5d3b;
  font-weight: bolder;
  margin: 10px 0px 5px 0px;
}

/*右上角选择时间*/
.min-date,
.max-date,
.middle-character {
  font-size: 14px;
  font-weight: 600;
  padding: 0 4px;
}

/*模块主题内容*/
.statistics-content-figure {
  width: 100%;
  height: 109px;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*含有图表的模块*/
.module-charts {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/*右上角统一div样式*/
.right-time {
  float: right;
  margin-right: 8px;
}

/*所有数字下方描述性质字体*/
.description-text {
  font-size: 12px;
  color: #999999;
  font-family: PingFangSC-Regular;
  margin-top: 4px;
  font-weight: bolder;
}

/*数字样式-小*/
.money-small-font {
  font-family: Oswald-Regular;
  font-size: 20px;
  color: #222222;
  font-weight: bolder;
  padding-top: 30px;
}

/*数字样式-大*/
.money-big-font {
  font-family: Oswald-Regular;
  font-size: 40px;
  color: #222222;
  font-weight: bolder;
  padding-top: 20px;
}

/*数字样式-小-橘色*/
.money-other-font {
  font-family: Oswald-Regular;
  font-size: 20px;
  color: #ff5d3b;
  font-weight: bolder;
  padding-top: 30px;
}

/*图表样式*/
.chart-style {
  height: 115px;
  width: 115px;
}

.description-content {
  width: 220px;
  margin: 0 auto;
}
.description-content1 {
  width: 85%;
  margin: 0 auto;
  height: 100px;
}

.description-content-other {
  width: 8.2rem;
  margin: 0 auto;
}

.left-content {
  float: left;
}
.left-content1 {
  float: left;
  width: 49.5%;
}

.right-content {
  margin-left: 25px;
  float: left;
}
.right-content1 {
  /*margin-left: 20px;*/
  float: right;
  width: 49.5%;
}

.middle-content {
  margin-left: 28px;
  float: left;
}

.statistics-content-chart {
  width: 100%;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*智能门锁*/
.statistics-IDL-chart {
  width: 100%;
  /*height: 240px;*/
  border-radius: 8px 8px 0px 0px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*智能设备*/
.statistics-ID-chart {
  width: 100%;
  /*height: 240px;*/
  border-top: 1px dashed #ff5d3b;
  border-radius: 0px 0px 8px 8px;
  background-color: white;
  text-align: center;
}

/*右上角选择时间左右箭头*/
.left-arrow,
.right-arrow {
  padding-bottom: 2px;
}
/*中间分割线*/
.middle-line {
  float: left;
  width: 1px;
  height: 43px;
  left: 50%;
  margin-top: 33px;
  border-right: #ff5d3b 1px dashed;
}
.mianComputed {
  margin-top: 20px;
}
.maincomcon {
  margin: 0 15px;
  background: #fff;
  height: 100%;
  border-radius: 8px;
}
.maincomconT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50%;
  /* background: #f8f8f8; */
}

.maincomconTItem {
  flex: 1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.linedashed {
  height: 40px;
  width: 0px;
  border-right: 1px dashed #ff5d3b;
}
.maincomconTItemfont {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #999999;
  font-family: PingFangSC-Regular;
  margin-top: 4px;
  font-weight: bolder;
}
.maincomconTItemcount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Oswald-Regular;
  font-size: 20px;
  color: #222222;
  font-weight: bolder;

}
.totalContant {
  margin-top: 20px;
}
.flexCommon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexCenCommon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.maincomconItem {
  flex: 1;
  height: 80px;
}
.mainList {
  margin: 20px 15px 0 15px;
  border-radius: 8px;

}
.dataDetail{  
        width: 100%;
        font-size: 14px;
        padding: 10px 0;
        display: flex;
        border-radius: 7.5px;
        background-color: #FFFFFF;
        
        .dataStaffName{  
            display: flex;
            flex-direction: column;

            // border-top: 1px solid #f8f8f8;
            >div:nth-of-type(1){
                font-weight: bolder;
                border-left: 1px solid #f8f8f8;
                border-right: 1px solid #f8f8f8;
                border-bottom: 1px solid #f8f8f8;
            }
            >div:nth-of-type(even){
                background-color: #fff;
                border-left: 1px solid #f8f8f8;
                border-right: 1px solid #f8f8f8;
                border-bottom: 1px solid #f8f8f8;
            }
            >div{
                padding: 0 10px;
                // font-size: 14px;
                width: 120px;
                overflow: hidden;
                height: 25px;
                line-height: 25px;
                border-left: 1px solid #f8f8f8;
                border-right: 1px solid #f8f8f8;
                border-bottom: 1px solid #f8f8f8;
            }
        }
        .staffData{  //右侧数据
            width: 94%;
            // width: 80%;
            // display: inline-block;
            overflow:hidden;
            white-space:nowrap;
            overflow-x: scroll;
            display: flex;
            flex-direction: column;
            
            .dataDetailHeaderRow{  //右侧表头横hang
                height: 25px;
                line-height: 27px;
                border-top: 1px solid #f8f8f8;
                border-bottom: 1px solid #f8f8f8;


                .dataDetailHeader{     //数据的每个表头
                    display:inline-block;
                    width: 80px;
                    font-size: 14px;
                    font-weight: bolder;
                    // border-right: 1px solid #f8f8f8;
                    // border-bottom: 1px solid #f8f8f8;
                    
                }
                
            }
            .dataDetailRightDataRow:nth-of-type(even){
                background-color: #fff;
                
                 
                >.dataDetailRightData{
                    background-color: #fff;
                    //  border-right: 1px solid #f8f8f8;
                    // border-bottom: 1px solid #f8f8f8;
                }
                >.dataDetailRightData1 {
                  background-color: #fff;
                   border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                }
                >.dataDetailRightData2 {
                    border-top: 1px solid #f8f8f8;

                }
            }
            .dataDetailRightDataRow{  //右侧数据横hang
                height: 25px;
                line-height: 25px;
                //  border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                .dataDetailRightData{     //右侧每个数据
                    display:inline-block;
                    width: 80px;
                    // background: red;
                    overflow: hidden;
                    font-size: 14px;
                    height: 25px;
                     border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                }
                .dataDetailRightData1 {
                    display:inline-block;
                    width: 500px;
                    overflow: hidden;
                    font-size: 14px;
                    height: 25px;
                     border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                }
                .dataDetailRightDataMoney{
                    box-sizing: border-box;
                    padding: 0 50px 0 0;
                    text-align: right;
                     border-right: 1px solid #f8f8f8;
                    border-bottom: 1px solid #f8f8f8;
                    
                }
            }
        }

    } 
</style>
